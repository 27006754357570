import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../utils/api';

const reportReasons = [
  'Inappropriate content',
  'Spam',
  'Misleading information',
  'Safety concerns',
  'Copyright violation',
  'Other'
];

const ReportDialog = ({ open, onClose, eventId, latitude, longitude }) => {
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getIdToken } = useAuth();


  
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!reason) {
      setSnackbar({ open: true, message: 'Please select a reason for reporting', severity: 'error' });
      return;
    }

    setIsSubmitting(true);
    try {
      const token = await getIdToken();
      await api.post(`/${eventId}/report`, 
        { reason, description, latitude, longitude },
      );
      setSnackbar({ open: true, message: 'Report submitted successfully', severity: 'success' });
      setTimeout(() => {
        onClose();
        setReason('');
        setDescription('');
      }, 2000);
    } catch (error) {
      console.error('Error submitting report:', error);
      let errorMessage = 'Failed to submit report. Please try again.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }, [eventId, reason, description, latitude, longitude, getIdToken, onClose]);


  
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Report Event</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              Please provide details about why you're reporting this event. Your report will be reviewed by our team.
            </Typography>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Reason for reporting</InputLabel>
              <Select
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                label="Reason for reporting"
              >
                {reportReasons.map((r) => (
                  <MenuItem key={r} value={r}>{r}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Additional details"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{ maxLength: 1000 }}
              helperText={`${description.length}/1000`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={isSubmitting || !reason}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Submit Report'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReportDialog;