import axios from 'axios';
import cacheService from '../services/cacheService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://hubevent.app/api',
});


let interceptorId;

export const setupInterceptors = (currentUser, getIdToken) => {
  // Remove existing interceptor if there is one
  if (interceptorId !== undefined) {
    api.interceptors.request.eject(interceptorId);
  }

  // Add a new interceptor
  interceptorId = api.interceptors.request.use(
    async (config) => {
      if (currentUser) {
        try {
          const token = await getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          console.error("Erreur lors de l'obtention du token:", error);
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeInterceptors = () => {
  if (interceptorId !== undefined) {
    api.interceptors.request.eject(interceptorId);
    interceptorId = undefined;
  }
};

api.getCached = async (url, config = {}, cacheKey = null) => {
  // Générer une clé de cache si non fournie
  const key = cacheKey || `${url}_${JSON.stringify(config)}`;
  
  // Vérifier le cache
  const cachedData = cacheService.get(key);
  if (cachedData) {
    return { data: cachedData, fromCache: true };
  }
  
  // Si pas en cache, faire l'appel API
  const response = await api.get(url, config);
  
  // Mettre en cache le résultat
  cacheService.set(key, response.data);
  
  return { ...response, fromCache: false };
};

export { api, cacheService };
