// utils/cacheService.js

/**
 * A utility service for managing app-wide caching with expiration times
 * Compatible with the existing HUB application architecture
 */

// Default expiration time is 5 minutes (in milliseconds)
const DEFAULT_EXPIRATION = 5 * 60 * 1000;

class CacheService {
  constructor() {
    this._cache = {};
    this._initializeFromLocalStorage();
    
    // Add event listener for storage events to sync cache across tabs
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', this._handleStorageChange.bind(this));
      
      // Cleanup expired items on instantiation
      this._cleanupExpiredItems();
      
      // Set up periodic cleanup every minute
      this._cleanupInterval = setInterval(() => this._cleanupExpiredItems(), 60000);
    }
  }
  
  /**
   * Initialize cache from localStorage to persist across page refreshes
   * @private
   */
  _initializeFromLocalStorage() {
    try {
      if (typeof localStorage !== 'undefined') {
        const storedCache = localStorage.getItem('app_cache');
        if (storedCache) {
          this._cache = JSON.parse(storedCache);
        }
      }
    } catch (error) {
      console.error('Error initializing cache from localStorage:', error);
      this._cache = {};
    }
  }
  
  /**
   * Handle storage events to sync cache across tabs
   * @private
   */
  _handleStorageChange(event) {
    if (event.key === 'app_cache') {
      try {
        this._cache = JSON.parse(event.newValue) || {};
      } catch (error) {
        console.error('Error syncing cache across tabs:', error);
      }
    }
  }
  
  /**
   * Persist cache to localStorage
   * @private
   */
  _persistToLocalStorage() {
    try {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('app_cache', JSON.stringify(this._cache));
      }
    } catch (error) {
      console.error('Error persisting cache to localStorage:', error);
    }
  }
  
  /**
   * Clean up expired items from cache
   * @private
   */
  _cleanupExpiredItems() {
    const now = Date.now();
    let hasRemovedItems = false;
    
    Object.keys(this._cache).forEach(key => {
      if (this._cache[key].expiry < now) {
        delete this._cache[key];
        hasRemovedItems = true;
      }
    });
    
    if (hasRemovedItems) {
      this._persistToLocalStorage();
    }
  }
  
  /**
   * Get an item from the cache
   * @param {string} key - The cache key
   * @returns {*} The cached value or undefined if not found or expired
   */
  get(key) {
    const cacheItem = this._cache[key];
    if (!cacheItem) return undefined;
    
    const now = Date.now();
    if (cacheItem.expiry < now) {
      delete this._cache[key];
      this._persistToLocalStorage();
      return undefined;
    }
    
    return cacheItem.value;
  }
  
  /**
   * Set an item in the cache with optional expiration time
   * @param {string} key - The cache key
   * @param {*} value - The value to cache
   * @param {number} [expiration=DEFAULT_EXPIRATION] - Expiration time in milliseconds
   */
  set(key, value, expiration = DEFAULT_EXPIRATION) {
    this._cache[key] = {
      value,
      expiry: Date.now() + expiration
    };
    
    this._persistToLocalStorage();
  }
  
  /**
   * Check if a key exists in the cache and is not expired
   * @param {string} key - The cache key
   * @returns {boolean} True if the key exists and is not expired
   */
  has(key) {
    const cacheItem = this._cache[key];
    if (!cacheItem) return false;
    
    const now = Date.now();
    if (cacheItem.expiry < now) {
      delete this._cache[key];
      this._persistToLocalStorage();
      return false;
    }
    
    return true;
  }
  
  /**
   * Remove an item from the cache
   * @param {string} key - The cache key
   */
  remove(key) {
    if (this._cache[key]) {
      delete this._cache[key];
      this._persistToLocalStorage();
    }
  }
  
  /**
   * Clear all items from the cache
   */
  clear() {
    this._cache = {};
    this._persistToLocalStorage();
  }
  
  /**
   * Get a list of all valid cache keys
   * @returns {string[]} Array of cache keys
   */
  keys() {
    this._cleanupExpiredItems();
    return Object.keys(this._cache);
  }
  
  /**
   * Get the remaining time in milliseconds before an item expires
   * @param {string} key - The cache key
   * @returns {number} The remaining time in milliseconds or 0 if expired/not found
   */
  getTimeToExpiry(key) {
    const cacheItem = this._cache[key];
    if (!cacheItem) return 0;
    
    const now = Date.now();
    return Math.max(0, cacheItem.expiry - now);
  }
  
  /**
   * Extend the expiration time of an item
   * @param {string} key - The cache key
   * @param {number} [additionalTime=DEFAULT_EXPIRATION] - Additional time in milliseconds
   * @returns {boolean} True if the item existed and was extended
   */
  extend(key, additionalTime = DEFAULT_EXPIRATION) {
    const cacheItem = this._cache[key];
    if (!cacheItem) return false;
    
    const now = Date.now();
    if (cacheItem.expiry < now) {
      delete this._cache[key];
      this._persistToLocalStorage();
      return false;
    }
    
    cacheItem.expiry += additionalTime;
    this._persistToLocalStorage();
    return true;
  }
  
  /**
   * Get or set a cached value with a callback function if not found
   * @param {string} key - The cache key
   * @param {Function} callback - Function to call to get the value if not in cache
   * @param {number} [expiration=DEFAULT_EXPIRATION] - Expiration time in milliseconds
   * @returns {Promise<*>} The cached or newly fetched value
   */
  async getOrSet(key, callback, expiration = DEFAULT_EXPIRATION) {
    const cachedValue = this.get(key);
    if (cachedValue !== undefined) {
      return cachedValue;
    }
    
    try {
      const newValue = await callback();
      this.set(key, newValue, expiration);
      return newValue;
    } catch (error) {
      console.error(`Error fetching data for cache key ${key}:`, error);
      throw error;
    }
  }
  
  /**
   * Clean up resources when the service is no longer needed
   */
  dispose() {
    if (this._cleanupInterval) {
      clearInterval(this._cleanupInterval);
    }
    
    if (typeof window !== 'undefined') {
      window.removeEventListener('storage', this._handleStorageChange.bind(this));
    }
  }
}

// Export a singleton instance
const cacheService = new CacheService();
export default cacheService;