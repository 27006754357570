import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, addDays, isWeekend, isToday, isSameDay, parseISO, isYesterday } from 'date-fns';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { isLateNight, getEventReferenceDate } from '../utils/dateUtils';

const DateChip = styled(Chip)(({ theme, isSelected, isWeekend, isNight, isToday }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: isSelected 
    ? theme.palette.primary.main 
    : isNight
      ? theme.palette.mode === 'dark' 
        ? theme.palette.grey[800]
        : theme.palette.grey[700]
      : isWeekend 
        ? theme.palette.grey[500]
        : 'transparent',
  color: isSelected 
    ? theme.palette.primary.contrastText 
    : isNight
      ? '#fff'
      : theme.palette.text.primary,
  border: `1px solid ${isSelected 
    ? theme.palette.primary.main 
    : isNight
      ? theme.palette.grey[600]
      : isWeekend
        ? theme.palette.grey[400]
        : theme.palette.divider}`,
  '&:hover': {
    backgroundColor: isSelected 
      ? theme.palette.primary.dark 
      : isNight
        ? theme.palette.mode === 'dark'
          ? theme.palette.grey[700]
          : theme.palette.grey[600]
        : isWeekend
          ? theme.palette.grey[300]
          : theme.palette.action.hover,
  },
  transition: 'all 0.2s ease',
  flexDirection: 'column',
  height: 'auto',
  minWidth: '80px',
  '& .MuiChip-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

const DateIcon = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DayLabel = styled(Typography)(({ theme, isWeekend, isNight, isToday }) => ({
  fontSize: '0.7rem',
  fontWeight: isToday ? 700 : isWeekend || isNight ? 500 : 400,
  opacity: isToday ? 1 : isWeekend || isNight ? 0.9 : 0.7,
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(1),
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '3px',
  },
}));

const EnhancedDateSelector = ({ 
  selectedDate, 
  onDateSelect, 
  onLoadMoreDates, 
  displayedDates = 30,
  eventCounts = {} // Optional prop to show event counts per day
}) => {
  const scrollContainerRef = useRef(null);
  const isNightNow = isLateNight();
  
  // Get the base date - if it's late night, we default to yesterday
  const baseDate = getEventReferenceDate();
  
  // Generate dates array
  const dates = Array.from({ length: displayedDates }, (_, i) => addDays(baseDate, i));
  
  // If it's late night (after midnight but before 6 AM), include yesterday as well at the beginning
  const [displayDates, setDisplayDates] = useState([]);
  
  useEffect(() => {
    let datesToShow = [...dates];
    
    // If it's late night and the first date isn't already yesterday
    if (isNightNow && !isYesterday(datesToShow[0])) {
      const yesterday = addDays(new Date(), -1);
      datesToShow = [yesterday, ...datesToShow];
    }
    
    setDisplayDates(datesToShow);
  }, [isNightNow, dates]);

  useEffect(() => {
    if (selectedDate && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const selectedElement = container.querySelector(`[data-date="${format(selectedDate, 'yyyy-MM-dd')}"]`);
      
      if (selectedElement) {
        const containerWidth = container.offsetWidth;
        const elementWidth = selectedElement.offsetWidth;
        const elementOffsetLeft = selectedElement.offsetLeft;
        const scrollPosition = elementOffsetLeft - (containerWidth / 2) + (elementWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [selectedDate, displayDates]);

  // Auto-select today/last night when component mounts if no date is selected
  useEffect(() => {
    if (!selectedDate) {
      onDateSelect(baseDate);
    }
  }, [selectedDate, onDateSelect, baseDate]);

  const getDateIcon = (date) => {
    const now = new Date();
    const hour = now.getHours();
    
    // It's night time now and this is yesterday's events
    if (isNightNow && isYesterday(date)) {
      return <NightsStayIcon fontSize="small" color="inherit" />;
    }
    
    // Today's events, show according to time of day
    if (isToday(date)) {
      // Evening
      if (hour >= 18) {
        return <NightsStayIcon fontSize="small" color="inherit" />;
      }
      // Morning to afternoon
      return <WbSunnyIcon fontSize="small" color="inherit" />;
    }
    
    // Future dates
    return <EventAvailableIcon fontSize="small" color="inherit" />;
  };

  return (
    <Box mb={2}>
      <Typography variant="subtitle1" gutterBottom>
        {isNightNow ? 'Événements en cours (y compris fin de soirée)' : 'Sélectionner une date'}
      </Typography>
      <ScrollableBox ref={scrollContainerRef}>
        {displayDates.map((date) => {
          const isNight = isNightNow && isYesterday(date);
          const isDateSelected = selectedDate && 
            isSameDay(selectedDate, date);
          const isWeekendDay = isWeekend(date);
          const count = eventCounts[format(date, 'yyyy-MM-dd')] || 0;
          const dateKey = format(date, 'yyyy-MM-dd');
          
          return (
            <Box
              key={dateKey}
              data-date={dateKey}
            >
              <Tooltip title={isNight ? "Événements de fin de soirée" : count > 0 ? `${count} événements` : "Aucun événement"}>
                <DateChip
                  label={
                    <>
                      <DateIcon>
                        {getDateIcon(date)}
                      </DateIcon>
                      <DayLabel 
                        isWeekend={isWeekendDay} 
                        isNight={isNight}
                        isToday={isToday(date)}
                      >
                        {format(date, 'EEE')}
                      </DayLabel>
                      {format(date, 'd MMM')}
                      {count > 0 && (
                        <Typography variant="caption" 
                          sx={{ 
                            fontWeight: 'bold',
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '4px'
                          }}
                        >
                          {count}
                        </Typography>
                      )}
                    </>
                  }
                  onClick={() => onDateSelect(date)}
                  isSelected={isDateSelected}
                  isWeekend={isWeekendDay}
                  isNight={isNight}
                  isToday={isToday(date)}
                />
              </Tooltip>
            </Box>
          );
        })}
        <Chip
          label="Plus de dates"
          onClick={onLoadMoreDates}
          variant="outlined"
          sx={{ 
            margin: (theme) => theme.spacing(0.5),
            height: '100%',
            minWidth: '80px'
          }}
        />
      </ScrollableBox>
    </Box>
  );
};

export default EnhancedDateSelector;