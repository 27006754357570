// components/LoadingError.js

import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

const LoadingError = ({ loading, error, children }) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" my={4}>
        <Typography color="error" variant="h6">{error}</Typography>
      </Box>
    );
  }

  return children;
};

export default LoadingError;