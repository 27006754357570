import React, { useState, useCallback, useMemo } from 'react';
import { 
  Card, CardContent, CardActions, Typography, Button, Chip, Box, 
  CardMedia, Tooltip, IconButton, Avatar, Grid,
  ListItemIcon, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import PulsatingGlowBorder from './PulsatingGlowBorder';
import { categories } from '../constants/categories';
import { parseISO, format, isValid, isSameDay } from 'date-fns';
import EventOptionsMenu from './EventOptionsMenu';
import ReportDialog from './ReportDialog';
import SafeDescriptionComponent from './SafeDescriptionComponent';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { fr, enUS } from 'date-fns/locale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CategoryIcon = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(30, 30, 30, 0.8)',
  backdropFilter: 'blur(10px)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '12px',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledCardMedia = styled(CardMedia)({
  paddingTop: '80%', // 16:9 aspect ratio
  position: 'relative',
});

const OverlayContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.7) 100%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '8px 16px',
  background: 'rgba(0, 0, 0, 0.7)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
});

const OptionsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const CertificationBadge = styled(IconButton)(({ theme, color }) => ({
  padding: 2,
  marginLeft: 8,
  '& svg': {
    color: color,
    fontSize: '1rem',
  },
}));

const CertificationBadgeHeader = styled(IconButton)(({ theme, color }) => ({
  padding: 7,
  marginRight: 32,
  '& svg': {
    color: color,
    fontSize: '1.5rem',
  },
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
});

const TruncatedTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const HashtagContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
  marginTop: '8px',
});

const StyledChip = styled(Chip)({
  fontSize: '0.7rem',
  height: '24px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const StyledCardActions = styled(CardActions)({
  padding: '16px',
  justifyContent: 'space-between',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '8px',
});

const StyledButton = styled(Button)({
  flex: 1,
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
  },
});

const InfoItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '4px',
});

const EventCard = ({ 
  event, 
  onViewDetails, 
  onJoin, 
  onHashtagClick, 
  certificationStyle, 
  onBlock,
  onViewCreatorProfile,
  onReport,
  userLocale = 'fr' // Ajout d'un prop pour la locale de l'utilisateur, par défaut 'fr'

}) => {
  const [openReportDialog, setOpenReportDialog] = useState(false);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + '...';
  };

  const getCategoryIcon = (category) => {
    const categoryData = categories.find(c => c.value === category);
    if (categoryData) {
      const Icon = categoryData.icon;
      return <Icon fontSize="small" />;
    }
    return null;
  };
  const formatEventDate = useMemo(() => (startDate, endDate) => {
    if (!startDate) return 'Date non disponible';
  
    const start = typeof startDate === 'object' && startDate._seconds
      ? new Date(startDate._seconds * 1000)
      : parseISO(startDate);
  
    if (!isValid(start)) return 'Date invalide';
  
    const end = endDate ? (typeof endDate === 'object' && endDate._seconds
      ? new Date(endDate._seconds * 1000)
      : parseISO(endDate)) : null;
  
    const locale = userLocale === 'fr' ? fr : enUS;
  
    const formatTime = (date) => format(date, 'HH:mm', { locale }); // Format uniquement l'heure et les minutes
  
    if (!end || isSameDay(start, end)) {
      const timeStr = `${formatTime(start)}${end ? ` - ${formatTime(end)}` : ''}`;
      return timeStr; // Affiche uniquement l'heure
    } else {
      return `${formatTime(start)} - ${formatTime(end)}`; // Affiche l'heure de début et de fin
    }
  }, [userLocale]);
  

  const handleViewDetails = useCallback(() => {
    if (!openReportDialog) {
      onViewDetails(event.id);
    }
  }, [event.id, onViewDetails, openReportDialog]);

  const handleReport = useCallback(() => {
    if (onReport) {
      onReport(event);
    } else {
      setOpenReportDialog(true);
    }
  }, [event, onReport]);

  const handleCloseReport = useCallback(() => setOpenReportDialog(false), []);

  const handleHashtagClick = useCallback((tag) => (e) => {
    e.stopPropagation();
    onHashtagClick(tag);
  }, [onHashtagClick]);

  const handleBlock = useCallback(() => {
    if (onBlock) {
      onBlock(event.createdBy);
    }
  }, [onBlock, event.createdBy]);

  const handleViewProfile = useCallback(() => {
    if (onViewCreatorProfile) {
      onViewCreatorProfile(event.createdBy);
    }
  }, [onViewCreatorProfile, event.createdBy]);

  return (
    <StyledCard>
      <StyledCardMedia
        image={event.imageUrl || '/placeholder-image.jpg'}
        title={event.title}
      >
        <HeaderContainer>
          <CategoryIcon>
            {getCategoryIcon(event.eventCategory)}
          </CategoryIcon>
          <OptionsContainer>
            {certificationStyle && (
              <Tooltip title={`Certified ${event.creatorCertification === 'personal' ? 'User' : 'Organization'}`}>
                <CertificationBadgeHeader style={{ color: certificationStyle.badgeColor }}>
                  {certificationStyle.icon === 'verified' ? <VerifiedIcon /> : <VerifiedUserIcon />}
                </CertificationBadgeHeader>
              </Tooltip>
            )}
            <EventOptionsMenu
              onReport={handleReport}
              onBlock={() => handleBlock(event.createdBy)}
              onViewProfile={handleViewProfile}
            />
          </OptionsContainer>
        </HeaderContainer>
        <OverlayContainer>
          <Typography variant="h5" component="h2" style={{ color: 'white', fontWeight: 'bold', marginBottom: '8px', marginTop: '40px' }}>
            {truncateText(event.title, 50)}
          </Typography>
          <Typography variant="subtitle1" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
          @{event.venueName} ({event.distance ? `${event.distance.toFixed(1)} km)` : ''}
          </Typography>
        </OverlayContainer>
      </StyledCardMedia>
      <StyledCardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TruncatedTypography variant="body2" color="textSecondary">
              <SafeDescriptionComponent description={truncateText(event.description, 100)} />
            </TruncatedTypography>
          </Grid>
          <Grid item xs={12}>
            <InfoItem>
              <AccessTimeIcon fontSize="small" color="primary" />
              <Typography variant="body2" color="textSecondary">
                {formatEventDate(event.startDate)}
                {formatEventDate(event.endDate) && ` - ${formatEventDate(event.endDate)}`}
              </Typography>
            </InfoItem>
            <InfoItem>
            <AttachMoneyIcon fontSize="small" color="primary" />
            <Typography variant="body2" color="textSecondary">
              {event.price ? `${event.price}` : 'Prix non disponible'}
            </Typography>
            </InfoItem>
            <InfoItem>
              <PersonIcon fontSize="small" color="primary" />
              <Typography variant="body2" color="textSecondary">
                By {event.username || 'Anonymous'}
                {certificationStyle && (
                  <Tooltip title={`Certified ${event.creatorCertification === 'personal' ? 'User' : 'Organization'}`}>
                    <CertificationBadge style={{ color: certificationStyle.badgeColor }}>
                      {certificationStyle.icon === 'verified' ? <VerifiedIcon /> : <VerifiedUserIcon />}
                    </CertificationBadge>
                  </Tooltip>
                )}
              </Typography>
            </InfoItem>
            <InfoItem>
              {event.isAutomated && (
                <Tooltip title="This event was added automatically" arrow>
                  <Typography>
                    <SmartToyIcon fontSize="small" color="primary"/>
                  </Typography>
                </Tooltip>
              )}
            </InfoItem>
          </Grid>
          <Grid item xs={12}>
            <HashtagContainer>
              {event.hashtags && event.hashtags.map((tag, index) => (
                <Tooltip key={`${event.id}-${tag}-${index}`} title={tag}>
                  <StyledChip 
                    label={truncateText(tag, 15)}
                    size="small" 
                    onClick={handleHashtagClick(tag)}
                  />
                </Tooltip>
              ))}
            </HashtagContainer>
          </Grid>
        </Grid>
      </StyledCardContent>
      <StyledCardActions>
        <ButtonContainer>
          <StyledButton variant="outlined" color="primary" onClick={handleViewDetails}>
            View Details
          </StyledButton>
          <StyledButton variant="contained" color="secondary" onClick={() => onJoin(event.id)}>
            Join
          </StyledButton>
        </ButtonContainer>
      </StyledCardActions>
      <ReportDialog
        open={openReportDialog}
        onClose={handleCloseReport}
        eventId={event.id}
        latitude={event.coordinates?.latitude}
        longitude={event.coordinates?.longitude}
      />
    </StyledCard>
  );
};

export default EventCard;