import React, { useState, useEffect, useCallback, useMemo, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, Grid, Box, Button, Chip, CircularProgress, 
  Paper, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
  useTheme, useMediaQuery, Select, MenuItem, TextField, IconButton,
  Fade, Zoom, Skeleton, Divider, Collapse, Badge, Tooltip, FormControlLabel,
  Checkbox, InputBase, SwipeableDrawer, List, ListItem, ListItemText, Backdrop
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { categories } from '../constants/categories';
import EventCard from '../components/EventCard';
import EventListItem from '../components/EventListItem';
import DateSelector from '../components/DateSelector';
import { api } from '../utils/api';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoginIcon from '@mui/icons-material/Login';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SortIcon from '@mui/icons-material/Sort';
import VerifiedIcon from '@mui/icons-material/Verified';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { parseISO, format, isValid, startOfDay, endOfDay, addDays, isToday, isTomorrow } from 'date-fns';
import debounce from 'lodash/debounce';
import CustomizablePulsatingGlowBorder from '../components/PulsatingGlowBorder';
import { getCertificationStyle } from '../utils/certificationUtils';
import { styled, alpha } from '@mui/material/styles';
import WelcomeCategoriesDialog from './WelcomeCategoriesDialog';

// Lazily load the map component to improve initial load performance
const EventMap = lazy(() => import('../components/EventMap'));

// ===== STYLED COMPONENTS =====
const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: 0,
  }
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  transition: 'all 0.2s ease',
  '&.MuiChip-outlined': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    height: '28px'
  }
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  transition: 'all 0.2s ease',
  fontWeight: 500,
  '&.MuiChip-filled': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 120px)',
    marginTop: 0,
    borderRadius: 0
  }
}));

const StickyDateHeader = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 11,
  padding: theme.spacing(2, 0),
  display: 'flex',
  alignItems: 'center',
  backdropFilter: 'blur(10px)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    padding: theme.spacing(1, 0),
    fontWeight: 500
  }
}));

const Search = styled('div')(({ theme, expanded }) => ({
  position: 'relative',
  borderRadius: expanded ? theme.shape.borderRadius : '50px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1)
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  color: alpha(theme.palette.common.white, 0.7),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1)
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.2, 1, 1.2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(1, 0.75, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    }
  }
}));

const MobileControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));

const SortSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: 20,
  [theme.breakpoints.down('sm')]: {
    '& .MuiSelect-select': {
      fontSize: '0.9rem',
      padding: theme.spacing(1, 1, 1, 2)
    }
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '-4px',
    width: 'calc(100% + 8px)',
    '& .MuiGrid-item': {
      padding: '4px'
    }
  }
}));

const NotFoundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(5),
  textAlign: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  minHeight: '30vh',
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  borderRadius: theme.shape.borderRadius,
  backdropFilter: 'blur(5px)',
}));

const ViewModeSwitcher = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
  backdropFilter: 'blur(5px)',
  borderRadius: 25,
  padding: theme.spacing(0.5),
  '& .MuiToggleButton-root': {
    color: alpha(theme.palette.common.white, 0.7),
    borderRadius: 20,
    padding: theme.spacing(0.75, 1.5),
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.9),
      }
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiToggleButton-root': {
      padding: theme.spacing(0.5, 1),
    }
  }
}));

const FilterButton = styled(Button)(({ theme, active }) => ({
  borderRadius: 20,
  textTransform: 'none',
  fontWeight: 500,
  padding: theme.spacing(0.75, 2),
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.common.white, 0.1),
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  border: active ? `1px solid ${alpha(theme.palette.primary.main, 0.5)}` : 'none',
  '&:hover': {
    backgroundColor: active ? alpha(theme.palette.primary.main, 0.25) : alpha(theme.palette.common.white, 0.2),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    padding: theme.spacing(0.5, 1.5),
  }
}));

const ActionButtonContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'flex-end',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  padding: theme.spacing(1.2, 2.5),
  boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
  }
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  }
}));

const FilterDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    maxWidth: 500,
    margin: '0 auto',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const EventSkeleton = ({ isList = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  if (isList) {
    return (
      <Box mb={1} sx={{ 
        backgroundColor: alpha(theme.palette.background.paper, 0.4),
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
      }}>
        <Box display="flex" p={isMobile ? 1 : 2}>
          <Skeleton variant="rectangular" width={80} height={80} sx={{ borderRadius: 1 }} />
          <Box ml={2} width="100%">
            <Skeleton variant="text" width="80%" height={28} />
            <Skeleton variant="text" width="60%" height={20} />
            <Box display="flex" mt={1}>
              <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 12, mr: 1 }} />
              <Skeleton variant="rectangular" width={70} height={24} sx={{ borderRadius: 12 }} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Skeleton variant="rectangular" width="100%" height={140} sx={{ borderRadius: '8px 8px 0 0' }}/>
      <Box p={2}>
        <Skeleton variant="text" width="80%" height={32} />
        <Skeleton variant="text" width="40%" height={24} />
        <Box display="flex" mt={1}>
          <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 12, mr: 1 }} />
          <Skeleton variant="rectangular" width={70} height={24} sx={{ borderRadius: 12 }} />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Skeleton variant="rectangular" width="45%" height={36} sx={{ borderRadius: 18 }} />
          <Skeleton variant="rectangular" width="45%" height={36} sx={{ borderRadius: 18 }} />
        </Box>
      </Box>
    </Box>
  );
};

// Main component
const PublicEventBrowser = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ===== STATE VARIABLES =====
  const [selectedCategories, setSelectedCategories] = useState(() => {
    const storedCategories = localStorage.getItem('selectedCategories');
    return storedCategories ? JSON.parse(storedCategories) : [];
  });
  
  const [showOnlyCertified, setShowOnlyCertified] = useState(() => {
    return JSON.parse(localStorage.getItem('showOnlyCertified') || 'false');
  });
  
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categoryEventCounts, setCategoryEventCounts] = useState({});
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'grid';
  });
  
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('sortBy') || 'dateAsc';
  });
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [displayedDates, setDisplayedDates] = useState(30);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [eventCache, setEventCache] = useState({});
  const [favoriteHashtags, setFavoriteHashtags] = useState([]);
  const [blacklistedHashtags, setBlacklistedHashtags] = useState([]);
  const [selectedHashtags, setSelectedHashtags] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedHashtags') || '[]');
  });
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [expandedFilters, setExpandedFilters] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [mapReady, setMapReady] = useState(false);

  // ===== LOCATION HANDLING =====
  const getUserLocation = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
          // Default to Geneva coordinates as fallback
          setUserLocation({ latitude: 46.2044, longitude: 6.1432 }); 
        }
      );
    } else {
      // Default to Geneva coordinates if geolocation is not available
      setUserLocation({ latitude: 46.2044, longitude: 6.1432 });
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  // ===== DIALOG HANDLING =====
  useEffect(() => {
    const hideDialog = localStorage.getItem('hideWelcomeDialog');
    const hasStoredCategories = localStorage.getItem('selectedCategories');
    if (!hideDialog && !hasStoredCategories) {
      setShowWelcomeDialog(true);
    }
  }, []);

  // ===== EVENT LOADING =====
  const preloadEvents = useCallback(async () => {
    if (!userLocation) return;

    try {
      await api.get('/public-events/preload', {
        params: {
          days: displayedDates,
          categories: selectedCategories.join(','),
          latitude: userLocation.latitude,
          longitude: userLocation.longitude
        }
      });
    } catch (error) {
      console.error('Error preloading events:', error);
      // Don't show error as this is a background operation
    }
  }, [displayedDates, selectedCategories, userLocation]);

  const fetchEvents = useCallback(async (date) => {
    if (!userLocation) return;

    setLoading(true);
    setError(null);

    const dateToFetch = date || selectedDate;
    const dateStr = format(dateToFetch, 'yyyy-MM-dd');
    const cacheKey = `${dateStr}_${selectedCategories.join('_')}_${showOnlyCertified}`;

    try {
      // Check cache first
      if (eventCache[cacheKey] && !searchTerm) {
        setEvents(eventCache[cacheKey]);
        setFilteredEvents(eventCache[cacheKey]);
        setLoading(false);
        setInitialLoadComplete(true);
        return;
      }

      const response = await api.get('/public-events/daily', {
        params: {
          date: dateStr,
          categories: selectedCategories.join(','),
          latitude: userLocation.latitude,
          longitude: userLocation.longitude
        }
      });

      const newEvents = response.data.events || [];
      
      if (!searchTerm) {
        setEventCache(prev => ({
          ...prev,
          [cacheKey]: newEvents
        }));
      }

      setEvents(newEvents);
      updateCategoryEventCounts(newEvents);
      
      // Apply filters after loading
      applyFilters(newEvents);
      setInitialLoadComplete(true);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('Failed to load events. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [userLocation, selectedDate, selectedCategories, searchTerm, eventCache, showOnlyCertified]);

  // Apply all filters to events
  const applyFilters = useCallback((eventList) => {
    const filtered = eventList.filter((event) => {
      // Certification filter
      if (showOnlyCertified && !event.creatorIsCertified) {
        return false;
      }

      const matchesSearch = !searchTerm || (
        event.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (event.hashtags && event.hashtags.some(tag => 
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        ))
      );

      const matchesSelectedHashtags = selectedHashtags.length === 0 || 
        (Array.isArray(event.hashtags) && selectedHashtags.every(tag => event.hashtags.includes(tag)));
      
      const matchesFavoriteHashtags = favoriteHashtags.length === 0 ||
        (Array.isArray(event.hashtags) && event.hashtags.some(tag => favoriteHashtags.includes(tag)));
      
      const notInBlacklist = !Array.isArray(event.hashtags) ||
        !event.hashtags.some(tag => blacklistedHashtags.includes(tag));

      return matchesSearch && matchesSelectedHashtags && matchesFavoriteHashtags && notInBlacklist;
    });
    
    setFilteredEvents(filtered);
  }, [searchTerm, selectedHashtags, favoriteHashtags, blacklistedHashtags, showOnlyCertified]);

  // Load events when dependencies change
  useEffect(() => {
    if (userLocation) {
      fetchEvents();
      preloadEvents();
    }
  }, [fetchEvents, preloadEvents, userLocation]);

  // This effect will run when filtering parameters change
  useEffect(() => {
    if (events.length > 0) {
      applyFilters(events);
    }
  }, [applyFilters, events, searchTerm, selectedHashtags, showOnlyCertified, favoriteHashtags, blacklistedHashtags]);

  // This effect will run when selectedCategories changes (to fetch new events)
  useEffect(() => {
    if (userLocation && initialLoadComplete) {
      fetchEvents();
    }
  }, [selectedCategories, userLocation, initialLoadComplete, fetchEvents]);

  // ===== SEARCH AND FILTERING =====
  const debouncedSearch = useCallback(
    debounce((term) => {
      if (events.length > 0) {
        applyFilters(events);
      }
    }, 300),
    [events, applyFilters]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  // ===== EVENT HANDLERS =====
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    // Pass date directly to fetchEvents to avoid timing issues
    fetchEvents(date);
  };

  const handleLoadMoreDates = () => {
    setDisplayedDates(prev => prev + 30);
    preloadEvents();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCertifiedFilterChange = (event) => {
    const newValue = event.target.checked;
    setShowOnlyCertified(newValue);
    localStorage.setItem('showOnlyCertified', JSON.stringify(newValue));
    // Filtering will be applied via the useEffect
  };

  const updateCategoryEventCounts = useCallback((eventList) => {
    const counts = {};
    eventList.forEach(event => {
      if (event.eventCategory) {
        counts[event.eventCategory] = (counts[event.eventCategory] || 0) + 1;
      }
    });
    setCategoryEventCounts(counts);
  }, []);

  const handleCategoryToggle = useCallback((category) => {
    setSelectedCategories(prev => {
      const updated = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      localStorage.setItem('selectedCategories', JSON.stringify(updated));
      return updated;
    });
    setEventCache({}); // Clear cache when changing categories
    // Fetch new events via the useEffect
  }, []);

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
      localStorage.setItem('viewMode', newMode);
      if (newMode === 'map' && !mapReady) {
        setMapReady(true);
      }
    }
  };

  const handleWelcomeCategoriesChange = (newCategories) => {
    setSelectedCategories(newCategories);
    localStorage.setItem('selectedCategories', JSON.stringify(newCategories));
    setEventCache({}); // Clear cache when changing categories
    // This will trigger a fetch in the next useEffect cycle
  };

  const handleViewDetails = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleHashtagClick = useCallback((hashtag) => {
    setSelectedHashtags(prevHashtags => {
      const updatedHashtags = prevHashtags.includes(hashtag)
        ? prevHashtags.filter(h => h !== hashtag)
        : [...prevHashtags, hashtag];
      localStorage.setItem('selectedHashtags', JSON.stringify(updatedHashtags));
      return updatedHashtags;
    });
    // The filtering will be handled by useEffect
  }, []);

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedHashtags([]);
    setShowOnlyCertified(false);
    localStorage.setItem('selectedCategories', JSON.stringify([]));
    localStorage.setItem('selectedHashtags', JSON.stringify([]));
    localStorage.setItem('showOnlyCertified', JSON.stringify(false));
    setEventCache({});
    setOpenFilterDrawer(false);
    // This will trigger a fetch in the next useEffect cycle
  };

  const toggleSearch = () => {
    setSearchExpanded(!searchExpanded);
    if (!searchExpanded) {
      // Focus the search input when expanding
      setTimeout(() => {
        const searchInput = document.getElementById('event-search-input');
        if (searchInput) {
          searchInput.focus();
        }
      }, 100);
    }
  };

  // ===== MEMOIZED VALUES =====
  const sortedEvents = useMemo(() => {
    return [...filteredEvents].sort((a, b) => {
      // Handle missing dates
      if (!a.startDate && !b.startDate) return 0;
      if (!a.startDate) return 1;
      if (!b.startDate) return -1;

      // Convert to Date objects if needed
      const dateA = a.startDate instanceof Date ? a.startDate : new Date(a.startDate);
      const dateB = b.startDate instanceof Date ? b.startDate : new Date(b.startDate);

      switch (sortBy) {
        case 'dateDesc':
          return dateB - dateA;
        case 'dateAsc':
          return dateA - dateB;
        case 'distanceAsc':
          return (a.distance || Infinity) - (b.distance || Infinity);
        case 'distanceDesc':
          return (b.distance || Infinity) - (a.distance || Infinity);
        default:
          return 0;
      }
    });
  }, [filteredEvents, sortBy]);

  // Group events by day for display
  const groupedEvents = useMemo(() => {
    return sortedEvents.reduce((acc, event) => {
      if (!event.startDate) {
        console.warn('Event missing startDate:', event);
        return acc;
      }
      
      // Convert to Date if needed
      const eventDate = event.startDate instanceof Date ? event.startDate : new Date(event.startDate);
      
      // Format consistently
      const date = format(eventDate, 'yyyy-MM-dd');
      
      if (!acc[date]) acc[date] = [];
      acc[date].push(event);
      return acc;
    }, {});
  }, [sortedEvents]);

  // ===== RENDERING HELPERS =====
  const getFormattedDateHeader = (dateStr) => {
    const date = parseISO(dateStr);
    
    if (isToday(date)) {
      return (
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="primary.main" fontWeight="bold" mr={1}>
            Today
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {format(date, 'MMMM d, yyyy')}
          </Typography>
        </Box>
      );
    }
    
    if (isTomorrow(date)) {
      return (
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="secondary.main" fontWeight="bold" mr={1}>
            Tomorrow
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {format(date, 'MMMM d, yyyy')}
          </Typography>
        </Box>
      );
    }
    
    return format(date, 'EEEE, MMMM d, yyyy');
  };

  const renderSkeletons = (count, isList = false) => (
    <Grid container spacing={isMobile ? 1 : 2}>
      {Array.from(new Array(count)).map((_, index) => (
        <Grid item xs={12} sm={isList ? 12 : 6} md={isList ? 12 : 4} key={`skeleton-${index}`}>
          <EventSkeleton isList={isList} />
        </Grid>
      ))}
    </Grid>
  );

  const hasActiveFilters = selectedCategories.length > 0 || selectedHashtags.length > 0 || showOnlyCertified;

  const renderActiveFilters = () => {
    if (!hasActiveFilters) return null;
    
    return (
      <TagsContainer>
        <Typography variant="body2" color="text.secondary" mr={1}>
          Active filters:
        </Typography>
        
        {selectedCategories.map(category => {
          const categoryInfo = categories.find(c => c.value === category);
          return (
            <FilterChip
              key={`category-${category}`}
              label={categoryInfo?.label || category}
              onDelete={() => handleCategoryToggle(category)}
              size="small"
            />
          );
        })}
        
        {selectedHashtags.map(tag => (
          <FilterChip
            key={`hashtag-${tag}`}
            label={tag}
            onDelete={() => handleHashtagClick(tag)}
            size="small"
          />
        ))}
        
        {showOnlyCertified && (
          <FilterChip
            icon={<VerifiedIcon />}
            label="Certified only"
            onDelete={() => {
              setShowOnlyCertified(false);
              localStorage.setItem('showOnlyCertified', 'false');
            }}
            size="small"
          />
        )}
        
        <IconButton 
          size="small" 
          onClick={handleClearFilters}
          sx={{ ml: 1 }}
          color="primary"
        >
          <ClearAllIcon fontSize="small" />
        </IconButton>
      </TagsContainer>
    );
  };

  // ===== RENDER COMPONENTS =====
  const renderMobileHeader = () => (
    <Box mb={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
        <Typography variant="h5" component="h1" fontWeight="600">
          Discover Events
        </Typography>
        
        <Box display="flex" gap={1}>
          <Badge 
            color="primary" 
            variant="dot" 
            invisible={!hasActiveFilters}
          >
            <IconButton
              size="small"
              onClick={() => setOpenFilterDrawer(true)}
              color="primary"
              sx={{ 
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2)
                }
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Badge>
          
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate('/login')}
            startIcon={<LoginIcon />}
            sx={{ 
              borderRadius: 20,
              textTransform: 'none',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
      
      <Search expanded={searchExpanded}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search events..."
          value={searchTerm}
          onChange={handleSearchChange}
          id="event-search-input"
          onClick={() => setSearchExpanded(true)}
          onBlur={() => {
            if (!searchTerm) {
              setSearchExpanded(false);
            }
          }}
        />
        {searchTerm && (
          <IconButton
            size="small"
            onClick={() => setSearchTerm('')}
            sx={{ position: 'absolute', right: 8 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Search>
    </Box>
  );

  const renderEventsByDay = () => {
    if (!initialLoadComplete && loading) {
      return renderSkeletons(6);
    }
    
    if (Object.keys(groupedEvents).length === 0) {
      return (
        <NotFoundContainer>
          <Box mb={3}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <PublicIcon sx={{ fontSize: 60, color: 'text.secondary', opacity: 0.5 }} />
            </motion.div>
          </Box>
          <Typography variant="h6" gutterBottom>
            No events found
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Try changing your filters or selecting a different date
          </Typography>
          {hasActiveFilters && (
            <Button 
              variant="outlined" 
              color="primary" 
              startIcon={<ClearAllIcon />}
              onClick={handleClearFilters}
              sx={{ mt: 2 }}
            >
              Clear all filters
            </Button>
          )}
        </NotFoundContainer>
      );
    }
    
    return Object.entries(groupedEvents)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, dayEvents]) => (
        <Box key={date} mb={isMobile ? 3 : 4}>
          <StickyDateHeader variant="h6" gutterBottom>
            {getFormattedDateHeader(date)}
          </StickyDateHeader>
          <StyledGrid container spacing={isMobile ? 1 : 2}>
            <AnimatePresence>
              {dayEvents.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <CustomizablePulsatingGlowBorder 
                      color1={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.borderColor : 'transparent'} 
                      color2={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.badgeColor : 'transparent'}
                    >
                      <EventCard 
                        event={event}
                        onViewDetails={() => handleViewDetails(event.id)}
                        onHashtagClick={handleHashtagClick}
                        certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                        isMobile={isMobile}
                        onJoin={() => handleViewDetails(event.id)}
                      />
                    </CustomizablePulsatingGlowBorder>
                  </motion.div>
                </Grid>
              ))}
            </AnimatePresence>
          </StyledGrid>
        </Box>
      ));
  };

  const renderEventsList = () => {
    if (!initialLoadComplete && loading) {
      return renderSkeletons(6, true);
    }
    
    if (Object.keys(groupedEvents).length === 0) {
      return (
        <NotFoundContainer>
          <Box mb={3}>
            <PublicIcon sx={{ fontSize: 60, color: 'text.secondary', opacity: 0.5 }} />
          </Box>
          <Typography variant="h6" gutterBottom>
            No events found
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Try changing your filters or selecting a different date
          </Typography>
          {hasActiveFilters && (
            <Button 
              variant="outlined" 
              color="primary" 
              startIcon={<ClearAllIcon />}
              onClick={handleClearFilters}
              sx={{ mt: 2 }}
            >
              Clear all filters
            </Button>
          )}
        </NotFoundContainer>
      );
    }
    
    return (
      <Box sx={{ 
        [theme.breakpoints.down('sm')]: { 
          mx: -1 
        }
      }}>
        {Object.entries(groupedEvents)
          .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
          .map(([date, dayEvents]) => (
            <Box key={date} mb={isMobile ? 2 : 4}>
              <StickyDateHeader variant="h6" gutterBottom>
                {getFormattedDateHeader(date)}
              </StickyDateHeader>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: isMobile ? 1 : 2
              }}>
                <AnimatePresence>
                  {dayEvents.map((event) => (
                    <motion.div
                      key={event.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box
                        sx={{
                          [theme.breakpoints.down('sm')]: {
                            px: 1
                          }
                        }}
                      >
                        <EventListItem
                          event={event}
                          onViewDetails={() => handleViewDetails(event.id)}
                          onHashtagClick={handleHashtagClick}
                          certificationStyle={
                            event.creatorIsCertified 
                              ? getCertificationStyle(event.creatorCertificationType) 
                              : null
                          }
                          isMobile={isMobile}
                          onBlockCreator={() => {}}
                          onViewCreatorProfile={() => {}}
                        />
                      </Box>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  const renderMapView = () => (
    <MapWrapper>
      <Suspense fallback={
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Typography variant="body2" ml={2}>Loading map...</Typography>
        </Box>
      }>
        {(mapReady || viewMode === 'map') && (
          <EventMap 
            events={sortedEvents}
            userLocation={userLocation}
            onViewDetails={handleViewDetails}
            onHashtagClick={handleHashtagClick}
            onJoin={() => {}}
            onReport={() => {}}
            onBlockCreator={() => {}}
            onViewCreatorProfile={() => {}}
          />
        )}
      </Suspense>
    </MapWrapper>
  );

  const activeFiltersCount = selectedCategories.length + selectedHashtags.length + (showOnlyCertified ? 1 : 0);

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        [theme.breakpoints.down('sm')]: {
          padding: 0
        }
      }}
    >
      <Fade in={true} timeout={500}>
        <StyledPaper>
          {isMobile ? (
            <>
              {renderMobileHeader()}
              <DateSelector 
                selectedDate={selectedDate} 
                onDateSelect={handleDateSelect}
                onLoadMoreDates={handleLoadMoreDates}
                displayedDates={displayedDates}
              />
              
              <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                <SortSelect
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  size="small"
                  variant="standard"
                  sx={{ flexGrow: 1, mr: 1 }}
                  renderValue={(selected) => (
                    <>
                      <SortIcon fontSize="small" />
                      <Typography variant="body2" ml={1}>
                        {selected === 'dateAsc' ? 'Soonest first' : 
                         selected === 'dateDesc' ? 'Latest first' :
                         selected === 'distanceAsc' ? 'Nearest first' :
                         'Farthest first'}
                      </Typography>
                    </>
                  )}
                >
                  <MenuItem value="dateAsc">Soonest first</MenuItem>
                  <MenuItem value="dateDesc">Latest first</MenuItem>
                  <MenuItem value="distanceAsc">Nearest first</MenuItem>
                  <MenuItem value="distanceDesc">Farthest first</MenuItem>
                </SortSelect>
                
                <ViewModeSwitcher
                  value={viewMode}
                  exclusive
                  onChange={handleViewModeChange}
                  aria-label="view mode"
                  size="small"
                >
                  <ToggleButton value="grid">
                    <ViewModuleIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton value="list">
                    <ViewListIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton value="map">
                    <PublicIcon fontSize="small" />
                  </ToggleButton>
                </ViewModeSwitcher>
              </Box>
              
              {renderActiveFilters()}
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" component="h1" fontWeight={600}>
                  Discover Public Events
                </Typography>
                <Box>
                  <Badge 
                    badgeContent={activeFiltersCount > 0 ? activeFiltersCount : null} 
                    color="primary"
                  >
                    <FilterButton
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenFilterDrawer(true)}
                      startIcon={<FilterListIcon />}
                      sx={{ mr: 1 }}
                      active={hasActiveFilters}
                    >
                      Filters
                    </FilterButton>
                  </Badge>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}
                    startIcon={<LoginIcon />}
                    sx={{ borderRadius: 20, textTransform: 'none' }}
                  >
                    Log In
                  </Button>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <Search expanded={searchExpanded || !!searchTerm}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search events..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    id="event-search-input"
                    onClick={() => setSearchExpanded(true)}
                    onBlur={() => {
                      if (!searchTerm) {
                        setSearchExpanded(false);
                      }
                    }}
                  />
                  {searchTerm && (
                    <IconButton
                      onClick={() => setSearchTerm('')}
                      sx={{ position: 'absolute', right: 8 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Search>
                
                <Box ml={2} display="flex" alignItems="center">
                  <SortSelect
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    variant="standard"
                    sx={{ minWidth: 180 }}
                    renderValue={(selected) => (
                      <>
                        <SortIcon />
                        <Typography variant="body2" ml={1}>
                          {selected === 'dateAsc' ? 'Starting Soonest' : 
                           selected === 'dateDesc' ? 'Starting Latest' :
                           selected === 'distanceAsc' ? 'Nearest First' :
                           'Farthest First'}
                        </Typography>
                      </>
                    )}
                  >
                    <MenuItem value="dateAsc">Starting Soonest</MenuItem>
                    <MenuItem value="dateDesc">Starting Latest</MenuItem>
                    <MenuItem value="distanceAsc">Nearest First</MenuItem>
                    <MenuItem value="distanceDesc">Farthest First</MenuItem>
                  </SortSelect>
                  
                  <Box ml={2}>
                    <ViewModeSwitcher
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                    >
                      <ToggleButton value="grid">
                        <ViewModuleIcon />
                      </ToggleButton>
                      <ToggleButton value="list">
                        <ViewListIcon />
                      </ToggleButton>
                      <ToggleButton value="map">
                        <PublicIcon />
                      </ToggleButton>
                    </ViewModeSwitcher>
                  </Box>
                </Box>
              </Box>

              <DateSelector 
                selectedDate={selectedDate} 
                onDateSelect={handleDateSelect}
                onLoadMoreDates={handleLoadMoreDates}
                displayedDates={displayedDates}
              />
              
              {renderActiveFilters()}
            </>
          )}

          {loading && !initialLoadComplete && (
            <Box display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Alert 
              severity="error" 
              sx={{ my: 2 }}
              action={
                <Button 
                  color="inherit" 
                  size="small" 
                  onClick={() => fetchEvents()}
                >
                  Retry
                </Button>
              }
            >
              {error}
            </Alert>
          )}

          <Fade in={!loading || initialLoadComplete}>
            <Box>
              {viewMode === 'grid' && renderEventsByDay()}
              {viewMode === 'list' && renderEventsList()}
              {viewMode === 'map' && renderMapView()}
            </Box>
          </Fade>
        </StyledPaper>
      </Fade>

      {/* Floating Action Button */}
      {!isMobile && (
        <ActionButtonContainer>
          <ActionButton
            variant="contained"
            color="primary"
            onClick={() => navigate('/login')}
            startIcon={<LoginIcon />}
          >
            Sign In to Create Events
          </ActionButton>
        </ActionButtonContainer>
      )}

      {/* Filter Drawer for Mobile */}
      <FilterDrawer
        anchor="bottom"
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        onOpen={() => setOpenFilterDrawer(true)}
      >
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <IconButton onClick={() => setOpenFilterDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Typography variant="h6" gutterBottom>
          Filter Events
        </Typography>
        
        <Divider sx={{ my: 1 }} />
        
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnlyCertified}
                onChange={handleCertifiedFilterChange}
                color="primary"
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <VerifiedIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Show certified events only</Typography>
              </Box>
            }
          />
        </Box>
        
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center"
          onClick={() => setExpandedFilters(!expandedFilters)}
          sx={{ cursor: 'pointer' }}
          mb={1}
        >
          <Typography variant="subtitle1" fontWeight={500}>
            Event Categories
          </Typography>
          <IconButton size="small">
            {expandedFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        
        <Collapse in={expandedFilters}>
          <Box mb={2}>
            <Grid container spacing={1}>
              {categories.map((category) => {
                const isSelected = selectedCategories.includes(category.value);
                return (
                  <Grid item key={category.value}>
                    <CategoryChip
                      icon={<category.icon fontSize="small" />}
                      label={`${category.label} (${categoryEventCounts[category.value] || 0})`}
                      onClick={() => handleCategoryToggle(category.value)}
                      color="primary"
                      variant={isSelected ? "filled" : "outlined"}
                      size="small"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Collapse>
        
        {selectedHashtags.length > 0 && (
          <Box my={2}>
            <Typography variant="subtitle2" gutterBottom>
              Selected Hashtags
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={0.5}>
              {selectedHashtags.map(tag => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleHashtagClick(tag)}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        )}
        
        <Box mt={3} mb={2} display="flex" gap={1}>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleClearFilters}
            startIcon={<ClearAllIcon />}
            sx={{ flex: 1 }}
          >
            Clear All
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            sx={{ flex: 1 }}
            onClick={() => setOpenFilterDrawer(false)}
          >
            Apply
          </Button>
        </Box>
      </FilterDrawer>

      {/* Welcome Dialog for first time users */}
      <WelcomeCategoriesDialog
        open={showWelcomeDialog}
        onClose={() => {
          setShowWelcomeDialog(false);
          localStorage.setItem('hideWelcomeDialog', 'true');
        }}
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoriesChange={handleWelcomeCategoriesChange}
        todayEventCounts={categoryEventCounts}
      />

      {/* Error Snackbar */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ 
          vertical: isMobile ? 'top' : 'bottom', 
          horizontal: 'center' 
        }}
      >
        <Alert 
          onClose={() => setError(null)} 
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PublicEventBrowser;