import React, { useState, useEffect, useCallback } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { getLanguageByCode } from './config/languageConfig';
import { setupInterceptors, removeInterceptors } from './utils/api';
import { QueryClient, QueryClientProvider } from 'react-query';

// Material-UI components
import { CssBaseline, CircularProgress, Box, AppBar, Toolbar, Typography, Container, Slide, Snackbar, Button } from '@mui/material';

// Date adapter for localization
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Custom components
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Event from './pages/Event';
import Friends from './pages/Friends';
import CreateEvent from './pages/CreateEvent';
import TermsOfService from './pages/TermsOfService';
import MyEvents from './pages/MyEvents';
import EventDetails from './pages/EventDetails';
import AdvancedContactImport from './components/AdvancedContactImport';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import ServiceWorkerUpdateNotifier from './ServiceWorkerUpdateNotifier';
import InvitationHandler from './components/InvitationHandler';
import LanguageSwitcher from './components/LanguageSwitcher';
import ThemeSwitcher from './components/ThemeSwitcher';
import Maintenance from './components/Maintenance';
import ViewProfile from './components/ViewProfile';
import PublicEventBrowser from './components/PublicEventBrowser';
import PublicEventDetails from './components/PublicEventDetails';
import { api } from './utils/api';

import logo from './assets/logo.png';

const queryClient = new QueryClient();

function PrivateRoute({ children }) {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }
  
  return currentUser ? children : <Navigate to="/login" />;
}

function PublicRoute({ children }) {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return currentUser ? <Navigate to="/" /> : children;
}

function AppContent() {
  const { currentUser, getIdToken } = useAuth();
  const { i18n } = useTranslation();
  const currentLanguage = getLanguageByCode(i18n.language);
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  const [headerVisible, setHeaderVisible] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showNotificationPrompt, setShowNotificationPrompt] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setupInterceptors(currentUser, getIdToken);
      checkNotificationSubscription();
    } else {
      removeInterceptors();
    }

    return () => {
      removeInterceptors();
    };
  }, [currentUser, getIdToken]);

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('SW registered: ', registration);
      } catch (registrationError) {
        console.log('SW registration failed: ', registrationError);
      }
    });
  }

  const checkNotificationSubscription = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        setIsSubscribed(!!subscription);
        if (!subscription) {
          setShowNotificationPrompt(true);
        }
      } catch (error) {
        console.error('Error checking notification subscription:', error);
      }
    }
  };

  const subscribeToNotifications = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
        });

        const token = await getIdToken();
        await api.post('/notifications/subscribe', { subscription }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setIsSubscribed(true);
        setShowNotificationPrompt(false);
        console.log('User subscribed to notifications');
      } catch (error) {
        console.error('Failed to subscribe to notifications:', error);
      }
    }
  };

  const unsubscribeFromNotifications = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          await subscription.unsubscribe();
          const token = await getIdToken();
          await api.post('/notifications/unsubscribe', {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setIsSubscribed(false);
          console.log('User unsubscribed from notifications');
        }
      } catch (error) {
        console.error('Failed to unsubscribe from notifications:', error);
      }
    }
  };

  const toggleHeader = useCallback(() => {
    setHeaderVisible(prev => !prev);
    if (!headerVisible) {
      setTimeout(() => setHeaderVisible(false), 5000);
    }
  }, [headerVisible]);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLanguage.dateLocale}>
        <Router>
          <Box sx={{ position: 'relative', minHeight: '100vh' }}>
            <Slide direction="down" in={headerVisible} mountOnEnter unmountOnExit>
              <AppBar position="fixed" sx={{ 
                backgroundColor: 'rgba(30, 30, 30, 0.8)', 
                backdropFilter: 'blur(10px)',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <Toolbar>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <img src={logo} alt="Logo" style={{ height: '40px', cursor: 'pointer' }} />
                  </Typography>
                  <LanguageSwitcher />
                  <ThemeSwitcher />
                  {currentUser && (
                    <Button 
                      color="inherit" 
                      onClick={isSubscribed ? unsubscribeFromNotifications : subscribeToNotifications}
                    >
                      {isSubscribed ? 'Disable Notifications' : 'Enable Notifications'}
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
            </Slide>
            
            <Box
              onClick={toggleHeader}
              sx={{
                position: 'fixed',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '50px',
                height: '10px',
                backgroundColor: headerVisible ? 'transparent' : 'rgba(255, 255, 255, 0.3)',
                cursor: 'pointer',
                zIndex: 1100,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  height: '6px',
                },
                '&::after': headerVisible ? {
                  content: '""',
                  position: 'absolute',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '5px solid transparent',
                  borderRight: '5px solid transparent',
                  borderBottom: '5px solid rgba(255, 255, 255, 0.5)',
                } : {},
              }}
            />

            <Container maxWidth="lg" style={{ 
              marginTop: headerVisible ? '80px' : '30px', 
              transition: 'margin-top 0.3s',
              paddingBottom: '60px'
            }}>
              <Routes>
                {/* Routes publiques - accessibles sans connexion */}
                <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                <Route path="/" element={currentUser ? <Home /> : <PublicEventBrowser />} />
                <Route path="/event/:eventId" element={<PublicEventDetails />} />
                <Route path="/terms" element={<TermsOfService />} />
                
                {/* Routes privées - nécessitent une connexion */}
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path="/event" element={<PrivateRoute><Event /></PrivateRoute>} />
                <Route path="/friends" element={<PrivateRoute><Friends><AdvancedContactImport /></Friends></PrivateRoute>} />
                <Route path="/create-event" element={<PrivateRoute><CreateEvent /></PrivateRoute>} />
                <Route path="/my-events" element={<PrivateRoute><MyEvents /></PrivateRoute>} />
                <Route path="/invite/:inviteCode" element={<InvitationHandler />} />
                <Route path="/profile/:userId" element={<PrivateRoute><ViewProfile /></PrivateRoute>} />
              </Routes>
            </Container>
            <PWAInstallPrompt />
            <ServiceWorkerUpdateNotifier />
          </Box>
        </Router>
      </LocalizationProvider>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showNotificationPrompt}
        message="Would you like to receive push notifications?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowNotificationPrompt(false)}>
              No, thanks
            </Button>
            <Button color="primary" size="small" onClick={subscribeToNotifications}>
              Yes, please
            </Button>
          </React.Fragment>
        }
      />
    </QueryClientProvider>
  );
}

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;