export const getCertificationStyle = (certificationType) => {
    switch (certificationType) {
      case 'personal':
        return { 
          borderColor: '#0d47a1', // Bleu foncé
          badgeColor: '#0d47a1',
          icon: 'verified' 
        };
      case 'organization':
        return { 
          borderColor: '#ffd700', // Or
          badgeColor: '#ffd700',
          icon: 'verified_organization' 
        };
      default:
        return null;
    }
  };