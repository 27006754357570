import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import L from 'leaflet';
import { 
  MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, 
  Circle, LayerGroup, ZoomControl 
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { 
  Typography, Button, Box, useMediaQuery, useTheme, Chip,
  Card, CardContent, CardActions, Fade, IconButton, Tooltip,
  CardMedia, Avatar, Divider, Collapse
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import MapIcon from '@mui/icons-material/Map';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parseISO, format, isValid, addHours, isBefore } from 'date-fns';
import EventOptionsMenu from './EventOptionsMenu';
import ReportDialog from './ReportDialog';

// Fix Leaflet icon issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Styled components for optimal performance and appearance
const StyledPopup = styled(Popup)(({ theme }) => ({
  '& .leaflet-popup-content-wrapper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    overflow: 'hidden',
    minWidth: 220, // Réduction de la largeur du popup
    maxWidth: 260, // Réduction de la largeur max du popup
    boxShadow: theme.shadows[8],
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    transition: 'all 0.3s ease',
  },
  '& .leaflet-popup-tip': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
  },
  '& .leaflet-popup-content': {
    margin: 0,
    width: '100% !important',
  },
  '& .leaflet-popup-close-button': {
    display: 'none', // We'll use our own close button for better control
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'visible',
  borderRadius: theme.shape.borderRadius,
}));

const EventCardHeader = styled(Box)(({ theme, categoryColor }) => ({
  position: 'relative',
  padding: theme.spacing(1), // Réduction du padding
  background: `linear-gradient(135deg, ${alpha(categoryColor || theme.palette.primary.main, 0.15)} 0%, ${alpha(categoryColor || theme.palette.primary.main, 0.05)} 100%)`,
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const EventImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 140,
  overflow: 'hidden',
}));

const EventImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.4) 100%)',
}));

const PopupCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 10,
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  color: theme.palette.text.secondary,
  padding: 4,
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    color: theme.palette.text.primary,
  },
}));

const EventContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1), // Réduction du padding
  '&:last-child': {
    paddingBottom: theme.spacing(1),
  }
}));

const EventActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1), // Réduction du padding
  justifyContent: 'space-between',
}));

const StyledButton = styled(Button)(({ theme, size }) => ({
  fontWeight: 600,
  fontSize: size === 'small' ? '0.7rem' : '0.75rem',
  padding: size === 'small' ? theme.spacing(0.25, 0.75) : theme.spacing(0.5, 1),
  borderRadius: 16,
  boxShadow: 'none',
  textTransform: 'none',
  minWidth: size === 'small' ? 'auto' : 70,
  flex: 1,
}));

const CategoryBadge = styled(Chip)(({ theme, categoryColor }) => ({
  backgroundColor: alpha(categoryColor || theme.palette.primary.light, 0.2),
  color: categoryColor || theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '0.65rem',
  height: 20,
  marginRight: theme.spacing(0.75),
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5), // Réduction du margin
  '& svg': {
    fontSize: '0.9rem',
    marginRight: theme.spacing(0.75),
    color: theme.palette.text.secondary,
  },
}));

const MapControlsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 999,
  bottom: 20,
  right: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const MapControlButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  width: 36,
  height: 36,
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  zIndex: 999,
  top: 10,
  right: 10,
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  boxShadow: theme.shadows[2],
  height: 32,
  '& .MuiChip-label': {
    fontSize: '0.75rem',
  }
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: '0.8rem',
  marginRight: theme.spacing(1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
}));

const ExpandButton = styled(IconButton)(({ theme, expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  padding: 4,
  marginLeft: 'auto',
}));

// Category colors and icon mapping
const CATEGORY_COLORS = {
  culture: '#9C27B0',
  sports: '#4CAF50',
  family: '#FF9800',
  nightlife: '#2196F3',
  professional: '#607D8B',
  markets: '#FFC107',
  social: '#E91E63',
  education: '#F44336',
  tech: '#00BCD4',
  wellness: '#CDDC39',
  default: '#9E9E9E'
};

const CATEGORY_COLOR_MAP = {
  culture: 'violet',
  sports: 'green',
  family: 'orange',
  nightlife: 'blue',
  professional: 'gray',
  markets: 'yellow',
  social: 'pink',
  education: 'red',
  tech: 'cyan',
  wellness: 'gold',
  default: 'blue'
};

// Create cached icons for better performance
const iconCache = {};
const createEventIcon = (category) => {
  const colorName = CATEGORY_COLOR_MAP[category] || CATEGORY_COLOR_MAP.default;
  
  if (!iconCache[colorName]) {
    iconCache[colorName] = new L.Icon({
      iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${colorName}.png`,
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
  }
  
  return iconCache[colorName];
};

// User location icon (red)
const userIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Set up clustering for better performance (or a fallback for markers)
function setupClusterGroup(map) {
  // Check if marker cluster plugin is available
  try {
    // If MarkerClusterGroup is available, use it
    if (typeof L.MarkerClusterGroup === 'function') {
      const clusterGroup = L.MarkerClusterGroup({
        disableClusteringAtZoom: 14,
        maxClusterRadius: 50,
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        chunkedLoading: true,
        iconCreateFunction: (cluster) => {
          const count = cluster.getChildCount();
          let size = 40;
          if (count > 10) size = 50;
          if (count > 20) size = 60;
          
          return L.divIcon({
            html: `<div style="
              width: ${size}px;
              height: ${size}px;
              background-color: #1976d2;
              color: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              box-shadow: 0 2px 5px rgba(0,0,0,0.3);
            ">${count}</div>`,
            className: '',
            iconSize: L.point(size, size)
          });
        }
      });
      map.addLayer(clusterGroup);
      return clusterGroup;
    }
  } catch (e) {
    console.warn('MarkerClusterGroup not available, using regular layer group');
  }
  
  // Fallback to regular layer group if MarkerClusterGroup is not available
  const layerGroup = L.layerGroup();
  map.addLayer(layerGroup);
  return layerGroup;
}

// MapController component for managing map interactions
function MapController({ center, zoom, events, userLocation, onMapReady }) {
  const map = useMap();
  const clusterRef = useRef(null);
  
  // Initialize the map and clustering
  useEffect(() => {
    if (!map) return;
    
    if (onMapReady) onMapReady(map);
    
    if (!clusterRef.current) {
      clusterRef.current = setupClusterGroup(map);
    }
    
    // Clean up when unmounting
    return () => {
      if (clusterRef.current) {
        map.removeLayer(clusterRef.current);
        clusterRef.current = null;
      }
    };
  }, [map, onMapReady]);

  // Handle map resizing
  useMapEvents({
    resize: () => map.invalidateSize()
  });

  // Set map view and bounds when data changes
  useEffect(() => {
    if (!map) return;
    
    try {
      // Focus on user location with a ~30km radius when available
      if (userLocation) {
        // Zoom level 10 typically shows about 30km radius on most maps
        map.setView([userLocation.latitude, userLocation.longitude], 10);
      } else if (center) {
        map.setView(center, zoom);
      } else if (events.length > 0) {
        const bounds = L.latLngBounds(
          events.map(e => [e.coordinates.latitude, e.coordinates.longitude])
        );
        map.fitBounds(bounds, { padding: [50, 50], maxZoom: 13 });
      }
    } catch (error) {
      console.error("Error fitting map bounds:", error);
      // Fallback to center view if bounds calculation fails
      map.setView(center || [0, 0], zoom || 10);
    }
  }, [map, center, zoom, events, userLocation]);

  // Update markers when events change
  useEffect(() => {
    if (!map || !clusterRef.current || !events.length) return;
    
    // Clear previous markers
    clusterRef.current.clearLayers();
    
    // Add event markers to the cluster group
    events.forEach(event => {
      if (event?.coordinates?.latitude == null || event?.coordinates?.longitude == null) return;
      
      const marker = L.marker(
        [event.coordinates.latitude, event.coordinates.longitude],
        { icon: createEventIcon(event.eventCategory) }
      );
      
      // Create popup DOM element and content
      const popupDiv = document.createElement('div');
      popupDiv.innerHTML = `
        <div class="event-popup" data-event-id="${event.id}">
          <h3>${event.title}</h3>
          <p>${event.description ? event.description.substring(0, 100) + '...' : ''}</p>
          <p>Click for details</p>
        </div>
      `;
      
      // Handle popup click (to show detailed popup)
      popupDiv.addEventListener('click', (e) => {
        // Find matching marker in React components and open its popup
        const customEvent = new CustomEvent('openEventPopup', {
          detail: { eventId: event.id }
        });
        document.dispatchEvent(customEvent);
        e.stopPropagation();
      });
      
      marker.bindPopup(popupDiv);      
      clusterRef.current.addLayer(marker);
    });
  }, [map, events]);

  return null;
}

// Main EventMap component
const EventMap = ({ 
  events, 
  userLocation, 
  onViewDetails, 
  onJoin, 
  onReport, 
  onBlockCreator, 
  onViewCreatorProfile 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [activePopupEventId, setActivePopupEventId] = useState(null);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [expandedDescription, setExpandedDescription] = useState({});
  
  // Filter valid events
  const validEvents = useMemo(() => events.filter(event => 
    event?.coordinates?.latitude != null && 
    event?.coordinates?.longitude != null
  ), [events]);

  // Count events by category for filter badge
  const eventCount = useMemo(() => validEvents.length, [validEvents]);

  // Determine map center
  const mapCenter = useMemo(() => {
    if (userLocation?.latitude && userLocation?.longitude) {
      return [userLocation.latitude, userLocation.longitude];
    } else if (validEvents.length > 0) {
      return [validEvents[0].coordinates.latitude, validEvents[0].coordinates.longitude];
    } else {
      return [46.2044, 6.1432]; // Default to Geneva
    }
  }, [userLocation, validEvents]);

  // Handle map ready
  const handleMapReady = useCallback((map) => {
    setMapInstance(map);
    
    // Add click listener to close popups when clicking outside
    map.on('click', () => {
      closeAllPopups();
    });
  }, []);

  // Focus on user location
  const goToUserLocation = useCallback(() => {
    if (userLocation && mapInstance) {
      mapInstance.setView(
        [userLocation.latitude, userLocation.longitude], 
        10, // Zoom level for ~30km radius
        { animate: true }
      );
    }
  }, [userLocation, mapInstance]);

  // Format event date for display
  const formatEventDate = useCallback((dateString) => {
    if (!dateString) return 'Date not available';
    
    try {
      const date = typeof dateString === 'object' && dateString._seconds
        ? new Date(dateString._seconds * 1000)
        : parseISO(dateString);
      
      return isValid(date) ? format(date, 'EEE, MMM d • h:mm a') : 'Invalid date';
    } catch (error) {
      console.error("Error formatting date:", error);
      return 'Date error';
    }
  }, []);

  // Check if event is starting soon (within 24 hours)
  const isEventSoon = useCallback((startDate) => {
    try {
      const now = new Date();
      const eventDate = new Date(startDate);
      const in24Hours = addHours(now, 24);
      return isValid(eventDate) && isBefore(eventDate, in24Hours) && isBefore(now, eventDate);
    } catch (error) {
      return false;
    }
  }, []);

  // Handle report button click
  const handleReport = useCallback((event) => {
    setSelectedEvent(event);
    setOpenReportDialog(true);
  }, []);

  // Handle report dialog close
  const handleCloseReport = useCallback(() => {
    setOpenReportDialog(false);
    setSelectedEvent(null);
  }, []);

  // Open location in Google Maps
  const openGoogleMaps = useCallback((lat, lng) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
  }, []);

  // Toggle description expansion
  const toggleDescription = useCallback((eventId) => {
    setExpandedDescription(prev => ({
      ...prev,
      [eventId]: !prev[eventId]
    }));
  }, []);

  // Close all popups
  const closeAllPopups = useCallback(() => {
    if (mapInstance) {
      mapInstance.closePopup();
      setActivePopupEventId(null);
    }
  }, [mapInstance]);

  // Close a specific popup
  const closePopup = useCallback((eventId) => {
    if (mapInstance) {
      // Find and close the popup
      const popups = document.querySelectorAll('.leaflet-popup');
      popups.forEach(popup => {
        if (popup.querySelector(`[data-event-id="${eventId}"]`)) {
          mapInstance.closePopup();
        }
      });
    }
    setActivePopupEventId(null);
  }, [mapInstance]);

  // Listen for custom event from MapController
  useEffect(() => {
    const handleOpenEventPopup = (e) => {
      const eventId = e.detail.eventId;
      const event = validEvents.find(event => event.id === eventId);
      
      // Close any open popups first
      if (mapInstance) {
        mapInstance.closePopup();
      }
      
      if (event) {
        setActivePopupEventId(eventId);
      }
    };
    
    document.addEventListener('openEventPopup', handleOpenEventPopup);
    return () => {
      document.removeEventListener('openEventPopup', handleOpenEventPopup);
    };
  }, [validEvents, mapInstance]);

  // Render popup content for markers
  const renderPopupContent = useCallback((event) => {
    const categoryColor = CATEGORY_COLORS[event.eventCategory] || CATEGORY_COLORS.default;
    const isSoon = isEventSoon(event.startDate);
    const isDescriptionExpanded = expandedDescription[event.id] || false;
    
    // Get a clean description with truncation if needed
    const getDescription = () => {
      if (!event.description) return '';
      const desc = event.description.replace(/<[^>]*>/g, ''); // Remove any HTML tags
      if (isDescriptionExpanded) return desc;
      return desc.length > 80 ? `${desc.substring(0, 80)}...` : desc; // Réduction de la longueur
    };
    
    return (
      <Fade in={true} timeout={300}>
        <StyledCard>
          {/* Close button */}
          <PopupCloseButton 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              closePopup(event.id);
            }}
          >
            <CloseIcon fontSize="small" />
          </PopupCloseButton>
          
          <EventCardHeader categoryColor={categoryColor}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="subtitle1" sx={{ 
                fontWeight: 600,
                fontSize: '0.9rem', // Réduction de la taille de police
                lineHeight: 1.2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>
                {event.title}
              </Typography>
              
              <EventOptionsMenu
                onReport={() => handleReport(event)}
                onBlock={() => onBlockCreator?.(event.createdBy)}
                onViewProfile={() => onViewCreatorProfile?.(event.createdBy)}
              />
            </Box>
          </EventCardHeader>
          
          <EventContent>
            {/* Creator info */}
            <Box display="flex" alignItems="center" mb={0.5}>
              <UserAvatar src={event.creatorPhotoURL}>
                {(event.username || 'A')[0].toUpperCase()}
              </UserAvatar>
              <Typography variant="body2" fontSize="0.75rem" sx={{ display: 'flex', alignItems: 'center' }}>
                {event.username || 'Anonymous'}
                {event.creatorIsCertified && (
                  <Box component="span" ml={0.5} display="inline-flex" alignItems="center">
                    {event.creatorCertificationType === 'personal' 
                      ? <VerifiedIcon fontSize="small" color="primary" sx={{ fontSize: '0.75rem' }} /> 
                      : <VerifiedUserIcon fontSize="small" color="warning" sx={{ fontSize: '0.75rem' }} />
                    }
                  </Box>
                )}
                {event.isAutomated && (
                  <Tooltip title="Automated event">
                    <SmartToyIcon fontSize="small" sx={{ ml: 0.5, fontSize: '0.75rem' }} />
                  </Tooltip>
                )}
              </Typography>
            </Box>
            
            {/* Event description */}
            {event.description && (
              <Box mb={0.5}>
                <Typography variant="body2" fontSize="0.75rem" color="text.secondary">
                  {getDescription()}
                </Typography>
                {event.description.length > 80 && (
                  <Box textAlign="center" mt={0.25}>
                    <ExpandButton
                      size="small"
                      expanded={isDescriptionExpanded}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDescription(event.id);
                      }}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </ExpandButton>
                  </Box>
                )}
              </Box>
            )}
            
            <Divider sx={{ my: 0.5 }} />
            
            {/* Event details */}
            <InfoItem>
              <AccessTimeIcon fontSize="small" />
              <Typography variant="body2" fontSize="0.75rem">
                {formatEventDate(event.startDate)}
              </Typography>
            </InfoItem>
            
            <InfoItem>
              <LocationOnIcon fontSize="small" />
              <Typography variant="body2" fontSize="0.75rem" noWrap sx={{ 
                maxWidth: '160px', // Réduction de la largeur
                overflow: 'hidden',
                textOverflow: 'ellipsis' 
              }}>
                {event.venueName || event.address}
                {event.distance ? ` (${event.distance.toFixed(1)} km)` : ''}
              </Typography>
            </InfoItem>

            {event.price && (
              <InfoItem>
                <AttachMoneyIcon fontSize="small" />
                <Typography variant="body2" fontSize="0.75rem">
                  {event.price}
                </Typography>
              </InfoItem>
            )}
          </EventContent>
          
          <EventActions>
            <StyledButton 
              variant="outlined" 
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onJoin?.(event.id);
              }}
            >
              Join
            </StyledButton>
            <StyledButton 
              variant="contained" 
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onViewDetails) {
                  onViewDetails(event);
                }
              }}
              sx={{ ml: 1 }}
            >
              Details
            </StyledButton>
            <Tooltip title="Open in Google Maps" arrow>
              <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openGoogleMaps(event.coordinates.latitude, event.coordinates.longitude);
                }}
                sx={{ ml: 1 }}
              >
                <MapIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </EventActions>
        </StyledCard>
      </Fade>
    );
  }, [
    formatEventDate, handleReport, isEventSoon, theme.palette.background.paper, 
    theme.palette.divider, onBlockCreator, onJoin, onViewCreatorProfile, 
    onViewDetails, openGoogleMaps, expandedDescription, toggleDescription, closePopup
  ]);

  // Render event markers
  const renderEventMarkers = useMemo(() => {
    return validEvents.map(event => (
      <Marker 
        key={`event-marker-${event.id}`}
        position={[event.coordinates.latitude, event.coordinates.longitude]}
        icon={createEventIcon(event.eventCategory)}
        eventHandlers={{
          click: () => {
            // Close any existing popups first
            if (activePopupEventId && activePopupEventId !== event.id) {
              closePopup(activePopupEventId);
            }
            setActivePopupEventId(event.id);
          }
        }}
      >
        <StyledPopup
          autoClose={false}
          closeOnClick={false}
        >
          {renderPopupContent(event)}
        </StyledPopup>
      </Marker>
    ));
  }, [validEvents, renderPopupContent, activePopupEventId, closePopup]);

  // Initial map zoom level (lower for mobile)
  const initialZoom = useMemo(() => {
    // Use zoom level 10 for a ~30km radius view
    if (isMobile) return 9;
    return 10;
  }, [isMobile]);

  // Handle toggling day/night mode for map
  const toggleMapMode = useCallback(() => {
    if (!mapInstance) return;
    
    // Logic for toggling between day/night map tiles would go here
    // Since we're using OpenStreetMap which doesn't have a night mode built-in,
    // this function is a placeholder for custom implementation
  }, [mapInstance]);

  // Focus on all events
  const showAllEvents = useCallback(() => {
    if (!mapInstance || validEvents.length === 0) return;
    
    try {
      const bounds = L.latLngBounds(
        validEvents.map(e => [e.coordinates.latitude, e.coordinates.longitude])
      );
      
      // Include user location in bounds if available
      if (userLocation) {
        bounds.extend([userLocation.latitude, userLocation.longitude]);
      }
      
      mapInstance.fitBounds(bounds, { padding: [50, 50], maxZoom: 13 });
    } catch (error) {
      console.error("Error fitting map bounds:", error);
    }
  }, [mapInstance, validEvents, userLocation]);

  return (
    <>
      <Box 
        sx={{ 
          position: 'relative',
          height: '100%', 
          width: '100%',
          borderRadius: isMobile ? 0 : theme.shape.borderRadius,
          overflow: 'hidden'
        }}
      >
        {/* Event count badge */}
        <FilterChip
          icon={<FilterAltIcon />}
          label={`${eventCount} events`}
          color="primary"
          variant="outlined"
          onClick={(e) => setFilterMenuAnchor(e.currentTarget)}
        />

        {/* Main map container */}
        <MapContainer 
          center={mapCenter} 
          zoom={initialZoom} 
          style={{ height: '100%', width: '100%' }}
          zoomControl={false}
          attributionControl={false}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          tap={true}
          preferCanvas={true}
        >
          {/* Base map layer */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            maxZoom={19}
            maxNativeZoom={18}
          />

          {/* Zoom control (positioned differently on mobile) */}
          <ZoomControl position={isMobile ? "bottomleft" : "topleft"} />
          
          {/* Map controller for complex interactions */}
          <MapController 
            center={mapCenter} 
            zoom={initialZoom} 
            events={validEvents} 
            userLocation={userLocation}
            onMapReady={handleMapReady}
          />
          
          {/* User location marker and radius */}
          {userLocation && (
            <LayerGroup>
              <Marker 
                position={[userLocation.latitude, userLocation.longitude]}
                icon={userIcon}
                zIndexOffset={1000}
              >
                <Popup>
                  <Typography variant="body2" fontWeight={500}>Your location</Typography>
                </Popup>
              </Marker>
              <Circle 
                center={[userLocation.latitude, userLocation.longitude]}
                radius={30000} // 30km radius
                pathOptions={{ 
                  fillColor: theme.palette.primary.main, 
                  fillOpacity: 0.1, 
                  color: theme.palette.primary.main, 
                  weight: 1,
                  opacity: 0.8
                }}
              />
            </LayerGroup>
          )}

          {/* Event markers */}
          {renderEventMarkers}
        </MapContainer>

        {/* Map control buttons */}
        <MapControlsContainer>
          {userLocation && (
            <Tooltip title="Go to my location" arrow placement="left">
              <MapControlButton 
                size="small" 
                color="primary"
                onClick={goToUserLocation}
                aria-label="My location"
              >
                <MyLocationIcon />
              </MapControlButton>
            </Tooltip>
          )}
          
          <Tooltip title="Show all events" arrow placement="left">
            <MapControlButton 
              size="small" 
              color="primary"
              onClick={showAllEvents}
              aria-label="Show all events"
            >
              <EventIcon />
            </MapControlButton>
          </Tooltip>
        </MapControlsContainer>
      </Box>

      {/* Report dialog */}
      {selectedEvent && (
        <ReportDialog
          open={openReportDialog}
          onClose={handleCloseReport}
          eventId={selectedEvent.id}
          latitude={selectedEvent.coordinates?.latitude}
          longitude={selectedEvent.coordinates?.longitude}
        />
      )}
    </>
  );
};

export default React.memo(EventMap);