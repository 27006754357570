import { EnFlag, FrFlag, DeFlag, ItFlag } from '../components/FlagIcons';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';

export const languages = [
  {
    code: 'en',
    name: 'English',
    nativeName: 'English',
    Flag: EnFlag,
    dateLocale: enLocale
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français',
    Flag: FrFlag,
    dateLocale: frLocale
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch',
    Flag: DeFlag,
    dateLocale: deLocale
  },
  {
    code: 'it',
    name: 'Italian',
    nativeName: 'Italiano',
    Flag: ItFlag,
    dateLocale: itLocale
  }
];

export const getLanguageByCode = (code) => {
  const language = languages.find(lang => lang.code === code);
  return language || languages[0]; // Return the first language (English) as default if not found
};

export const getSupportedLanguageCodes = () => languages.map(lang => lang.code);