import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Container, Typography, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { api } from '../utils/api';


function InvitationHandler() {
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    if (!currentUser) {
      navigate('/login', { state: { from: `/invite/${inviteCode}` } });
      return;
    }

    const sendFriendRequest = async () => {
      try {
        const token = await getIdToken();
        const response = await api.get(`/friends/invitations/verify/${inviteCode}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSnackbar({ open: true, message: response.data.message, severity: 'success' });
        setTimeout(() => navigate('/friends'), 2000); // Redirect to friends page after 2 seconds
      } catch (error) {
        console.error('Error sending friend request:', error);
        setSnackbar({ 
          open: true, 
          message: error.response?.data?.error || 'An error occurred while processing the invitation', 
          severity: 'error' 
        });
      } finally {
        setLoading(false);
      }
    };

    sendFriendRequest();
  }, [inviteCode, currentUser, getIdToken, navigate]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Processing Invitation
      </Typography>
      <Button variant="contained" onClick={() => navigate('/friends')}>
        Go to Friends
      </Button>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default InvitationHandler;