import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, Typography, Avatar, Box, Chip, CircularProgress, 
  Paper, Snackbar, Alert 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../utils/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  marginBottom: theme.spacing(2),
}));

const StyledBadge = styled(Chip)(({ theme, badgeType }) => {
  const badgeStyles = {
    default: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    achievement: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    special: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'white',
    },
    rare: {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      color: 'white',
    },
    legendary: {
      background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
      color: 'black',
    },
  };

  return {
    ...badgeStyles[badgeType || 'default'],
    margin: theme.spacing(0.5),
    fontWeight: 'bold',
  };
});

const getBadgeType = (badgeName) => {
  if (badgeName.includes('Legendary')) return 'legendary';
  if (badgeName.includes('Rare')) return 'rare';
  if (badgeName.includes('Special')) return 'special';
  if (badgeName.includes('Achievement')) return 'achievement';
  return 'default';
};

const ViewProfile = () => {
  const { userId } = useParams();
  const { getIdToken } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const token = await getIdToken();
        const response = await api.get(`/user-profile/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProfile(response.data);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load user profile. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId, getIdToken]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Snackbar open={true} autoHideDuration={6000}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <StyledPaper elevation={6}>
        <StyledAvatar src={profile.photoURL} alt={profile.username}>
          {profile.username ? profile.username[0].toUpperCase() : 'U'}
        </StyledAvatar>
        <Typography variant="h4" gutterBottom>
          {profile.username}
          {profile.isCertified && (
            <Box component="span" ml={1}>
              {profile.certificationType === 'personal' ? (
                <VerifiedIcon color="primary" />
              ) : (
                <VerifiedUserIcon style={{ color: 'gold' }} />
              )}
            </Box>
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {profile.bio}
        </Typography>
        <Box mt={2}>
          {profile.badges.map((badge, index) => (
            <StyledBadge
              key={index}
              label={badge}
              badgeType={getBadgeType(badge)}
            />
          ))}
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default ViewProfile;