import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, Typography, Grid, Box, Fab, CircularProgress,
  Alert, Snackbar, Paper, TextField, Chip, IconButton, Menu, 
  MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, 
  List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, 
  Toolbar, AppBar, Tabs, Tab, Select, Divider, Pagination
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PublicIcon from '@mui/icons-material/Public';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Timestamp } from 'firebase/firestore';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';

// Components
import EventCard from '../components/EventCard';
import EventListItem from '../components/EventListItem';
import EventMap from '../components/EventMap';
import LoadingError from '../components/LoadingError';
import CustomizablePulsatingGlowBorder from '../components/PulsatingGlowBorder';
import WelcomeCategoriesDialog from '../components/WelcomeCategoriesDialog';
import PublicEventBrowser from '../components/PublicEventBrowser';

// Utils & Constants
import { categories } from '../constants/categories';
import { parseISO, format, isValid, startOfDay, endOfDay, isSameDay, addDays } from 'date-fns';
import { calculateDistance } from '../utils/distanceCalculation';
import { useCachedDistances } from '../hooks/useCachedDistances';
import { getCertificationStyle } from '../utils/certificationUtils';
import { fetchPreferredCategories, updatePreferredCategories } from '../utils/categoryUtils';
import { api } from '../utils/api';
import logo from '../assets/logo.png';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    flexGrow: 1,
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StickyDateHeader = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 11,
  padding: theme.spacing(2, 0),
}));

// Custom Hooks
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  };

  return [storedValue, setValue];
};

const useEventFiltering = (allEvents, filters) => {
  const { searchTerm, selectedCategories, preferredCategories, selectedHashtags, favoriteHashtags, blacklistedHashtags, blockedUsers, tabValue } = filters;

  return useMemo(() => {
    return allEvents.filter(event => {
      if (!event || typeof event !== 'object') return false;
      if (blockedUsers.includes(event.createdBy)) return false;
      if (tabValue === 0 && event.eventType !== 'private') return false;
      if (tabValue === 1 && event.eventType !== 'friends') return false;
      if (tabValue === 2 && event.eventType !== 'public') return false;
      
      if (searchTerm && event.title && !event.title.toLowerCase().includes(searchTerm.toLowerCase())) return false;
      
      if (selectedCategories.length > 0 && !selectedCategories.includes(event.eventCategory)) return false;
      
      if (preferredCategories.length > 0 && event.eventType === 'public' && !preferredCategories.includes(event.eventCategory)) return false;
      
      if (selectedHashtags.length > 0 && Array.isArray(event.hashtags) && !selectedHashtags.every(tag => event.hashtags.includes(tag))) return false;
      
      if (favoriteHashtags.length > 0 && Array.isArray(event.hashtags) && !event.hashtags.some(tag => favoriteHashtags.includes(tag))) return false;
      
      if (Array.isArray(event.hashtags) && Array.isArray(blacklistedHashtags) && event.hashtags.some(tag => blacklistedHashtags.includes(tag))) return false;
      
      return true;
    });
  }, [allEvents, searchTerm, selectedCategories, preferredCategories, selectedHashtags, favoriteHashtags, blacklistedHashtags, blockedUsers, tabValue]);
};

const useEventSorting = (events, sortBy, userLocation, cachedDistances) => {
  return useMemo(() => {
    if (!events.length) return [];
    
    const eventsWithDistance = events.map(event => {
      const cacheKey = `${event.id}-${userLocation?.latitude}-${userLocation?.longitude}`;
      const cachedDistance = cachedDistances[cacheKey];
      return {
        ...event,
        distance: cachedDistance ? cachedDistance.distance : Infinity,
        formattedDistance: cachedDistance ? `${cachedDistance.distance.toFixed(1)} km` : 'N/A'
      };
    });

    return [...eventsWithDistance].sort((a, b) => {
      const dateA = getDateInMilliseconds(a.startDate);
      const dateB = getDateInMilliseconds(b.startDate);
      
      switch (sortBy) {
        case 'dateDesc': return dateB - dateA;
        case 'dateAsc': return dateA - dateB;
        case 'distanceAsc': return a.distance - b.distance;
        case 'distanceDesc': return b.distance - a.distance;
        case 'popularityDesc': return (b.participants?.length || 0) - (a.participants?.length || 0);
        case 'popularityAsc': return (a.participants?.length || 0) - (b.participants?.length || 0);
        default: return 0;
      }
    });
  }, [events, sortBy, userLocation, cachedDistances]);
};

// Helper functions
const getDateInMilliseconds = (dateValue) => {
  if (!dateValue) return 0;
  
  if (typeof dateValue === 'object' && dateValue._seconds) {
    return dateValue._seconds * 1000 + (dateValue._nanoseconds / 1000000);
  } else if (typeof dateValue === 'string') {
    const parsedDate = parseISO(dateValue);
    return isValid(parsedDate) ? parsedDate.getTime() : 0;
  } else if (dateValue instanceof Date) {
    return isValid(dateValue) ? dateValue.getTime() : 0;
  }
  return 0;
};

const groupEventsByDay = (events) => {
  return events.reduce((acc, event) => {
    const eventDate = new Date(getDateInMilliseconds(event.startDate));
    if (!isValid(eventDate)) {
      console.error('Invalid event date:', event.startDate);
      return acc;
    }
    const dateKey = format(eventDate, 'yyyy-MM-dd');
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(event);
    return acc;
  }, {});
};

function Home() {
  // Router
  const navigate = useNavigate();
  
  // Auth
  const { currentUser, getIdToken } = useAuth();
  
  // Local Storage State
  const [viewMode, setViewMode] = useLocalStorage('viewMode', 'grid');
  const [tabValue, setTabValue] = useLocalStorage('selectedTab', 0);
  const [selectedHashtags, setSelectedHashtags] = useLocalStorage('selectedHashtags', []);
  const [sortBy, setSortBy] = useLocalStorage('sortBy', 'dateAsc');
  
  // UI State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [favoriteHashtags, setFavoriteHashtags] = useState([]);
  const [blacklistedHashtags, setBlacklistedHashtags] = useState([]);
  const [openHashtagDialog, setOpenHashtagDialog] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportedEvent, setReportedEvent] = useState(null);
  const [locationLoading, setLocationLoading] = useState(true);
  const [visibleDays, setVisibleDays] = useState(7);
  const [timeframe, setTimeframe] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [categoryEventCounts, setCategoryEventCounts] = useState({});
  
  // Event Data State
  const [allEvents, setAllEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [preferredCategories, setPreferredCategories] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  
  // Pagination
  const eventsPerPage = 20;
  
  // InView hook for infinite scroll
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  });
  
  // Cached distances
  const cachedDistances = useCachedDistances(allEvents, userLocation);

  // Memoized filtering
  const filteredEvents = useEventFiltering(allEvents, {
    searchTerm,
    selectedCategories,
    preferredCategories,
    selectedHashtags,
    favoriteHashtags,
    blacklistedHashtags,
    blockedUsers,
    tabValue
  });

  // Memoized sorting
  const sortedEvents = useEventSorting(filteredEvents, sortBy, userLocation, cachedDistances);
  
  // Update category event counts when events change
  useEffect(() => {
    const counts = {};
    filteredEvents.forEach(event => {
      if (event.eventCategory) {
        counts[event.eventCategory] = (counts[event.eventCategory] || 0) + 1;
      }
    });
    setCategoryEventCounts(counts);
  }, [filteredEvents]);
  
  // Memoized grouping
  const groupedEvents = useMemo(() => groupEventsByDay(sortedEvents), [sortedEvents]);
  
  // Memoized visible events
  const displayedEvents = useMemo(() => {
    if (!groupedEvents) return [];
    
    const sortedDates = Object.keys(groupedEvents).sort();
    return sortedDates.slice(0, visibleDays).flatMap(date => groupedEvents[date] || []);
  }, [groupedEvents, visibleDays]);
  
  // Memoized paginated events
  const paginatedEvents = useMemo(() => {
    const startIndex = (currentPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    return displayedEvents.slice(startIndex, endIndex);
  }, [displayedEvents, currentPage, eventsPerPage]);
  
  // Memoized total pages
  const totalPages = useMemo(() => 
    Math.ceil(displayedEvents.length / eventsPerPage), 
  [displayedEvents.length, eventsPerPage]);
  
  // Speed Dial Actions
  const speedDialActions = [
    { icon: <PersonIcon />, name: 'Profile', handleClick: () => navigate('/profile') },
    { icon: <PeopleIcon />, name: 'Friends', handleClick: () => navigate('/friends') },
    { icon: <EventIcon />, name: 'My Events', handleClick: () => navigate('/my-events') },
    { icon: <AddIcon />, name: 'Create Event', handleClick: () => navigate('/create-event') },
  ];
  
  // Get user location
  const getUserLocation = useCallback(() => {
    setLocationLoading(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setLocationLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          // Improved error handling with specific messages
          let errorMessage = 'Unable to get your location. ';
          switch(error.code) {
            case error.PERMISSION_DENIED:
              errorMessage += 'Please enable location permissions in your browser settings.';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage += 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              errorMessage += 'The request to get your location timed out.';
              break;
            default:
              errorMessage += 'An unknown error occurred.';
          }
          
          // Set default coordinates (e.g., Geneva coordinates)
          setUserLocation({ latitude: 46.2044, longitude: 6.1432 });
          setSnackbar({ 
            open: true, 
            message: errorMessage + ' Using default location.', 
            severity: 'warning' 
          });
          setLocationLoading(false);
        },
        {
          maximumAge: 60000,       // Use cached position if less than 1 minute old
          timeout: 10000,          // Wait only 10 seconds for a position
          enableHighAccuracy: false // Don't require high accuracy, saves battery
        }
      );
    } else {
      setSnackbar({ 
        open: true, 
        message: 'Geolocation is not supported by your browser. Using default location.', 
        severity: 'warning' 
      });
      // Set default coordinates
      setUserLocation({ latitude: 46.2044, longitude: 6.1432 });
      setLocationLoading(false);
    }
  }, []);
  
  // Fetch blocked users
  const fetchBlockedUsers = useCallback(async () => {
    if (!currentUser) return;
    try {
      const token = await getIdToken();
      const response = await api.get('/users/blocked', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        setBlockedUsers(response.data.blockedUsers);
      } else {
        throw new Error('Failed to fetch blocked users');
      }
    } catch (error) {
      console.error('Error fetching blocked users:', error);
      setSnackbar({ open: true, message: "Failed to fetch blocked users. Please try again.", severity: "error" });
    }
  }, [currentUser, getIdToken]);
  
  // Fetch preferred categories
  const loadCategories = useCallback(async () => {
    if (!currentUser) return;
    try {
      const token = await getIdToken();
      const categories = await fetchPreferredCategories(token);
      setPreferredCategories(categories);
    } catch (error) {
      console.error("Error loading categories:", error);
      setSnackbar({ open: true, message: 'Error fetching preferred categories', severity: 'error' });
    }
  }, [currentUser, getIdToken]);

  // Fetch user profile
  const fetchUserProfile = useCallback(async () => {
    if (!currentUser) return;
    try {
      const token = await getIdToken();
      const response = await api.get('/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPreferredCategories(response.data.preferredCategories || []);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setSnackbar({ open: true, message: 'Error fetching user profile', severity: 'error' });
    }
  }, [currentUser, getIdToken]);
  
  // Fetch events
  const fetchEvents = useCallback(async (pageToFetch = page) => {
    if (!currentUser || !userLocation || !hasMore) return;
  
    setLoading(true);
    setError(null);
    try {
      const cacheKey = `events_${tabValue}_${pageToFetch}_${userLocation.latitude.toFixed(4)}_${userLocation.longitude.toFixed(4)}_${timeframe}`;
      const { data, fromCache } = await api.getCached('/events', {
        params: {
          tab: tabValue,
          page: pageToFetch,
          limit: 50, // Réduit de 250 à 50
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
          radius: 50000,
          timeframe: timeframe
        }
      }, cacheKey);
  
      if (!fromCache) {
        console.log('Events loaded from API');
      } else {
        console.log('Events loaded from cache');
      }
  
      const newEvents = Array.isArray(data.events) ? data.events : [];
      setAllEvents(prev => {
        const updatedEvents = [...prev];
        newEvents.forEach(event => {
          const index = updatedEvents.findIndex(e => e.id === event.id);
          if (index !== -1) {
            updatedEvents[index] = event;
          } else {
            updatedEvents.push(event);
          }
        });
        return updatedEvents;
      });
      setHasMore(data.hasMore);
      setPage(pageToFetch);
    } catch (err) {
      console.error("Error fetching events:", err);
      setError("An error occurred while fetching events. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [currentUser, userLocation, tabValue, hasMore, page, timeframe, api]);
  
  // Load more events
  const loadMoreEvents = useCallback(() => {
    setVisibleDays(prevDays => prevDays + 7);
  }, []);
  
  // Check welcome dialog
  useEffect(() => {
    const hideDialog = localStorage.getItem('hideWelcomeDialog');
    const hasStoredCategories = localStorage.getItem('selectedCategories');
    if (!hideDialog && !hasStoredCategories) {
      setOpenWelcomeDialog(true);
    }
  }, []);
  
  // Initialize user location
  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);
  
  // Fetch initial data
  useEffect(() => {
    if (currentUser) {
      fetchBlockedUsers();
      loadCategories();
      fetchUserProfile();
    }
  }, [currentUser, fetchBlockedUsers, loadCategories, fetchUserProfile]);
  
  // Fetch events when location or tab changes
  useEffect(() => {
    if (userLocation) {
      fetchEvents(1);
    }
  }, [fetchEvents, userLocation, tabValue, timeframe]);
  
  // Load more events when scrolling
  useEffect(() => {
    if (inView) {
      loadMoreEvents();
    }
  }, [inView, loadMoreEvents]);
  
  // Handler functions
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };
  
  const handleViewDetails = useCallback((event) => {
    navigate(`/event/${event.id}`, { state: { eventData: event } });
  }, [navigate]);
  
  const handleJoinEvent = useCallback(async (eventId) => {
    if (!currentUser) {
      setSnackbar({ open: true, message: 'Please log in to join events.', severity: 'error' });
      return;
    }
  
    try {
      const response = await api.post(`/events/${eventId}/join`, {
        latitude: userLocation.latitude,
        longitude: userLocation.longitude
      });
  
      if (response.data.success) {
        setSnackbar({ open: true, message: 'Successfully joined the event!', severity: 'success' });
        
        setAllEvents(prevEvents => 
          prevEvents.map(event =>
            event.id === eventId
              ? { ...event, participants: [...(event.participants || []), currentUser.uid] }
              : event
          )
        );
      } else {
        setSnackbar({ open: true, message: response.data.message, severity: 'warning' });
      }
    } catch (error) {
      console.error("Error joining event:", error);
      setSnackbar({ open: true, message: 'Error joining event. Please try again.', severity: 'error' });
    }
  }, [currentUser, userLocation]);
  
  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
    setPage(1);
    setAllEvents([]);
    setHasMore(true);
    fetchEvents(1);
  }, [fetchEvents]);
  
  const handleViewModeChange = useCallback((event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  }, [setViewMode]);
  
  const handleReportEvent = useCallback((event) => {
    setReportedEvent(event);
    setReportDialogOpen(true);
  }, []);
  
  const handleBlock = useCallback(async (creatorId) => {
    try {
      const token = await getIdToken();
      const response = await api.post(`/users/block/${creatorId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        console.log(`Blocked creator with ID: ${creatorId}`);
        setSnackbar({ open: true, message: "Creator blocked successfully", severity: "success" });
        
        fetchBlockedUsers();
      } else {
        throw new Error('Failed to block user');
      }
    } catch (error) {
      console.error('Error blocking creator:', error);
      setSnackbar({ open: true, message: "Failed to block creator. Please try again.", severity: "error" });
    }
  }, [getIdToken, fetchBlockedUsers]);
  
  const handleViewCreatorProfile = useCallback((creatorId) => {
    navigate(`/profile/${creatorId}`);
  }, [navigate]);
  
  const handleSortClick = useCallback((event) => {
    setSortAnchorEl(event.currentTarget);
  }, []);
  
  const handleSortClose = useCallback(() => {
    setSortAnchorEl(null);
  }, []);
  
  const handleSortChange = useCallback((newSortBy) => {
    setSortBy(newSortBy);
    handleSortClose();
  }, [setSortBy, handleSortClose]);
  
  const handleHashtagClick = useCallback((tag) => {
    setSelectedHashtags(prevHashtags => {
      const updatedHashtags = prevHashtags.includes(tag)
        ? prevHashtags.filter(h => h !== tag)
        : [...prevHashtags, tag];
      return updatedHashtags;
    });
  }, [setSelectedHashtags]);
  
  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);
  
  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);
  
  const handleOpenCategoryDialog = useCallback(() => {
    loadCategories();
    setOpenCategoryDialog(true);
  }, [loadCategories]);
  
  const handleCloseCategoryDialog = useCallback(() => {
    setOpenCategoryDialog(false);
  }, []);
  
  const handleCategoryToggle = useCallback(async (category) => {
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(c => c !== category)
      : [...selectedCategories, category];
    
    setSelectedCategories(updatedCategories);
    
    try {
      const token = await getIdToken();
      await updatePreferredCategories(token, updatedCategories);
      setSnackbar({ open: true, message: 'Preferred categories updated successfully', severity: 'success' });
    } catch (error) {
      console.error("Error updating categories:", error);
      setSnackbar({ open: true, message: 'Failed to update preferred categories', severity: 'error' });
    }
  }, [selectedCategories, getIdToken]);
  
  const handleWelcomeCategoriesChange = useCallback((newCategories) => {
    setSelectedCategories(newCategories);
    localStorage.setItem('selectedCategories', JSON.stringify(newCategories));
    setAllEvents([]);
    setHasMore(true);
    fetchEvents(1);
  }, [fetchEvents]);
  
  // Rendering Event Cards per Day
  const EventCardsByDay = useCallback(() => {
    // Extract dates from groupedEvents and sort them
    const sortedDates = Object.keys(groupedEvents).sort();
    
    // Take only the first 'visibleDays' dates
    const visibleDates = sortedDates.slice(0, visibleDays);
    
    return visibleDates.map(date => {
      const eventsForDay = groupedEvents[date] || [];
      const eventDate = parseISO(date);
      
      if (!isValid(eventDate)) {
        console.error('Invalid date:', date);
        return null;
      }
      
      const formattedDate = format(eventDate, 'EEEE, MMMM d, yyyy');
      
      return (
        <Box key={date} mb={4}>
          <StickyDateHeader variant="h6" gutterBottom>
            {formattedDate}
          </StickyDateHeader>
          <Grid container spacing={2}>
            {eventsForDay.map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event.id}>
                <CustomizablePulsatingGlowBorder 
                  color1={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.borderColor : '#ccc'} 
                  color2={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.badgeColor : '#eee'}
                >
                  <EventCard 
                    event={{
                      ...event,
                      distance: event.distance,
                      formattedDistance: event.distance ? `${event.distance.toFixed(1)} km` : 'N/A'
                    }}
                    certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                    onViewDetails={() => handleViewDetails(event)}
                    onJoin={() => handleJoinEvent(event.id)}
                    onHashtagClick={handleHashtagClick}
                    onReport={handleReportEvent}
                    onBlock={handleBlock}
                    onViewCreatorProfile={handleViewCreatorProfile}
                  />
                </CustomizablePulsatingGlowBorder>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    });
  }, [groupedEvents, visibleDays, handleViewDetails, handleJoinEvent, handleHashtagClick, handleReportEvent, handleBlock, handleViewCreatorProfile]);
  
  // Render List View
  const EventListView = useCallback(() => {
    const sortedDates = Object.keys(groupedEvents).sort();
    
    return (
      <List>
        {sortedDates.slice(0, visibleDays).map((date) => (
          <React.Fragment key={date}>
            <StickyDateHeader variant="h6" gutterBottom>
              <Typography variant="h6">
                {format(new Date(date), 'EEEE, MMMM d, yyyy')}
              </Typography>
            </StickyDateHeader>
            {groupedEvents[date]?.map((event) => (
              <ListItem key={event.id} disablePadding>
                <EventListItem 
                  event={{
                    ...event,
                    distance: event.distance,
                    formattedDistance: event.distance ? `${event.distance.toFixed(1)} km` : 'N/A'
                  }}
                  certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                  onViewDetails={() => handleViewDetails(event)}
                  onJoin={() => handleJoinEvent(event.id)}
                  onHashtagClick={handleHashtagClick}
                  onReport={handleReportEvent}
                  onBlockCreator={handleBlock}
                  onViewCreatorProfile={handleViewCreatorProfile}
                />
              </ListItem>
            ))}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    );
  }, [groupedEvents, visibleDays, handleViewDetails, handleJoinEvent, handleHashtagClick, handleReportEvent, handleBlock, handleViewCreatorProfile]);
  
  // Main component content
  if (tabValue === 2) {
    return <PublicEventBrowser />;
  }

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box 
            component="div" 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              minWidth: 'fit-content'
            }}
          >
            <img 
              src={logo} 
              alt="Logo" 
              style={{ 
                height: '32px', 
                cursor: 'pointer',
                marginRight: '8px'
              }} 
            />
          </Box>

          <Box sx={{ 
            display: 'flex', 
            flexGrow: 1, 
            alignItems: 'center',
            gap: 1
          }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Search>
            
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton color="inherit" onClick={handleOpenCategoryDialog}>
                <FilterListIcon />
              </IconButton>
              <IconButton color="inherit" onClick={handleSortClick}>
                <SortIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        
        <Tabs value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
          <Tab label="Private" />
          <Tab label="Friends" />
          <Tab label="Public" />
        </Tabs>
      </AppBar>

      <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '5rem' }}>
        <StyledPaper elevation={3}>
          {selectedCategories.length > 0 && (
            <Box mt={2} mb={2}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={categories.find(c => c.value === category)?.label}
                  onDelete={() => handleCategoryToggle(category)}
                  color="primary"
                  style={{ margin: '0 4px 4px 0' }}
                />
              ))}
            </Box>
          )}

          {selectedHashtags.length > 0 && (
            <Box mt={2} mb={2}>
              {selectedHashtags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleHashtagClick(tag)}
                  style={{ margin: '0 4px 4px 0' }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        <LoadingError loading={loading} error={error}>
          {Object.keys(groupedEvents).length === 0 ? (
            <Box textAlign="center" my={4}>
              <Typography variant="h6" gutterBottom>
                No events found
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                {tabValue === 0 ? "You don't have any private events." :
                tabValue === 1 ? "Your friends haven't created any events." :
                "There are no public events matching your criteria."}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate('/create-event')}
              >
                Create an Event
              </Button>
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="subtitle1">
                  Showing {paginatedEvents.length} of {displayedEvents.length}
                  <Select
                    value={sortBy}
                    onChange={(e) => handleSortChange(e.target.value)}
                    sx={{
                      ml: 1,
                      minWidth: 120,
                      height: '24px',
                      '& .MuiSelect-select': {
                        py: 0,
                      },
                    }}
                  >
                    <MenuItem value="dateDesc">Starting Latest</MenuItem>
                    <MenuItem value="dateAsc">Starting Soonest</MenuItem>
                    <MenuItem value="distanceAsc">Nearest</MenuItem>
                    <MenuItem value="distanceDesc">Farthest</MenuItem>
                    <MenuItem value="popularityDesc">Most Popular</MenuItem>
                    <MenuItem value="popularityAsc">Least Popular</MenuItem>
                  </Select>
                </Typography>
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={handleViewModeChange}
                  aria-label="view mode"
                >
                  <ToggleButton value="grid" aria-label="grid view">
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton value="list" aria-label="list view">
                    <ViewListIcon />
                  </ToggleButton>
                  <ToggleButton value="map" aria-label="map view">
                    <PublicIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {viewMode === 'grid' && <EventCardsByDay />}
              {viewMode === 'list' && <EventListView />}

              {viewMode === 'map' && userLocation && (
                <MapWrapper>
                  <EventMap 
                    events={paginatedEvents}
                    userLocation={userLocation}
                    onViewDetails={handleViewDetails}
                    onReport={handleReportEvent}
                    onBlockCreator={handleBlock}
                    onViewCreatorProfile={handleViewCreatorProfile}
                  />
                </MapWrapper>
              )}

              <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <Pagination 
                  count={totalPages} 
                  page={currentPage} 
                  onChange={handlePageChange} 
                  color="primary" 
                />
              </Box>

              <Box ref={ref} height="20px" />
            </>
          )}
        </LoadingError>

        <SpeedDial
          ariaLabel="Navigation SpeedDial"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.handleClick}
            />
          ))}
        </SpeedDial>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortClose}
        >
          <MenuItem onClick={() => handleSortChange('dateDesc')}>Starting Latest</MenuItem>
          <MenuItem onClick={() => handleSortChange('dateAsc')}>Starting Soonest</MenuItem>
          <MenuItem onClick={() => handleSortChange('distanceAsc')}>Nearest</MenuItem>
          <MenuItem onClick={() => handleSortChange('distanceDesc')}>Farthest</MenuItem>
          <MenuItem onClick={() => handleSortChange('popularityDesc')}>Most Popular</MenuItem>
          <MenuItem onClick={() => handleSortChange('popularityAsc')}>Least Popular</MenuItem>
        </Menu>

        <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
          <DialogTitle>Manage Preferred Categories</DialogTitle>
          <DialogContent>
            <List>
              {categories.map((category) => (
                <ListItem key={category.value} dense button onClick={() => handleCategoryToggle(category.value)}>
                  <ListItemIcon>
                    <category.icon />
                  </ListItemIcon>
                  <ListItemText primary={category.label} />
                  <Checkbox
                    edge="end"
                    checked={selectedCategories.includes(category.value)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <WelcomeCategoriesDialog
          open={openWelcomeDialog}
          onClose={() => {
            setOpenWelcomeDialog(false);
            localStorage.setItem('hideWelcomeDialog', 'true');
          }}
          categories={categories}
          selectedCategories={selectedCategories}
          onCategoriesChange={handleWelcomeCategoriesChange}
          todayEventCounts={categoryEventCounts}
        />
      </Container>
    </>
  );
}

export default Home;