import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';


const MAX_HASHTAG_LENGTH = 20;

const HashtagInput = ({ onChange }) => {
  const [hashtags, setHashtags] = useState([]);
  const [availableHashtags, setAvailableHashtags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchHashtags = async () => {
      try {
        const hashtagsCollection = collection(db, 'hashtags');
        const snapshot = await getDocs(hashtagsCollection);
        const fetchedHashtags = snapshot.docs.map(doc => doc.data().tag);
        setAvailableHashtags(fetchedHashtags);
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }
    };

    fetchHashtags();
  }, []);

  const sanitizeHashtag = (tag) => {
    // Enlever le # initial s'il existe
    tag = tag.startsWith('#') ? tag.slice(1) : tag;
    // Nettoyer le hashtag pour ne garder que les lettres et les chiffres
    tag = tag.replace(/[^a-zA-Z0-9]/g, '');
    // Limiter à 20 caractères (19 + #)
    tag = tag.slice(0, MAX_HASHTAG_LENGTH - 1);
    // Ajouter le # au début
    return tag ? `#${tag}` : '';
  };

  const addHashtag = (newTag) => {
    const sanitizedTag = sanitizeHashtag(newTag);
    if (sanitizedTag && !hashtags.includes(sanitizedTag) && hashtags.length < 3) {
      const newHashtags = [...hashtags, sanitizedTag];
      setHashtags(newHashtags);
      onChange(newHashtags);
      return true;
    }
    return false;
  };

  const handleChange = (event, newValue) => {
    const sanitizedHashtags = newValue
      .map(sanitizeHashtag)
      .filter(tag => tag.length > 1)
      .slice(0, 3);
    setHashtags(sanitizedHashtags);
    onChange(sanitizedHashtags);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      if (addHashtag(inputValue)) {
        setInputValue('');
      }
    }
  };

  return (
    <Autocomplete
      multiple
      id="hashtags"
      options={availableHashtags}
      value={hashtags}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            icon={<TagIcon />}
            label={option.replace('#', '')}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Add Hashtags (max 3)"
          placeholder={hashtags.length >= 3 ? "" : "Type to add hashtag"}
          onKeyDown={handleKeyDown}
          inputProps={{
            ...params.inputProps,
            pattern: "[a-zA-Z0-9#]*",
            maxLength: MAX_HASHTAG_LENGTH,
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>{option.replace('#', '')}</li>
      )}
      freeSolo
      limitTags={3}
    />
  );
};

export default HashtagInput;