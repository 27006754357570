import React, { useState, useCallback, useMemo } from 'react';
import { 
  Box, Typography, IconButton, Chip, Avatar, Badge,
  styled, useTheme, alpha, Tooltip, Collapse, Button,
  Divider
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EventOptionsMenu from './EventOptionsMenu';
import ReportDialog from './ReportDialog';
import { fr, enUS } from 'date-fns/locale';
import { parseISO, format, isValid, isSameDay, isToday, isTomorrow } from 'date-fns';
import { categories } from '../constants/categories';

const TRANSITION_DURATION = 0.2;

const StyledListItem = styled(motion.div)(({ theme, isCertified, certificationColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(1.5),
  width: '100%',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  border: isCertified ? `1px solid ${alpha(certificationColor, 0.5)}` : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[3],
  },
  ...(isCertified && {
    boxShadow: `0 0 5px ${alpha(certificationColor, 0.5)}`,
  }),
}));

const HeaderContainer = styled(Box)(({ theme, headerColor }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  padding: theme.spacing(1.5, 2),
  background: headerColor ? `linear-gradient(to right, ${alpha(headerColor, 0.1)}, transparent)` : 'transparent',
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexGrow: 1,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '80px',
  flexShrink: 0,
  marginRight: theme.spacing(2),
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '70px',
    height: '70px',
    marginRight: theme.spacing(1.5),
  },
}));

const GradientFallback = styled(Box)(({ theme, categoryColor }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(135deg, 
    ${alpha(categoryColor || theme.palette.primary.main, 0.8)} 0%, 
    ${alpha(categoryColor || theme.palette.primary.dark, 0.6)} 100%)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at 30% 30%, 
      ${alpha(theme.palette.background.paper, 0.1)} 0%,
      transparent 60%)`,
  },
}));

const EventImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const CategoryIconWrapper = styled(Box)(({ theme, categoryColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(categoryColor || theme.palette.primary.main, 0.1),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  height: 32,
  width: 32,
  flexShrink: 0,
  color: categoryColor || theme.palette.primary.main,
  '& svg': {
    fontSize: '1.2rem',
  }
}));

const CategoryIconPlaceholder = styled(Box)(({ theme }) => ({
  opacity: 0.7,
  color: theme.palette.common.white,
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  lineHeight: 1.3,
  fontWeight: 600,
  marginRight: theme.spacing(0.5),
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.85rem',
  },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  marginBottom: theme.spacing(0.5),
  '& svg': {
    fontSize: '0.9rem',
    marginRight: theme.spacing(0.5),
    opacity: 0.7,
  },
}));

const OptionsMenuWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexShrink: 0,
});

const HashtagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: '0.65rem',
  height: 20,
  maxWidth: '100px',
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
  },
}));

const EventDateChip = styled(Chip)(({ theme, isUpcoming, isPast }) => ({
  height: 20,
  fontSize: '0.65rem',
  fontWeight: 600,
  marginLeft: theme.spacing(0.5),
  backgroundColor: isUpcoming
    ? alpha(theme.palette.secondary.main, 0.1)
    : isPast
      ? alpha(theme.palette.error.main, 0.1)
      : alpha(theme.palette.primary.main, 0.1),
  color: isUpcoming
    ? theme.palette.secondary.main
    : isPast
      ? theme.palette.error.main
      : theme.palette.primary.main,
  '& .MuiChip-icon': {
    fontSize: '0.7rem',
  },
}));

const CertificationBadge = styled(Box)(({ theme, color }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: color,
  marginLeft: theme.spacing(0.5),
  '& svg': {
    fontSize: '0.85rem',
  }
}));

const ExpandButton = styled(IconButton)(({ theme, expanded }) => ({
  padding: 4,
  position: 'absolute',
  bottom: 0,
  right: 0,
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  [theme.breakpoints.down('sm')]: {
    bottom: -3,
    right: -3,
  }
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '& svg': {
    color: theme.palette.primary.main,
    opacity: 0.8,
  }
}));

const ExpandedSection = styled(Collapse)(({ theme }) => ({
  padding: theme.spacing(0, 2, 2, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  borderRadius: 20,
  padding: theme.spacing(0.5, 1.5),
  fontSize: '0.75rem',
  '&:first-of-type': {
    marginRight: theme.spacing(1),
  }
}));

const TimeLabel = styled(Typography)(({ theme, isHighlighted }) => ({
  fontSize: '0.75rem',
  lineHeight: 1.2,
  fontWeight: isHighlighted ? 600 : 400,
  color: isHighlighted ? theme.palette.primary.main : theme.palette.text.secondary,
}));

const truncate = (str, maxLength) => {
  if (!str) return '';
  if (str.length <= maxLength) return str;
  const minLength = Math.min(maxLength, str.length);
  const truncated = str.substr(0, minLength - 1);
  return `${truncated}…`;
};

const EventListItem = React.memo(({ 
  event, 
  onViewDetails, 
  onJoin, 
  onHashtagClick, 
  certificationStyle, 
  onBlockCreator, 
  onViewCreatorProfile,
  userLocale = 'fr',
  showExpandButton = true
}) => {
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  
  const isCertified = event.creatorIsCertified && certificationStyle;
  const certificationColor = isCertified ? certificationStyle.borderColor : 'transparent';

  const getCategoryColor = useCallback((category) => {
    const categoryData = categories.find(c => c.value === category);
    return categoryData?.color || theme.palette.primary.main;
  }, [theme.palette.primary.main]);

  const formatEventDate = useMemo(() => (startDate, endDate) => {
    if (!startDate) return { text: 'Date unavailable', isToday: false, isTomorrow: false, isPast: false };
  
    const start = typeof startDate === 'object' && startDate._seconds
      ? new Date(startDate._seconds * 1000)
      : parseISO(startDate);
  
    if (!isValid(start)) return { text: 'Invalid date', isToday: false, isTomorrow: false, isPast: false };
  
    const end = endDate ? (typeof endDate === 'object' && endDate._seconds
      ? new Date(endDate._seconds * 1000)
      : parseISO(endDate)) : null;
  
    const locale = userLocale === 'fr' ? fr : enUS;
    const formatTime = (date) => format(date, 'h:mm a', { locale });
  
    const isPastEvent = start < new Date();
    const isTodayEvent = isToday(start);
    const isTomorrowEvent = isTomorrow(start);
    
    let dateText = '';
    
    if (isTodayEvent) {
      dateText = formatTime(start);
      if (end && !isSameDay(start, end)) {
        dateText += ` - ${format(end, 'EEE, MMM d', { locale })} ${formatTime(end)}`;
      } else if (end) {
        dateText += ` - ${formatTime(end)}`;
      }
    } else {
      dateText = `${format(start, 'EEE, MMM d', { locale })} ${formatTime(start)}`;
      if (end && !isSameDay(start, end)) {
        dateText += ` - ${format(end, 'EEE, MMM d', { locale })} ${formatTime(end)}`;
      } else if (end) {
        dateText += ` - ${formatTime(end)}`;
      }
    }
  
    return { 
      text: dateText, 
      isToday: isTodayEvent, 
      isTomorrow: isTomorrowEvent, 
      isPast: isPastEvent 
    };
  }, [userLocale]);

  const eventDateTime = useMemo(() => formatEventDate(event.startDate, event.endDate), [event.startDate, event.endDate, formatEventDate]);

  const handleClick = useCallback(() => {
    if (!openReportDialog) {
      onViewDetails(event);
    }
  }, [event, onViewDetails, openReportDialog]);

  const handleOptionsClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  const handleReport = useCallback(() => setOpenReportDialog(true), []);
  const handleCloseReport = useCallback(() => setOpenReportDialog(false), []);

  const handleHashtagClick = useCallback((tag) => (e) => {
    e.stopPropagation();
    onHashtagClick(tag);
  }, [onHashtagClick]);
  
  const handleExpandClick = useCallback((e) => {
    e.stopPropagation();
    setExpanded(prev => !prev);
  }, []);

  const getCategoryIcon = useCallback((category) => {
    const categoryData = categories.find(c => c.value === category);
    return categoryData?.icon ? React.createElement(categoryData.icon, { fontSize: "small" }) : null;
  }, []);

  const renderCertificationBadge = useCallback(() => {
    if (!isCertified) return null;
    
    return (
      <CertificationBadge color={certificationColor}>
        <Tooltip title={`Certified ${event.creatorCertificationType === 'personal' ? 'User' : 'Organization'}`}>
          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            {certificationStyle.icon === 'verified' 
              ? <VerifiedIcon fontSize="small" /> 
              : <VerifiedUserIcon fontSize="small" />
            }
          </Box>
        </Tooltip>
      </CertificationBadge>
    );
  }, [certificationColor, certificationStyle, event.creatorCertificationType, isCertified]);

  const categoryColor = getCategoryColor(event.eventCategory);
  const CategoryIconComponent = categories.find(c => c.value === event.eventCategory)?.icon;

  const dateChipLabel = eventDateTime.isToday 
    ? 'Today' 
    : eventDateTime.isTomorrow 
      ? 'Tomorrow' 
      : eventDateTime.isPast 
        ? 'Past' 
        : 'Upcoming';

  const dateChipIcon = eventDateTime.isToday || eventDateTime.isTomorrow
    ? <EventIcon fontSize="small" />
    : null;

  return (
    <>
      <StyledListItem
        onClick={handleClick}
        isCertified={isCertified}
        certificationColor={certificationColor}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: TRANSITION_DURATION }}
        whileHover={{ scale: 1.01 }}
      >
        <HeaderContainer headerColor={categoryColor}>
          <TitleWrapper>
            <CategoryIconWrapper categoryColor={categoryColor}>
              {getCategoryIcon(event.eventCategory)}
            </CategoryIconWrapper>
            
            <Box sx={{ overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <EventTitle>
                  {event.title}
                </EventTitle>
                {renderCertificationBadge()}
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.3 }}>
                <TimeLabel isHighlighted={eventDateTime.isToday || eventDateTime.isTomorrow}>
                  {eventDateTime.text}
                </TimeLabel>
                
                <EventDateChip 
                  size="small" 
                  label={dateChipLabel}
                  icon={dateChipIcon}
                  isUpcoming={eventDateTime.isToday || eventDateTime.isTomorrow}
                  isPast={eventDateTime.isPast}
                />
              </Box>
            </Box>
          </TitleWrapper>
          
          <OptionsMenuWrapper onClick={handleOptionsClick}>
            <EventOptionsMenu
              onReport={handleReport}
              onBlock={() => onBlockCreator(event.createdBy)}
              onViewProfile={() => onViewCreatorProfile(event.createdBy)}
            />
          </OptionsMenuWrapper>
        </HeaderContainer>
        
        <ContentContainer>
          <ImageContainer>
            {!imageError && event.imageUrl ? (
              <EventImage 
                src={event.imageUrl} 
                alt={event.title}
                onError={handleImageError}
              />
            ) : (
              <GradientFallback categoryColor={categoryColor}>
                <CategoryIconPlaceholder>
                  {CategoryIconComponent && <CategoryIconComponent fontSize="large" />}
                </CategoryIconPlaceholder>
              </GradientFallback>
            )}
          </ImageContainer>
          
          <InfoContainer>
            <InfoItem>
              <LocationOnIcon />
              <Typography variant="caption" noWrap>
                {event.venueName || truncate(event.address, 40)} 
                {event.distance ? ` (${event.distance.toFixed(1)} km)` : ''}
              </Typography>
            </InfoItem>
            
            {event.price && (
              <InfoItem>
                <AttachMoneyIcon />
                <Typography variant="caption">
                  {event.price}
                </Typography>
              </InfoItem>
            )}

            <InfoItem>
              <PersonIcon />
              <Typography variant="caption">
                {event.username || 'Anonymous'}
              </Typography>
            </InfoItem>
            
            {event.isAutomated && (
              <InfoItem>
                <Tooltip title="This event was added automatically" arrow>
                  <SmartToyIcon fontSize="small" />
                </Tooltip>
              </InfoItem>
            )}
            
            <HashtagContainer>
              {event.hashtags?.slice(0, 3).map((tag, index) => (
                <StyledChip
                  key={`${tag}-${index}`}
                  label={truncate(tag, 15)}
                  size="small"
                  onClick={handleHashtagClick(tag)}
                />
              ))}
            </HashtagContainer>
          </InfoContainer>
          
          {showExpandButton && (
            <ExpandButton
              size="small"
              onClick={handleExpandClick}
              expanded={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon fontSize="small" />
            </ExpandButton>
          )}
        </ContentContainer>
        
        <AnimatePresence>
          {expanded && (
            <ExpandedSection
              in={expanded}
              timeout="auto"
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Divider sx={{ mb: 2 }} />
              
              <DetailItem>
                <AccessTimeIcon fontSize="small" />
                <Typography variant="body2">
                  {format(parseISO(event.startDate), 'EEEE, MMMM d, yyyy')} at {format(parseISO(event.startDate), 'h:mm a')}
                </Typography>
              </DetailItem>
              
              {event.endDate && (
                <DetailItem>
                  <AccessTimeIcon fontSize="small" />
                  <Typography variant="body2">
                    Ends: {format(parseISO(event.endDate), 'EEEE, MMMM d, yyyy')} at {format(parseISO(event.endDate), 'h:mm a')}
                  </Typography>
                </DetailItem>
              )}
              
              <DetailItem>
                <LocationOnIcon fontSize="small" />
                <Typography variant="body2" style={{ wordBreak: 'break-word' }}>
                  {event.address}
                </Typography>
              </DetailItem>
              
              {event.description && (
                <Box mt={1} mb={2}>
                  <Typography variant="body2" color="textSecondary" sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {truncate(event.description.replace(/<[^>]*>?/gm, ''), 150)}
                  </Typography>
                </Box>
              )}
              
              {event.participants && (
                <DetailItem>
                  <PeopleAltIcon fontSize="small" />
                  <Typography variant="body2">
                    {event.participants.length} Participants
                  </Typography>
                </DetailItem>
              )}
              
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <ActionButton
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    onJoin?.(event.id);
                  }}
                >
                  Join Event
                </ActionButton>
                <ActionButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewDetails(event);
                  }}
                >
                  View Details
                </ActionButton>
              </Box>
            </ExpandedSection>
          )}
        </AnimatePresence>
      </StyledListItem>
      
      <ReportDialog
        open={openReportDialog}
        onClose={handleCloseReport}
        eventId={event.id}
        latitude={event.coordinates?.latitude}
        longitude={event.coordinates?.longitude}
      />
    </>
  );
});

EventListItem.displayName = 'EventListItem';

export default EventListItem;