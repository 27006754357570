// src/constants/categories.js
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StorefrontIcon from '@mui/icons-material/Storefront';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const categories = [
  { value: 'culture', label: 'Culture and Entertainment', icon: TheaterComedyIcon },
  { value: 'sports', label: 'Sports and Physical Activities', icon: SportsSoccerIcon },
  { value: 'family', label: 'Family and Children', icon: FamilyRestroomIcon },
  { value: 'nightlife', label: 'Nightlife and Outings', icon: NightlifeIcon },
  { value: 'professional', label: 'Professional Events and Networking', icon: BusinessCenterIcon },
  { value: 'markets', label: 'Markets and Fairs', icon: StorefrontIcon },
  { value: 'social', label: 'Social and Community Events', icon: GroupsIcon },
  { value: 'education', label: 'Education and Personal Development', icon: SchoolIcon },
  { value: 'tech', label: 'Tech and Innovation', icon: ComputerIcon },
  { value: 'wellness', label: 'Wellness and Health', icon: FitnessCenterIcon },
  { value: 'other', label: 'Other', icon: MoreHorizIcon }
];