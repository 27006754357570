import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  browserSessionPersistence,
  setPersistence
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { checkAndAwardBadges } from '../utils/badgeUtils';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [authError, setAuthError] = useState(null);

  async function generateUniqueUsername(baseUsername) {
    let username = baseUsername;
    let counter = 1;
    let isUnique = false;

    while (!isUnique) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        isUnique = true;
      } else {
        username = `${baseUsername}${counter}`;
        counter++;
      }
    }

    return username;
  }

  async function createOrUpdateUserProfile(user) {
    try {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
    
      if (!userSnap.exists()) {
        // Générer un username unique
        const baseUsername = `user${Math.floor(Math.random() * 1000000)}`;
        const uniqueUsername = await generateUniqueUsername(baseUsername);
        
        // Création d'un nouveau profil utilisateur
        const userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || user.email.split('@')[0],
          photoURL: user.photoURL || '',
          createdAt: new Date().toISOString(),
          username: uniqueUsername,
          isCertified: false,
          certificationType: 'none',
          certifiedUntil: null,
          badges: [],
          lastLoginAt: new Date().toISOString()
        };

        await setDoc(userRef, userData);
        setUserProfile(userData);
        console.log("Nouveau profil utilisateur créé");
      } else {
        // Mise à jour d'un profil existant
        const userData = userSnap.data();
        const updateData = {
          email: user.email,
          displayName: user.displayName || userData.displayName,
          photoURL: user.photoURL || userData.photoURL,
          lastLoginAt: new Date().toISOString(),
        };
    
        // Assurez-vous que les nouveaux champs existent
        if (!userData.hasOwnProperty('username')) {
          updateData.username = await generateUniqueUsername(`user${Math.floor(Math.random() * 1000000)}`);
        }
        if (!userData.hasOwnProperty('isCertified')) {
          updateData.isCertified = false;
        }
        if (!userData.hasOwnProperty('certificationType')) {
          updateData.certificationType = 'none';
        }
        if (!userData.hasOwnProperty('certifiedUntil')) {
          updateData.certifiedUntil = null;
        }
        if (!userData.hasOwnProperty('badges')) {
          updateData.badges = [];
        }
    
        await updateDoc(userRef, updateData);
        setUserProfile({...userData, ...updateData});
        console.log("Profil utilisateur mis à jour");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      setAuthError(error.message);
    }
  }

  async function signup(email, password) {
    try {
      setAuthError(null);
      // Utiliser la persistance de session pour garder l'utilisateur connecté
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await createOrUpdateUserProfile(userCredential.user);
      await checkAndAwardBadges(userCredential.user.uid);
      return userCredential;
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      setAuthError(error.message);
      throw error;
    }
  }
  
  async function login(email, password) {
    try {
      setAuthError(null);
      // Utiliser la persistance de session pour garder l'utilisateur connecté
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await createOrUpdateUserProfile(userCredential.user);
      return userCredential;
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      setAuthError(error.message);
      throw error;
    }
  }

  async function logout() {
    try {
      setAuthError(null);
      return await signOut(auth);
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      setAuthError(error.message);
      throw error;
    }
  }

  async function loginWithGoogle() {
    try {
      setAuthError(null);
      const provider = new GoogleAuthProvider();
      // Utiliser signInWithRedirect pour une meilleure expérience mobile
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // Pour les appareils mobiles
        return signInWithRedirect(auth, provider);
      } else {
        // Pour les ordinateurs de bureau
        const userCredential = await signInWithPopup(auth, provider);
        await createOrUpdateUserProfile(userCredential.user);
        await checkAndAwardBadges(userCredential.user.uid);
        return userCredential;
      }
    } catch (error) {
      console.error("Erreur lors de la connexion avec Google:", error);
      setAuthError(error.message);
      throw error;
    }
  }

  async function getIdToken(forceRefresh = false) {
    if (currentUser) {
      try {
        return await currentUser.getIdToken(forceRefresh);
      } catch (error) {
        console.error("Erreur lors de l'obtention du token ID:", error);
        setAuthError(error.message);
        throw error;
      }
    }
    return null;
  }

  async function fetchUserProfile() {
    if (currentUser) {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
        }
      } catch (error) {
        console.error("Erreur lors du chargement du profil:", error);
        setAuthError(error.message);
      }
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await createOrUpdateUserProfile(user);
        } catch (error) {
          console.error("Erreur lors de la mise à jour du profil:", error);
        }
      } else {
        setUserProfile(null);
      }
      
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userProfile,
    authError,
    loading,
    signup,
    login,
    logout,
    loginWithGoogle,
    getIdToken,
    fetchUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}