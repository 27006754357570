// hooks/useCachedDistances.js

import { useState, useEffect, useCallback } from 'react';
import { calculateDistance } from '../utils/distanceCalculation';

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes

export const useCachedDistances = (events, userLocation) => {
  const [cachedDistances, setCachedDistances] = useState({});

  const calculateDistances = useCallback(() => {
    const now = Date.now();
    const newDistances = {};

    events.forEach(event => {
      const cacheKey = `${event.id}-${userLocation.latitude}-${userLocation.longitude}`;
      const cachedItem = cachedDistances[cacheKey];

      if (cachedItem && now - cachedItem.timestamp < CACHE_EXPIRATION) {
        newDistances[cacheKey] = cachedItem;
      } else {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          event.coordinates.latitude,
          event.coordinates.longitude
        );
        newDistances[cacheKey] = { distance, timestamp: now };
      }
    });

    setCachedDistances(newDistances);
  }, [events, userLocation, cachedDistances]);

  useEffect(() => {
    if (events.length > 0 && userLocation) {
      calculateDistances();
    }
  }, [events, userLocation, calculateDistances]);

  return cachedDistances;
};