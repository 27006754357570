import React from 'react';

export const EnFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <rect width="32" height="32" fill="#012169"/>
    <path d="M32 0v4L20 16l12 12v4H28L16 20 4 32H0v-4l12-12L0 4V0h4l12 12L28 0z" fill="#FFF"/>
    <path d="M12 0v32h8V0zM0 12h32v8H0z" fill="#C8102E"/>
    <path d="M0 0l32 32m0-32L0 32" stroke="#FFF" strokeWidth="4"/>
  </svg>
);

export const FrFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path fill="#002395" d="M0 0h10.667v32H0z"/>
    <path fill="#fff" d="M10.667 0h10.667v32H10.667z"/>
    <path fill="#ED2939" d="M21.333 0H32v32H21.333z"/>
  </svg>
);

export const DeFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path fill="#000" d="M0 0h32v10.667H0z"/>
    <path fill="#DD0000" d="M0 10.667h32v10.667H0z"/>
    <path fill="#FFCE00" d="M0 21.333h32V32H0z"/>
  </svg>
);

export const ItFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <rect width="10.667" height="32" fill="#009246"/>
    <rect x="10.667" width="10.667" height="32" fill="#fff"/>
    <rect x="21.333" width="10.667" height="32" fill="#ce2b37"/>
  </svg>
);