import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, Typography, Button, Box, Grid, Chip, IconButton,
  Dialog, DialogContent, Snackbar, Alert, CircularProgress, 
  Avatar, Paper, Fade, Zoom, useMediaQuery, useTheme, Card, CardContent, Divider,
  Tooltip, Skeleton, Menu, MenuItem, ListItemIcon, ListItemText, Popper, Grow, 
  ClickAwayListener, MenuList, ButtonBase, alpha
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AccessTime, Room, People, Chat, Report, ExitToApp, 
  Visibility, Share as ShareIcon, ArrowBack, Person, Close,
  Category, EventAvailable, Info, Directions, Facebook, Twitter,
  LinkedIn, WhatsApp, Email, Link, ContentCopy, Telegram, Instagram,
  CalendarMonth, Map, Star, StarBorder, HelpOutline
} from '@mui/icons-material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { parseISO, format, isValid, differenceInDays, isPast, addToDate } from 'date-fns';
import L from 'leaflet';
import ReportDialog from '../components/ReportDialog';
import { api } from '../utils/api';
import SafeDescriptionComponent from '../components/SafeDescriptionComponent';

// Leaflet icon setup
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: 0,
  background: 'rgba(25, 25, 25, 0.85)',
  backdropFilter: 'blur(15px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  overflow: 'hidden',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    borderRadius: 0,
    minHeight: '100vh',
  }
}));

const EventImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '350px',
  [theme.breakpoints.down('sm')]: {
    height: '250px',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '12px 12px 0 0',
  backgroundColor: theme.palette.grey[900],
  cursor: 'pointer',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100px',
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
    pointerEvents: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
  }
}));

const EventImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const InfoChip = styled(Chip)(({ theme, color }) => ({
  margin: theme.spacing(0.5),
  borderRadius: '16px',
  fontWeight: 500,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.2s ease',
  backgroundColor: color ? alpha(theme.palette[color].main, 0.15) : alpha(theme.palette.primary.main, 0.15),
  color: color ? theme.palette[color].main : theme.palette.primary.main,
  border: `1px solid ${color ? alpha(theme.palette[color].main, 0.3) : alpha(theme.palette.primary.main, 0.3)}`,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
    backgroundColor: color ? alpha(theme.palette[color].main, 0.25) : alpha(theme.palette.primary.main, 0.25),
  },
  '& .MuiChip-icon': {
    color: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.25),
    height: '28px',
    '& .MuiChip-label': {
      fontSize: '0.75rem',
      padding: '0 8px',
    },
    '& .MuiChip-icon': {
      fontSize: '1rem',
      marginLeft: '4px',
    },
  }
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: '300px',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  border: '1px solid rgba(255,255,255,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: '220px',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1.5),
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '24px',
  padding: '8px 24px',
  textTransform: 'none',
  fontWeight: 600,
  letterSpacing: '0.3px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.5),
    fontSize: '0.8rem',
    padding: '6px 16px',
    '& .MuiButton-startIcon': {
      marginRight: '6px',
    },
    '& .MuiButton-startIcon > *:first-of-type': {
      fontSize: '1.1rem',
    },
  }
}));

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgb(0, 0, 0)',
    }
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transform: 'scale(1.1)',
  },
  transition: 'all 0.2s ease',
  [theme.breakpoints.down('sm')]: {
    right: theme.spacing(1),
    top: theme.spacing(1),
  }
}));

const InfoCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(45, 45, 45, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 24px rgba(0, 0, 0, 0.25)',
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1.5),
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5),
    }
  }
}));

const HashtagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  }
}));

const MobileActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.75),
    marginTop: theme.spacing(1.5),
  }
}));

const EventStatus = styled(Chip)(({ theme, status }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 5,
  fontWeight: 'bold',
  boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
  ...(status === 'upcoming' && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  }),
  ...(status === 'today' && {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  }),
  ...(status === 'past' && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }),
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    fontSize: '0.7rem',
  }
}));

const ParticipantAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    zIndex: 2,
  },
  [theme.breakpoints.down('sm')]: {
    width: 24,
    height: 24,
  }
}));

const ParticipantAvatarGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  '& > *:not(:first-child)': {
    marginLeft: -8,
  }
}));

const BackdropContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  opacity: 0.06,
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(30px) brightness(0.6)',
  }
}));

const ShareMenu = styled(MenuList)(({ theme }) => ({
  padding: theme.spacing(1),
  width: 220,
  maxWidth: '100%',
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.1)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
}));

const ShareMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: '2px 0',
  padding: theme.spacing(1, 2),
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transform: 'translateX(3px)',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '36px',
    color: 'inherit',
  },
}));

const FavoriteButton = styled(IconButton)(({ theme, isFavorite }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(7),
  zIndex: 5,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: isFavorite ? theme.palette.warning.light : 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transform: 'scale(1.1)',
  },
  transition: 'all 0.2s ease',
  boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(1.5),
    right: theme.spacing(6),
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.breakpoints.down('sm') ? '1.1rem' : '1.4rem',
  fontWeight: 600,
  letterSpacing: '-0.3px',
  position: 'relative',
  display: 'inline-block',
  marginBottom: theme.spacing(1.5),
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: '40px',
    height: '3px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2px',
  }
}));

const DescriptionCard = styled(Box)(({ theme }) => ({
  fontSize: theme.breakpoints.down('sm') ? '0.9rem' : '1rem',
  lineHeight: 1.6,
  letterSpacing: '0.2px',
  color: 'rgba(255, 255, 255, 0.87)',
  background: 'rgba(40, 40, 40, 0.4)',
  padding: theme.breakpoints.down('sm') ? theme.spacing(1.5) : theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: 'inset 0 0 15px rgba(0, 0, 0, 0.15)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  }
}));

function EventDetails() {
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [event, setEvent] = useState(location.state?.eventData || null);
  const [isParticipant, setIsParticipant] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [userLocation, setUserLocation] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [loading, setLoading] = useState(!event);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);

  // Share menu state
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const shareButtonRef = useRef(null);

  const getUserLocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            setUserLocation(location);
            resolve(location);
          },
          (error) => {
            console.error("Error getting user location:", error);
            setSnackbar({ 
              open: true, 
              message: 'Unable to get your location. Some features might be limited.', 
              severity: 'warning' 
            });
            reject(error);
          }
        );
      } else {
        setSnackbar({ 
          open: true, 
          message: 'Geolocation is not supported by your browser.', 
          severity: 'warning' 
        });
        reject(new Error('Geolocation not supported'));
      }
    });
  }, []);

  const fetchEventDetails = useCallback(async (userLocation) => {
    if (event) {
      setIsParticipant(event.participants?.includes(currentUser.uid) || false);
      return;
    }

    try {
      setLoading(true);
      const token = await getIdToken();
      const response = await api.get(`/events/${eventId}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: userLocation
      });
      setEvent(response.data);
      setIsParticipant(response.data.participants?.includes(currentUser.uid) || false);
      
      // Check if event is in favorites (example implementation)
      try {
        const favoritesResponse = await api.get('/user/favorites', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setIsFavorite(favoritesResponse.data.some(fav => fav.id === eventId));
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    } catch (err) {
      console.error("Error fetching event details:", err);
      setError("Failed to fetch event details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [eventId, getIdToken, currentUser, event]);

  useEffect(() => {
    if (!event) {
      getUserLocation().then(fetchEventDetails);
    } else {
      setIsParticipant(event.participants?.includes(currentUser.uid) || false);
    }
  }, [getUserLocation, fetchEventDetails, event, currentUser]);

  const handleJoinLeaveEvent = useCallback(async () => {
    try {
      const token = await getIdToken();
      const action = isParticipant ? 'leave' : 'join';
      const response = await api.post(`/events/${eventId}/${action}`, 
        { latitude: event.coordinates.latitude, longitude: event.coordinates.longitude },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.success) {
        setIsParticipant(!isParticipant);
        setSnackbar({ 
          open: true, 
          message: response.data.message, 
          severity: 'success' 
        });
        
        setEvent(prevEvent => ({
          ...prevEvent,
          participants: action === 'join' 
            ? [...(prevEvent.participants || []), currentUser.uid]
            : (prevEvent.participants || []).filter(id => id !== currentUser.uid),
          participantCount: response.data.participantCount
        }));
      } else {
        setSnackbar({ 
          open: true, 
          message: response.data.message, 
          severity: 'warning' 
        });
      }
    } catch (error) {
      console.error(`Error ${isParticipant ? 'leaving' : 'joining'} event:`, error);
      setSnackbar({ 
        open: true, 
        message: `Error ${isParticipant ? 'leaving' : 'joining'} event. Please try again.`, 
        severity: 'error' 
      });
    }
  }, [eventId, isParticipant, getIdToken, event, currentUser.uid]);

  const toggleFavorite = useCallback(async () => {
    try {
      const token = await getIdToken();
      const action = isFavorite ? 'remove' : 'add';
      const response = await api.post(`/user/favorites/${action}`, 
        { eventId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.success) {
        setIsFavorite(!isFavorite);
        setSnackbar({ 
          open: true, 
          message: isFavorite ? 'Removed from favorites' : 'Added to favorites', 
          severity: 'success' 
        });
      } else {
        setSnackbar({ 
          open: true, 
          message: response.data.message, 
          severity: 'warning' 
        });
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
      setSnackbar({ 
        open: true, 
        message: `Couldn't update favorites. Please try again.`, 
        severity: 'error' 
      });
    }
  }, [eventId, isFavorite, getIdToken]);

  const openInGoogleMaps = useCallback(() => {
    if (event && event.coordinates) {
      const { latitude, longitude } = event.coordinates;
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }, [event]);

  const formatEventDate = useMemo(() => (dateString) => {
    if (!dateString) return 'Date not available';
    const date = typeof dateString === 'object' && dateString._seconds
      ? new Date(dateString._seconds * 1000)
      : parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
  }, []);

  const getEventStatus = useMemo(() => () => {
    if (!event || !event.startDate) return null;
    const date = typeof event.startDate === 'object' && event.startDate._seconds
      ? new Date(event.startDate._seconds * 1000)
      : parseISO(event.startDate);
    
    if (!isValid(date)) return null;
    
    const today = new Date();
    const daysDiff = differenceInDays(date, today);
    
    if (isPast(date)) {
      return { label: 'Past Event', status: 'past' };
    } else if (daysDiff === 0) {
      return { label: 'Today!', status: 'today' };
    } else {
      return { label: 'Upcoming', status: 'upcoming' };
    }
  }, [event]);

  const eventStatus = useMemo(() => getEventStatus(), [getEventStatus]);

  // Share menu handlers
  const handleShareMenuOpen = (event) => {
    setShareMenuOpen(true);
  };

  const handleShareMenuClose = () => {
    setShareMenuOpen(false);
  };

  const buildShareUrl = () => {
    return window.location.href;
  };

  const shareViaFacebook = () => {
    const url = encodeURIComponent(buildShareUrl());
    const title = encodeURIComponent(event.title);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`, '_blank');
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Sharing to Facebook', 
      severity: 'info' 
    });
  };

  const shareViaTwitter = () => {
    const url = encodeURIComponent(buildShareUrl());
    const text = encodeURIComponent(`Check out this event: ${event.title}`);
    window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, '_blank');
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Sharing to Twitter', 
      severity: 'info' 
    });
  };

  const shareViaLinkedIn = () => {
    const url = encodeURIComponent(buildShareUrl());
    const title = encodeURIComponent(event.title);
    const summary = encodeURIComponent(event.description?.substring(0, 100) || 'Check out this event!');
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}`, '_blank');
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Sharing to LinkedIn', 
      severity: 'info' 
    });
  };

  const shareViaWhatsApp = () => {
    const url = encodeURIComponent(buildShareUrl());
    const text = encodeURIComponent(`Check out this event: ${event.title} ${buildShareUrl()}`);
    window.open(`https://wa.me/?text=${text}`, '_blank');
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Sharing to WhatsApp', 
      severity: 'info' 
    });
  };

  const shareViaTelegram = () => {
    const url = encodeURIComponent(buildShareUrl());
    const text = encodeURIComponent(`Check out this event: ${event.title}`);
    window.open(`https://t.me/share/url?url=${url}&text=${text}`, '_blank');
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Sharing to Telegram', 
      severity: 'info' 
    });
  };

  const shareViaEmail = () => {
    const subject = encodeURIComponent(`Check out this event: ${event.title}`);
    const body = encodeURIComponent(`I thought you might be interested in this event:\n\n${event.title}\n\n${event.description}\n\nDate: ${formatEventDate(event.startDate)}\n\nLocation: ${event.address}\n\nSee more details here: ${buildShareUrl()}`);
    window.open(`mailto:?subject=${subject}&body=${body}`);
    handleShareMenuClose();
    setSnackbar({ 
      open: true, 
      message: 'Opening email client', 
      severity: 'info' 
    });
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(buildShareUrl())
      .then(() => {
        handleShareMenuClose();
        setSnackbar({
          open: true,
          message: 'Link copied to clipboard!',
          severity: 'success'
        });
      })
      .catch(error => {
        console.error("Error copying to clipboard:", error);
        handleShareMenuClose();
        setSnackbar({
          open: true,
          message: "Couldn't copy link. Please copy the URL manually.",
          severity: 'error'
        });
      });
  };

  const addToCalendar = () => {
    if (!event || !event.startDate) {
      handleShareMenuClose();
      setSnackbar({
        open: true,
        message: "Couldn't add to calendar. Missing event date.",
        severity: 'error'
      });
      return;
    }

    try {
      const date = typeof event.startDate === 'object' && event.startDate._seconds
        ? new Date(event.startDate._seconds * 1000)
        : parseISO(event.startDate);
      
      if (!isValid(date)) throw new Error("Invalid date");
      
      // Assume event is 2 hours long if no end date
      const endDate = event.endDate || new Date(date.getTime() + 2 * 60 * 60 * 1000);
      
      const title = encodeURIComponent(event.title);
      const details = encodeURIComponent(event.description || '');
      const location = encodeURIComponent(event.address || '');
      
      const start = format(date, "yyyyMMdd'T'HHmmss");
      const end = format(endDate, "yyyyMMdd'T'HHmmss");
      
      const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${start}/${end}&details=${details}&location=${location}`;
      
      window.open(googleCalendarUrl, '_blank');
      handleShareMenuClose();
      setSnackbar({
        open: true,
        message: 'Opening Google Calendar',
        severity: 'success'
      });
    } catch (error) {
      console.error("Error adding to calendar:", error);
      handleShareMenuClose();
      setSnackbar({
        open: true,
        message: "Couldn't add to calendar. Please try again.",
        severity: 'error'
      });
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Box textAlign="center">
            <CircularProgress size={60} thickness={4} sx={{
              color: theme.palette.primary.main,
              animation: 'pulse 1.5s ease-in-out infinite',
              '@keyframes pulse': {
                '0%': {
                  opacity: 1,
                  transform: 'scale(1)',
                },
                '50%': {
                  opacity: 0.7,
                  transform: 'scale(1.05)',
                },
                '100%': {
                  opacity: 1,
                  transform: 'scale(1)',
                },
              },
            }} />
            <Typography variant="h6" sx={{ 
              mt: 2, 
              opacity: 0.8,
              background: 'linear-gradient(45deg, #ffffff 30%, #e0e0e0 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
            }}>
              Loading event details...
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  if (error || !event) {
    return (
      <Container maxWidth="md">
        <StyledPaper>
          <Box p={3} textAlign="center">
            <Typography variant="h5" color="error" gutterBottom>
              {error || 'Event not found'}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              We couldn't load the event details. Please try again later.
            </Typography>
            <Button 
              variant="contained"
              startIcon={<ArrowBack />} 
              onClick={() => navigate(-1)}
              size={isMobile ? "medium" : "large"}
              sx={{ 
                mt: 2,
                borderRadius: '24px',
                padding: '8px 24px',
                textTransform: 'none',
                fontWeight: 600,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.25)',
                '&:hover': {
                  boxShadow: '0 6px 16px rgba(211, 47, 47, 0.4)',
                  transform: 'translateY(-2px)',
                },
              }}
            >
              Go Back
            </Button>
          </Box>
        </StyledPaper>
      </Container>
    );
  }

  const renderEventHeader = () => (
    <Box position="relative">
      <IconButton 
        onClick={() => navigate(-1)} 
        sx={{ 
          position: 'absolute', 
          top: isMobile ? 8 : 12, 
          left: isMobile ? 8 : 12, 
          zIndex: 10,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            transform: 'scale(1.1)',
          },
          transition: 'all 0.2s ease',
          boxShadow: '0 2px 8px rgba(0,0,0,0.3)'
        }}
      >
        <ArrowBack />
      </IconButton>
      
      {eventStatus && (
        <EventStatus 
          label={eventStatus.label}
          status={eventStatus.status}
          size={isMobile ? "small" : "medium"}
        />
      )}

      <FavoriteButton
        onClick={toggleFavorite}
        isFavorite={isFavorite}
        size={isMobile ? "small" : "medium"}
      >
        {isFavorite ? <Star /> : <StarBorder />}
      </FavoriteButton>
      
      <EventImageContainer onClick={() => setOpenImageDialog(true)}>
        {!imageLoaded && (
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height="100%" 
            animation="wave" 
          />
        )}
        <EventImage 
          src={event.imageUrl || '/placeholder-image.jpg'} 
          alt={event.title} 
          loading="lazy"
          onLoad={() => setImageLoaded(true)}
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />

        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            zIndex: 2,
            opacity: 0.8,
            transition: 'opacity 0.2s ease',
            '&:hover': {
              opacity: 1
            }
          }}
        >
          <Tooltip title="View full image">
            <IconButton
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                }
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        </Box>
      </EventImageContainer>
    </Box>
  );

  const renderEventInfo = () => (
    <Box p={isMobile ? 2 : 3} position="relative">
      <BackdropContainer>
        <img 
          src={event.imageUrl || '/placeholder-image.jpg'} 
          alt="" 
          aria-hidden="true"
        />
      </BackdropContainer>
      
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        gutterBottom
        sx={{
          fontSize: isMobile ? '1.6rem' : '2.2rem',
          lineHeight: 1.2,
          fontWeight: 700,
          letterSpacing: '-0.5px',
          background: 'linear-gradient(45deg, #ffffff 30%, #e0e0e0 90%)',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          marginBottom: isMobile ? 1.5 : 2,
          textShadow: '0 2px 10px rgba(0,0,0,0.2)'
        }}
      >
        {event.title}
      </Typography>
      
      <Box 
        display="flex" 
        alignItems="center" 
        mb={isMobile ? 1.5 : 2.5}
        sx={{
          '& .MuiAvatar-root': {
            width: isMobile ? 36 : 44,
            height: isMobile ? 36 : 44,
            boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
            border: '2px solid rgba(255,255,255,0.1)',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'scale(1.1)',
            }
          }
        }}
      >
        <Tooltip title={event.username || 'Anonymous'}>
          <Avatar 
            src={event.creatorPhotoURL} 
            alt={event.username}
          >
            {event.username ? event.username[0].toUpperCase() : 'A'}
          </Avatar>
        </Tooltip>
        
        <Typography 
          variant={isMobile ? "body2" : "subtitle1"} 
          ml={1.5}
          sx={{
            fontWeight: 500,
            fontSize: isMobile ? '0.85rem' : '1rem',
            opacity: 0.9
          }}
        >
          Created by {event.username || 'Anonymous'}
        </Typography>
      </Box>

      <InfoCard elevation={isMobile ? 2 : 4}>
        <CardContent sx={{ padding: isMobile ? 1.75 : 2.5 }}>
          <Grid container spacing={isMobile ? 1.25 : 2}>
            <Grid item xs={12} sm={6}>
              <InfoChip 
                icon={<EventAvailable />} 
                label={formatEventDate(event.startDate)}
                size={isMobile ? "small" : "medium"}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title={event.address}>
                <InfoChip 
                  icon={<Room />} 
                  label={event.address}
                  size={isMobile ? "small" : "medium"}
                  color="secondary"
                />
              </Tooltip>
            </Grid>
            {event.distance && (
              <Grid item xs={12} sm={6}>
                <InfoChip 
                  icon={<Directions />} 
                  label={`${event.distance.toFixed(1)} km away`}
                  size={isMobile ? "small" : "medium"}
                  color="info"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InfoChip 
                icon={<Category />} 
                label={event.eventCategory}
                size={isMobile ? "small" : "medium"}
                color="default"
              />
            </Grid>
          </Grid>
        </CardContent>
      </InfoCard>

      <Box mt={isMobile ? 2 : 3} display="flex" justifyContent="space-between" alignItems="center">
        <SectionTitle variant={isMobile ? "h6" : "h5"}>
          About this event
        </SectionTitle>
        
        <Box 
          display="flex" 
          alignItems="center"
        >
          <Tooltip title={`${event.viewCount || 0} views`}>
            <Box display="flex" alignItems="center" mr={2}>
              <Visibility fontSize="small" sx={{ color: 'text.secondary', mr: 0.5 }} />
              <Typography variant="body2" color="text.secondary">
                {event.viewCount || 0}
              </Typography>
            </Box>
          </Tooltip>
          
          <Tooltip title={`${event.participants?.length || 0} participants`}>
            <Box 
              display="flex" 
              alignItems="center" 
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowParticipants(prev => !prev)}
            >
              <People fontSize="small" sx={{ color: 'text.secondary', mr: 0.5 }} />
              <Typography variant="body2" color="text.secondary">
                {event.participants?.length || 0}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      
      {showParticipants && event.participants?.length > 0 && (
        <ParticipantAvatarGroup>
          {Array.from({ length: Math.min(5, event.participants.length) }).map((_, index) => (
            <ParticipantAvatar key={index}>
              {index + 1}
            </ParticipantAvatar>
          ))}
          {event.participants.length > 5 && (
            <Typography variant="body2" sx={{ ml: 1, alignSelf: 'center' }}>
              +{event.participants.length - 5} more
            </Typography>
          )}
        </ParticipantAvatarGroup>
      )}

      <Divider sx={{ my: isMobile ? 1.5 : 2.5, opacity: 0.1 }} />

      <DescriptionCard>
        <SafeDescriptionComponent description={event.description} />
      </DescriptionCard>

      <HashtagContainer>
        {event.hashtags && event.hashtags.map((tag, index) => (
          <InfoChip 
            key={index} 
            label={`#${tag}`}
            size={isMobile ? "small" : "medium"}
          />
        ))}
      </HashtagContainer>

      <Divider sx={{ my: isMobile ? 2 : 3, opacity: 0.1 }} />

      <MobileActionsContainer>
        <ActionButton
          variant={isParticipant ? "outlined" : "contained"}
          color={isParticipant ? "secondary" : "primary"}
          startIcon={isParticipant ? <ExitToApp /> : <People />}
          onClick={handleJoinLeaveEvent}
          size={isMobile ? "medium" : "large"}
          fullWidth={isMobile}
          sx={{
            backgroundColor: isParticipant ? 'rgba(255,255,255,0.08)' : undefined,
            '&:hover': {
              backgroundColor: isParticipant ? 'rgba(255,255,255,0.15)' : undefined,
            }
          }}
        >
          {isParticipant ? 'Leave Event' : 'Join Event'}
        </ActionButton>

        <Box 
          display="flex" 
          gap={isMobile ? 0.75 : 1}
          width={isMobile ? '100%' : 'auto'}
          mt={isMobile ? 0.75 : 0}
        >
          <ActionButton
            variant="outlined"
            color="info"
            startIcon={<Chat />}
            onClick={() => navigate(`/chat/${eventId}`)}
            size={isMobile ? "medium" : "large"}
            fullWidth={isMobile}
            sx={{
              backgroundColor: 'rgba(41, 182, 246, 0.08)',
              '&:hover': {
                backgroundColor: 'rgba(41, 182, 246, 0.15)',
              }
            }}
          >
            Chat
          </ActionButton>
          
          <ActionButton
            variant="outlined"
            color="success"
            startIcon={<ShareIcon />}
            onClick={handleShareMenuOpen}
            ref={shareButtonRef}
            size={isMobile ? "medium" : "large"}
            fullWidth={isMobile}
            sx={{
              backgroundColor: 'rgba(76, 175, 80, 0.08)',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.15)',
              }
            }}
          >
            Share
          </ActionButton>
        </Box>
      </MobileActionsContainer>

      {/* Share Menu */}
      <Popper
        open={shareMenuOpen}
        anchorEl={shareButtonRef.current}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
          >
            <Paper elevation={8} sx={{ mt: 1, borderRadius: 2, overflow: 'hidden' }}>
              <ClickAwayListener onClickAway={handleShareMenuClose}>
                <ShareMenu autoFocusItem={shareMenuOpen}>
                  <Box px={1} py={0.5} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Share via
                    </Typography>
                  </Box>
                  <ShareMenuItem onClick={shareViaFacebook}>
                    <ListItemIcon>
                      <Facebook sx={{ color: '#4267B2' }} />
                    </ListItemIcon>
                    <ListItemText primary="Facebook" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={shareViaTwitter}>
                    <ListItemIcon>
                      <Twitter sx={{ color: '#1DA1F2' }} />
                    </ListItemIcon>
                    <ListItemText primary="Twitter" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={shareViaWhatsApp}>
                    <ListItemIcon>
                      <WhatsApp sx={{ color: '#25D366' }} />
                    </ListItemIcon>
                    <ListItemText primary="WhatsApp" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={shareViaTelegram}>
                    <ListItemIcon>
                      <Telegram sx={{ color: '#0088cc' }} />
                    </ListItemIcon>
                    <ListItemText primary="Telegram" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={shareViaLinkedIn}>
                    <ListItemIcon>
                      <LinkedIn sx={{ color: '#0077B5' }} />
                    </ListItemIcon>
                    <ListItemText primary="LinkedIn" />
                  </ShareMenuItem>
                  <Divider sx={{ my: 1 }} />
                  <ShareMenuItem onClick={copyShareLink}>
                    <ListItemIcon>
                      <ContentCopy />
                    </ListItemIcon>
                    <ListItemText primary="Copy link" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={shareViaEmail}>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary="Email" />
                  </ShareMenuItem>
                  <ShareMenuItem onClick={addToCalendar}>
                    <ListItemIcon>
                      <CalendarMonth />
                    </ListItemIcon>
                    <ListItemText primary="Add to calendar" />
                  </ShareMenuItem>
                </ShareMenu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {event.coordinates && (
        <Box mt={isMobile ? 2.5 : 3.5}>
          <SectionTitle variant={isMobile ? "h6" : "h5"}>
            Location
          </SectionTitle>
          
          <MapWrapper>
            <MapContainer 
              center={[event.coordinates.latitude, event.coordinates.longitude]} 
              zoom={13} 
              style={{ height: '100%', width: '100%' }}
              zoomControl={!isMobile}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker 
                position={[event.coordinates.latitude, event.coordinates.longitude]}
                eventHandlers={{
                  click: openInGoogleMaps,
                }}
              >
                <Popup>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    {event.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5, mb: 1 }}>
                    {event.address}
                  </Typography>
                  <Button 
                    size="small" 
                    variant="contained"
                    onClick={openInGoogleMaps}
                    startIcon={<Directions />}
                    sx={{ mt: 0.5, fontSize: '0.8rem' }}
                  >
                    Directions
                  </Button>
                </Popup>
              </Marker>
            </MapContainer>
          </MapWrapper>
          
          <Box display="flex" justifyContent="center" mt={1.5}>
            <Button 
              variant="text" 
              size="small" 
              startIcon={<Directions />}
              onClick={openInGoogleMaps}
              sx={{ 
                textTransform: 'none',
                opacity: 0.8,
                '&:hover': { opacity: 1 }
              }}
            >
              Open in Google Maps
            </Button>
          </Box>
        </Box>
      )}

      <Box 
        display="flex" 
        justifyContent="flex-end" 
        mt={isMobile ? 2 : 3}
        mb={isMobile ? 1 : 0}
      >
        <Tooltip title="Report this event">
          <IconButton 
            color="error" 
            onClick={() => setOpenReportDialog(true)}
            size={isMobile ? "small" : "medium"}
            sx={{
              opacity: 0.6,
              '&:hover': {
                opacity: 1,
                backgroundColor: 'rgba(211, 47, 47, 0.1)'
              }
            }}
          >
            <Report />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        p: isMobile ? 0 : 3,
        minHeight: '100vh',
        position: 'relative'
      }}
    >
      <Fade in={true} timeout={800}>
        <StyledPaper elevation={isMobile ? 0 : 6}>
          {renderEventHeader()}
          {renderEventInfo()}
        </StyledPaper>
      </Fade>

      <FullScreenDialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        TransitionComponent={Zoom}
        transitionDuration={400}
      >
        <DialogContent 
          sx={{ 
            p: 0, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%' 
          }}
        >
          <img 
            src={event.imageUrl || '/placeholder-image.jpg'} 
            alt={event.title} 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '100%', 
              objectFit: 'contain' 
            }}
          />
          <CloseButton 
            onClick={() => setOpenImageDialog(false)}
            size={isMobile ? "small" : "medium"}
          >
            <Close />
          </CloseButton>
        </DialogContent>
      </FullScreenDialog>

      <ReportDialog
        open={openReportDialog}
        onClose={() => setOpenReportDialog(false)}
        eventId={eventId}
        latitude={event.coordinates?.latitude}
        longitude={event.coordinates?.longitude}
        isMobile={isMobile}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={5000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ 
          vertical: isMobile ? 'top' : 'bottom', 
          horizontal: 'center' 
        }}
        TransitionComponent={Fade}
        sx={{
          [theme.breakpoints.down('sm')]: {
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }
        }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          variant="filled"
          elevation={6}
          sx={{ 
            width: '100%',
            borderRadius: isMobile ? 0 : undefined,
            fontSize: isMobile ? '0.875rem' : '1rem',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EventDetails;