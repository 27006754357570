import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Chip,
  Box,
  FormControlLabel,
  Checkbox,
  useTheme,
  alpha
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { api } from '../utils/api';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(30, 30, 30, 0.95)',
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    maxWidth: '600px',
    width: '100%',
  }
}));

const CategoryChip = styled(Chip)(({ theme, selected }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: selected 
    ? theme.palette.primary.main 
    : alpha(theme.palette.primary.main, 0.1),
  color: selected 
    ? theme.palette.primary.contrastText 
    : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: selected 
      ? theme.palette.primary.dark 
      : alpha(theme.palette.primary.main, 0.2),
  },
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  '& .MuiChip-label': {
    padding: theme.spacing(1.5),
  }
}));

const CountBadge = styled('span')(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  marginLeft: theme.spacing(1),
}));

const WelcomeCategoriesDialog = ({ 
  open, 
  onClose, 
  categories, 
  selectedCategories,
  onCategoriesChange,
  todayEventCounts 
}) => {
  const theme = useTheme();
  const [localSelectedCategories, setLocalSelectedCategories] = useState(selectedCategories);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  
  useEffect(() => {
    setLocalSelectedCategories(selectedCategories);
  }, [selectedCategories]);

  const handleCategoryToggle = (category) => {
    setLocalSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      }
      return [...prev, category];
    });
  };

  const handleSave = async () => {
    try {
      // Sauvegarder les préférences dans le localStorage
      localStorage.setItem('selectedCategories', JSON.stringify(localSelectedCategories));
      if (dontShowAgain) {
        localStorage.setItem('hideWelcomeDialog', 'true');
      }

      // Mettre à jour les catégories dans l'application
      onCategoriesChange(localSelectedCategories);
      


      onClose();
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  const totalEvents = Object.values(todayEventCounts || {}).reduce((a, b) => a + b, 0);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="welcome-dialog-title"
    >
      <DialogTitle id="welcome-dialog-title">
        <Typography variant="h5" component="div" gutterBottom>
          Personnalisez votre expérience
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Typography gutterBottom>
          Sélectionnez les catégories d'événements qui vous intéressent 
          parmi les {categories.length} catégories disponibles.
          {totalEvents > 0 && (
            <Typography component="span" color="primary.main">
              {' '}({totalEvents} événements aujourd'hui)
            </Typography>
          )}
        </Typography>

        <Box my={3}>
          <Grid container spacing={1}>
            {categories.map((category) => (
              <Grid item key={category.value}>
                <CategoryChip
                  label={
                    <span>
                      {category.label}
                      {todayEventCounts?.[category.value] > 0 && (
                        <CountBadge>
                          {todayEventCounts[category.value]}
                        </CountBadge>
                      )}
                    </span>
                  }
                  onClick={() => handleCategoryToggle(category.value)}
                  selected={localSelectedCategories.includes(category.value)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
              color="primary"
            />
          }
          label="Ne plus afficher ce message"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Plus tard
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={localSelectedCategories.length === 0}
        >
          Enregistrer mes préférences
          {localSelectedCategories.length > 0 && ` (${localSelectedCategories.length})`}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default WelcomeCategoriesDialog;