import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Snackbar, Alert, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, styled } from '@mui/material';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';
import { api } from '../utils/api';

const translations = {
  fr: {
    hubComingSoon: "HUB : Crée et accède aux événements de ta région, où que tu sois, quand tu veux.",
    earlyCollaboratorBenefits: "Devenez pionnier de notre révolution événementielle : Profitez d'une certification gratuite d'un an et d'avantages exclusifs en rejoignant nos premiers collaborateurs (limitées).",
    firstName: "Prénom",
    lastName: "Nom",
    username: "Nom d'utilisateur",
    email: "Adresse e-mail",
    bio: "Biographie",
    website: "Site web",
    address: "Adresse",
    certificationType: "Type de certification",
    personal: "Personnelle (vérification bleue)",
    organization: "Organisation (vérification dorée)",
    submitting: "Soumission en cours...",
    submitApplication: "Soumettre la candidature",
    launchInfo: "HUB s'apprête à redéfinir le paysage événementiel mondial. Notre lancement à Genève marquera le début d'une expansion à travers la Suisse et au-delà. Préparez-vous à une plateforme où chaque type d'événement trouvera sa place en quelques clics.",
    allFieldsRequired: "Tous les champs obligatoires doivent être remplis.",
    requestSentSuccessfully: "Demande envoyée avec succès !",
    failedToSendRequest: "Échec de l'envoi de la demande.",
    firstNameHelper: "Entrez votre prénom (max 50 caractères)",
    lastNameHelper: "Entrez votre nom de famille (max 50 caractères)",
    usernameHelper: "Choisissez un nom d'utilisateur unique (3-20 caractères, lettres et chiffres)",
    emailHelper: "Entrez une adresse e-mail valide",
    bioHelper: "Décrivez-vous en quelques phrases (max 500 caractères)",
    websiteHelper: "Ajoutez l'URL de votre site web (optionnel)",
    addressHelper: "Entrez votre adresse complète (max 200 caractères)"
  },
  en: {
    hubComingSoon: "HUB: Create and access events in your area, anywhere, anytime. Unveils Soon!",
    earlyCollaboratorBenefits: "Be a pioneer in our event revolution: Enjoy a year of free certification and exclusive benefits by joining our limited early collaborators.",
    firstName: "First Name",
    lastName: "Last Name",
    username: "Username",
    email: "Email Address",
    bio: "Bio",
    website: "Website",
    address: "Address",
    certificationType: "Certification Type",
    personal: "Personal (blue check)",
    organization: "Organization (gold check)",
    submitting: "Submitting...",
    submitApplication: "Submit Application",
    launchInfo: "HUB is set to redefine the global event landscape. Our launch in Geneva marks the beginning of an expansion across Switzerland and beyond. Get ready for a platform where every type of event finds its place in just a few clicks.",
    allFieldsRequired: "All required fields must be filled.",
    requestSentSuccessfully: "Request sent successfully!",
    failedToSendRequest: "Failed to send the request.",
    firstNameHelper: "Enter your first name (max 50 characters)",
    lastNameHelper: "Enter your last name (max 50 characters)",
    usernameHelper: "Choose a unique username (3-20 characters, letters and numbers)",
    emailHelper: "Enter a valid email address",
    bioHelper: "Describe yourself in a few sentences (max 500 characters)",
    websiteHelper: "Add your website URL (optional)",
    addressHelper: "Enter your full address (max 200 characters)"
  }
};

const Maintenance = () => {
  const [language, setLanguage] = useState('fr');
  const t = translations[language];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    bio: '',
    website: '',
    address: '',
    certificationType: 'personal',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
  }));
  
  const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginRight: theme.spacing(3),
    '& .MuiFormControlLabel-label': {
      fontSize: '1rem',
    },
  }));
  
  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(2),
  }));
  
  const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: '1.1rem',
    marginBottom: theme.spacing(1),
  }));



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!formData.firstName || !formData.lastName || !formData.username || !formData.email || !formData.bio || !formData.address) {
      setSnackbarMessage(t.allFieldsRequired);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    try {
      const response = await api.post('/submit-collaborator-request', formData);
      setSnackbarMessage(response.data.message || t.requestSentSuccessfully);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || t.failedToSendRequest);
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column" textAlign="center" p={3}>
        <Box mb={2}>
          <Button onClick={() => handleLanguageChange('fr')}>Français</Button>
          <Button onClick={() => handleLanguageChange('en')}>English</Button>
        </Box>
        <motion.img
          src={logo}
          alt="Logo"
          style={{ width: 150, marginBottom: 20 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
        <Typography variant="h4" gutterBottom>
          {t.hubComingSoon}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t.earlyCollaboratorBenefits}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} maxWidth={500} width="100%" mt={3}>
        <StyledFormControl component="fieldset">
  <StyledFormLabel component="legend">{t.certificationType}</StyledFormLabel>
  <RadioGroup
    row
    name="certificationType"
    value={formData.certificationType}
    onChange={handleChange}
  >
    <StyledFormControlLabel 
      value="personal" 
      control={<StyledRadio />} 
      label={t.personal} 
    />
    <StyledFormControlLabel 
      value="organization" 
      control={<StyledRadio />} 
      label={t.organization} 
    />
  </RadioGroup>
</StyledFormControl>
          <TextField
            fullWidth
            label={t.firstName}
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            margin="normal"
            required
            inputProps={{ maxLength: 50 }}
            helperText={t.firstNameHelper}
          />
          <TextField
            fullWidth
            label={t.lastName}
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            margin="normal"
            required
            inputProps={{ maxLength: 50 }}
            helperText={t.lastNameHelper}
          />
          <TextField
            fullWidth
            label={t.username}
            name="username"
            value={formData.username}
            onChange={handleChange}
            margin="normal"
            required
            inputProps={{ minLength: 3, maxLength: 20, pattern: "^[a-zA-Z0-9]+$" }}
            helperText={t.usernameHelper}
          />
          <TextField
            fullWidth
            label={t.email}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            required
            helperText={t.emailHelper}
          />
          <TextField
            fullWidth
            label={t.bio}
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            margin="normal"
            required
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
            helperText={t.bioHelper}
          />
          <TextField
            fullWidth
            label={t.website}
            name="website"
            value={formData.website}
            onChange={handleChange}
            margin="normal"
            helperText={t.websiteHelper}
          />
          <TextField
            fullWidth
            label={t.address}
            name="address"
            value={formData.address}
            onChange={handleChange}
            margin="normal"
            required
            inputProps={{ maxLength: 200 }}
            helperText={t.addressHelper}
          />
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? t.submitting : t.submitApplication}
          </Button>
        </Box>

        <Typography variant="body2" color="textSecondary" mt={2}>
          {t.launchInfo}
        </Typography>

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </motion.div>
  );
};

export default Maintenance;