import React from 'react';
import { Typography, Box } from '@mui/material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const SafeDescriptionComponent = ({ description }) => {
  const cleanHtml = DOMPurify.sanitize(description, { USE_PROFILES: { html: true } });
  
  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'a') {
        return (
          <a
            href={domNode.attribs.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1976d2', // Couleur primaire de Material-UI
              textDecoration: 'none',
            }}
          >
            {domNode.children[0].data}
          </a>
        );
      }
    }
  };

  return (
    <Box className="event-description">
      <Typography
        component="div"
        variant="body1"
        mt={2}
        sx={{
          '& br': {
            display: 'block',
            content: '""',
            marginTop: '0.5em',
          },
        }}
      >
        {parse(cleanHtml, options)}
      </Typography>
    </Box>
  );
};

export default SafeDescriptionComponent;

// Utilisation :
// <SafeDescriptionComponent description={event.description} />