import React, { useState } from 'react';
import { 
  Box, Button, LinearProgress, Typography, Snackbar, Alert 
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 Mo en octets
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

function ImageUpload({ onImageUploaded }) {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  // Nouvelle fonction pour redimensionner les images
  const resizeImage = (file, maxWidth = 1200, maxHeight = 1200, quality = 0.8) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          
          // Calculer les nouvelles dimensions
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          
          canvas.width = width;
          canvas.height = height;
          
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convertir en Blob
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', quality);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      setError("L'image ne doit pas dépasser 5 Mo.");
      return;
    }
    
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Seuls les fichiers JPEG, PNG et GIF sont acceptés.");
      return;
    }
    
    try {
      // Redimensionner l'image
      let processedFile = file;
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        processedFile = await resizeImage(file);
      }
      
      setImageFile(processedFile);
      setImagePreview(URL.createObjectURL(processedFile));
      setError(null);
    } catch (error) {
      console.error("Error processing image:", error);
      setError("Erreur lors du traitement de l'image.");
    }
  };

  const handleUpload = async () => {
    if (!imageFile) return;

    setUploading(true);
    const storageRef = ref(storage, `eventImages/${Date.now()}_${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading image:", error);
        setError("Erreur lors de l'upload de l'image. Veuillez réessayer.");
        setUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onImageUploaded(downloadURL);
          setUploading(false);
          setImageFile(null);
          setImagePreview(null);
        } catch (error) {
          console.error("Error getting download URL:", error);
          setError("Erreur lors de la récupération de l'URL de l'image. Veuillez réessayer.");
          setUploading(false);
        }
      }
    );
  };

  return (
    <Box>
      <input
        accept={ALLOWED_FILE_TYPES.join(',')}
        style={{ display: 'none' }}
        id="image-upload"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<AddPhotoAlternateIcon />}
          fullWidth
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Upload Event Image (JPEG, PNG, GIF - Max 5 MB)'}
        </Button>
      </label>
      {imagePreview && (
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <img src={imagePreview} alt="Event preview" style={{ maxWidth: '100%', maxHeight: 200, borderRadius: '8px' }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
            style={{ marginTop: '10px' }}
          >
            Confirm Upload
          </Button>
        </Box>
      )}
      {uploading && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">{`${Math.round(uploadProgress)}%`}</Typography>
        </Box>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ImageUpload;