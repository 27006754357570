import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth, storage } from '../firebase';
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
  Box,
  Paper,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  Fade,
  LinearProgress,
  Link,
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  StepButton,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import HashtagInput from '../components/HashtagInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TermsOfService from './TermsOfService';
import 'leaflet/dist/leaflet.css';
import debounce from 'lodash/debounce';
import CustomMapContainer from '../components/CustomMapContainer';
import ImageUpload from '../components/ImageUpload';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../utils/api';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { 
  DatePicker,
  TimePicker 
} from '@mui/x-date-pickers';
import {
  addHours,
  differenceInMinutes,
  getHours,
  getMinutes,
  setHours,
  setMinutes, 
  addYears, 
  addMinutes, 
  addDays, 
  setSeconds,
  parseISO
} from 'date-fns';
import Switch from '@mui/material/Switch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ErrorIcon from '@mui/icons-material/Error';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';
import { fr, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

// Composant avec compteur de caractères
const CharacterCountInput = ({ value, onChange, maxLength, label, multiline = false, rows = 1, icon, error, helperText, required = false, ...props }) => {
  const characterCount = value.length;
  const progress = (characterCount / maxLength) * 100;
  const theme = useTheme();
  
  const getProgressColor = () => {
    if (error) return theme.palette.error.main;
    if (progress > 90) return theme.palette.warning.main;
    return theme.palette.primary.main;
  };

  return (
    <Box mb={2}>
      <TextField
        variant="outlined"
        fullWidth
        value={value}
        onChange={(e) => {
          if (e.target.value.length <= maxLength) {
            onChange(e);
          }
        }}
        label={label}
        multiline={multiline}
        rows={rows}
        error={error}
        helperText={helperText}
        required={required}
        InputProps={{
          startAdornment: icon,
        }}
        {...props}
      />
      <Box display="flex" alignItems="center" mt={1}>
        <Box width="100%" mr={1}>
          <LinearProgress 
            variant="determinate" 
            value={progress}
            color={progress > 90 ? "warning" : "primary"}
            sx={{ 
              height: 4, 
              borderRadius: 2,
              '& .MuiLinearProgress-bar': {
                backgroundColor: getProgressColor(),
              }
            }}
          />
        </Box>
        <Box minWidth={60}>
          <Typography variant="body2" color={progress > 90 ? "warning.main" : "textSecondary"}>
            {characterCount}/{maxLength}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
    '& .MuiStepLabel-label': {
      fontSize: '0.75rem',
    },
  },
}));

const StepDot = styled('div')(({ theme, active, completed }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  margin: '0 4px',
  background: active || completed ? theme.palette.primary.main : theme.palette.grey[400],
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: 'rgba(50, 50, 50, 0.5)',
  backdropFilter: 'blur(5px)',
  borderRadius: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
}));

function CreateEvent() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language === 'fr' ? fr : enUS;

  // États principaux du formulaire
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(() => {
    // Commencer avec une date 1 heure dans le futur, arrondie à la prochaine demi-heure
    const now = new Date();
    const minutes = now.getMinutes();
    const roundedMinutes = minutes < 30 ? 30 : 0;
    const hoursToAdd = minutes < 30 ? 0 : 1;
    return setMinutes(addHours(now, 1 + hoursToAdd), roundedMinutes);
  });
  const [endDate, setEndDate] = useState(null);
  const [eventType, setEventType] = useState('');
  const [eventCategory, setEventCategory] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [venueName, setVenueName] = useState('');
  const [price, setPrice] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [username, setUsername] = useState('');

  // États pour l'UI
  const [loading, setLoading] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [friends, setFriends] = useState([]);
  const [groups, setGroups] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [openTerms, setOpenTerms] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [stepCompleted, setStepCompleted] = useState({});
  const [userLocation, setUserLocation] = useState(null);

  const { currentUser, getIdToken } = useAuth();

  // Définition des étapes
  const steps = [
    { label: 'Type d\'événement', optional: false },
    { label: 'Détails', optional: false },
    { label: 'Date & Lieu', optional: false },
    { label: 'Image & Tags', optional: false },
    { label: 'Vérification', optional: false }
  ];

  const eventCategories = [
    { value: 'culture', label: 'Culture et Divertissement' },
    { value: 'sports', label: 'Sports et Activités Physiques' },
    { value: 'family', label: 'Famille et Enfants' },
    { value: 'nightlife', label: 'Vie Nocturne et Sorties' },
    { value: 'professional', label: 'Événements Professionnels et Networking' },
    { value: 'markets', label: 'Marchés et Foires' },
    { value: 'social', label: 'Événements Sociaux et Communautaires' },
    { value: 'education', label: 'Éducation et Développement Personnel' },
    { value: 'tech', label: 'Tech et Innovation' },
    { value: 'wellness', label: 'Bien-être et Santé' },
    { value: 'other', label: 'Autre' }
  ];

  // Récupération de la localisation de l'utilisateur
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          
          // Si les coordonnées de l'événement ne sont pas encore définies,
          // utiliser la position de l'utilisateur comme position initiale sur la carte
          if (!coordinates) {
            setMapCenter([latitude, longitude]);
          }
        },
        (error) => {
          console.error("Erreur de géolocalisation:", error);
          setSnackbar({
            open: true,
            message: "Impossible d'obtenir votre position. Certaines fonctionnalités seront limitées.",
            severity: 'warning'
          });
        }
      );
    }
  }, [coordinates]);

  // Formatage de la durée de l'événement
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours === 0) {
      return `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    } else if (minutes % 60 === 0) {
      return `${hours} heure${hours > 1 ? 's' : ''}`;
    } else {
      return `${hours} heure${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }
  };

  // Récupération du profil utilisateur
  const fetchUserProfile = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsername(response.data.username || '');
    } catch (error) {
      console.error("Erreur lors de la récupération du profil:", error);
      setSnackbar({ 
        open: true, 
        message: "Impossible de charger votre profil. Veuillez réessayer.",
        severity: 'error'
      });
    }
  }, [getIdToken]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  // Récupération des amis
  const fetchFriends = useCallback(async () => {
    if (eventType === 'private') {
      try {
        setLoading(true);
        const token = await getIdToken();
        const response = await api.get('/friends', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFriends(response.data || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des amis:', error);
        setSnackbar({ 
          open: true, 
          message: "Impossible de charger vos amis. Veuillez réessayer.",
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    }
  }, [eventType, getIdToken]);

  // Récupération des groupes
  const fetchGroups = useCallback(async () => {
    if (eventType === 'private') {
      try {
        setLoading(true);
        const token = await getIdToken();
        const response = await api.get('/friends/groups', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setGroups(response.data || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des groupes:', error);
        setSnackbar({ 
          open: true, 
          message: "Impossible de charger vos groupes. Veuillez réessayer.",
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    }
  }, [eventType, getIdToken]);

  useEffect(() => {
    if (eventType === 'private') {
      fetchFriends();
      fetchGroups();
    }
  }, [eventType, fetchFriends, fetchGroups]);

  // Gestion des étapes et validation
  const isStepValid = useCallback((step) => {
    const validationFunctions = {
      0: () => eventType !== '',
      1: () => {
        const titleValid = title.trim().length >= 3 && title.trim().length <= 100;
        const descriptionValid = description.trim().length >= 10 && description.trim().length <= 1000;
        const categoryValid = eventType !== 'public' || eventCategory !== '';
        
        // Mise à jour des erreurs spécifiques
        setErrors(prev => ({
          ...prev,
          title: titleValid ? '' : 'Le titre doit contenir entre 3 et 100 caractères',
          description: descriptionValid ? '' : 'La description doit contenir entre 10 et 1000 caractères',
          eventCategory: categoryValid ? '' : 'Veuillez sélectionner une catégorie'
        }));
        
        return titleValid && descriptionValid && categoryValid;
      },
      2: () => {
        const startDateValid = !!startDate && startDate > new Date();
        const addressValid = address.trim().length >= 5 && address.trim().length <= 200;
        const coordinatesValid = !!coordinates;
        
        // Mise à jour des erreurs spécifiques
        setErrors(prev => ({
          ...prev,
          startDate: startDateValid ? '' : 'La date de début doit être dans le futur',
          address: addressValid ? '' : 'L\'adresse doit contenir entre 5 et 200 caractères',
          coordinates: coordinatesValid ? '' : 'Veuillez saisir une adresse valide pour obtenir les coordonnées'
        }));
        
        return startDateValid && addressValid && coordinatesValid;
      },
      3: () => {
        const imageValid = !!image;
        const hashtagsValid = hashtags.length <= 3;
        
        // Mise à jour des erreurs spécifiques
        setErrors(prev => ({
          ...prev,
          image: imageValid ? '' : 'Veuillez télécharger une image',
          hashtags: hashtagsValid ? '' : 'Vous pouvez ajouter jusqu\'à 3 hashtags maximum'
        }));
        
        return imageValid && hashtagsValid;
      },
      4: () => {
        return acceptedTerms;
      }
    };
    
    return validationFunctions[step] ? validationFunctions[step]() : true;
  }, [eventType, title, description, eventCategory, startDate, address, coordinates, image, hashtags, acceptedTerms]);

  const handleNext = () => {
    if (isStepValid(activeStep)) {
      setStepCompleted(prev => ({...prev, [activeStep]: true}));
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      // Mettre en évidence les erreurs
      const errorMessages = {
        0: 'Veuillez sélectionner un type d\'événement.',
        1: 'Veuillez remplir correctement tous les détails de l\'événement.',
        2: 'Veuillez compléter toutes les informations de date et lieu.',
        3: 'Veuillez ajouter une image et vérifier les hashtags.',
        4: 'Veuillez accepter les conditions d\'utilisation.'
      };

      setSnackbar({
        open: true,
        message: errorMessages[activeStep] || 'Veuillez corriger les erreurs avant de continuer.',
        severity: 'error'
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepClick = (step) => {
    // Permettre uniquement de revenir en arrière, pas de sauter des étapes non complétées
    if (step < activeStep || stepCompleted[step]) {
      setActiveStep(step);
    }
  };

  // Navigation
  const handleBackClick = () => {
    navigate('/');
  };

  // Gestion des champs du formulaire
  const handleEventTypeChange = (event) => {
    const selectedEventType = event.target.value;
    setEventType(selectedEventType);
    
    if (selectedEventType === 'public' || selectedEventType === 'friends') {
      setInvitedUsers([]);
    }
  };

  const handleEventCategoryChange = (event) => {
    setEventCategory(event.target.value);
    setErrors(prev => ({ ...prev, eventCategory: '' }));
  };

  const handleHashtagsChange = (newHashtags) => {
    setHashtags(newHashtags);
    setErrors(prev => ({ ...prev, hashtags: '' }));
  };

  // Gestion des dialogues
  const handleInvite = (selected) => {
    let newInvitedUsers = [...invitedUsers];
    selected.forEach(item => {
      if (item.type === 'friend' && !newInvitedUsers.some(user => user.id === item.uid)) {
        newInvitedUsers.push({ id: item.uid, name: item.displayName, type: 'friend' });
      } else if (item.type === 'group') {
        item.members.forEach(memberId => {
          if (!newInvitedUsers.some(user => user.id === memberId)) {
            const friend = friends.find(f => f.uid === memberId);
            if (friend) {
              newInvitedUsers.push({ id: memberId, name: friend.displayName, type: 'friend' });
            }
          }
        });
      }
    });
    setInvitedUsers(newInvitedUsers);
    setOpenInviteDialog(false);
  };

  const removeInvitedUser = (userId) => {
    setInvitedUsers(invitedUsers.filter(user => user.id !== userId));
  };

  // Gestion des dates
  const handleStartDateChange = (newDate) => {
    const minAllowedDate = addMinutes(new Date(), 5);
    const maxAllowedDate = addYears(new Date(), 1);
    
    if (newDate < minAllowedDate) {
      setSnackbar({ 
        open: true, 
        message: 'La date de début doit être au moins 5 minutes dans le futur', 
        severity: 'warning' 
      });
      setStartDate(minAllowedDate);
    } else if (newDate > maxAllowedDate) {
      setSnackbar({ 
        open: true, 
        message: 'La date de début ne peut pas être à plus d\'un an d\'aujourd\'hui', 
        severity: 'warning' 
      });
      setStartDate(maxAllowedDate);
    } else {
      setStartDate(newDate);
      // Ajuster la date de fin si nécessaire
      if (endDate && endDate <= newDate) {
        setEndDate(addMinutes(newDate, 30));
      }
    }
    setErrors(prev => ({ ...prev, startDate: '' }));
  };

  const handleEndDateChange = (newDate) => {
    const maxAllowedEndDate = setSeconds(setMinutes(setHours(addDays(startDate, 1), 23), 59), 59);
    
    if (newDate <= startDate) {
      setSnackbar({ 
        open: true, 
        message: 'La date de fin doit être après la date de début', 
        severity: 'warning' 
      });
      setEndDate(addMinutes(startDate, 30));
    } else if (newDate > maxAllowedEndDate) {
      setSnackbar({ 
        open: true, 
        message: 'La date de fin ne peut pas être à plus de 23:59 le jour suivant', 
        severity: 'warning' 
      });
      setEndDate(maxAllowedEndDate);
    } else {
      setEndDate(newDate);
    }
  };

  // Gestion du téléchargement d'image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
  
    setImagePreview(URL.createObjectURL(file));
    setImageUploading(true);
    setImageUploadProgress(0);
  
    const storageRef = ref(storage, `eventImages/${auth.currentUser.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImageUploadProgress(progress);
      },
      (error) => {
        console.error("Erreur lors du téléchargement de l'image:", error);
        setSnackbar({ 
          open: true, 
          message: "Erreur lors du téléchargement de l'image. Veuillez réessayer.", 
          severity: 'error' 
        });
        setImageUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setImage(downloadURL);
          setImageUploading(false);
          setErrors(prev => ({ ...prev, image: '' }));
        } catch (error) {
          console.error("Erreur lors de la récupération de l'URL de l'image:", error);
          setSnackbar({ 
            open: true, 
            message: "Erreur lors de la récupération de l'URL de l'image. Veuillez réessayer.", 
            severity: 'error' 
          });
          setImageUploading(false);
        }
      }
    );
  };

  // Gestion de l'adresse et des coordonnées
  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    setErrors(prev => ({ ...prev, address: '' }));
    debouncedAddressLookup(newAddress);
  };

  const debouncedAddressLookup = useCallback(
    debounce(async (address) => {
      if (address.length > 3) {
        try {
          setLoadingLocation(true);
          const response = await api.get('/events/getlocation', {
            params: { address }
          });
          if (response.data && response.data.latitude && response.data.longitude) {
            const { latitude, longitude } = response.data;
            setCoordinates({ latitude, longitude });
            setMapCenter([latitude, longitude]);
            setErrors(prev => ({ ...prev, coordinates: '' }));
          } else {
            setSnackbar({ 
              open: true, 
              message: 'Aucun résultat trouvé pour cette adresse.', 
              severity: 'warning' 
            });
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des coordonnées:", error);
          if (error.response && error.response.status === 429) {
            setSnackbar({ 
              open: true, 
              message: 'Limite API atteinte. Veuillez réessayer plus tard.', 
              severity: 'error' 
            });
          } else {
            setSnackbar({ 
              open: true, 
              message: 'Erreur lors de la récupération des coordonnées. Veuillez réessayer.', 
              severity: 'error' 
            });
          }
        } finally {
          setLoadingLocation(false);
        }
      }
    }, 500),
    []
  );

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Vérifier la validité de tous les champs
    const isFormValid = [0, 1, 2, 3, 4].every(step => isStepValid(step));
    
    if (!isFormValid) {
      setSnackbar({ 
        open: true, 
        message: 'Veuillez corriger les erreurs dans le formulaire avant de créer l\'événement.', 
        severity: 'error' 
      });
      return;
    }
    
    setLoading(true);
  
    try {
      const token = await getIdToken();
      let invitedUserIds = [currentUser.uid];
  
      if (eventType === 'friends') {
        const friendIds = friends.map(friend => friend.uid);
        invitedUserIds = [...new Set([...invitedUserIds, ...friendIds])];
      } else if (eventType === 'private') {
        const selectedUserIds = invitedUsers.map(user => user.id);
        invitedUserIds = [...new Set([...invitedUserIds, ...selectedUserIds])];
      }
  
      const eventData = {
        title,
        description,
        startDate: startDate.toISOString(),
        endDate: endDate ? endDate.toISOString() : null,
        address,
        coordinates,
        eventType,
        eventCategory: eventType === 'public' ? eventCategory : undefined,
        invitedUsers: eventType === 'public' ? undefined : invitedUserIds,
        imageUrl: image,
        hashtags,
        username,
        venueName,
        price
      };
  
      const response = await api.post('/events/create', eventData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setSnackbar({ 
        open: true, 
        message: 'Événement créé avec succès!', 
        severity: 'success' 
      });
      
      // Rediriger vers la page de détails de l'événement après un court délai
      setTimeout(() => {
        navigate(`/event/${response.data.id}`);
      }, 1500);
    } catch (error) {
      console.error("Erreur lors de la création de l'événement: ", error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.errors
          .map(err => `${err.field}: ${err.message}`)
          .join(', ');
        setSnackbar({ 
          open: true, 
          message: `Erreurs de validation: ${errorMessages}`, 
          severity: 'error' 
        });
      } else if (error.response && error.response.data && error.response.data.error === 'Event limit exceeded') {
        // Message spécifique pour limite d'événements atteinte
        setSnackbar({ 
          open: true, 
          message: error.response.data.message || 'Vous avez atteint la limite d\'événements autorisés.', 
          severity: 'warning' 
        });
      } else {
        setSnackbar({ 
          open: true, 
          message: 'Erreur lors de la création de l\'événement. Veuillez réessayer.', 
          severity: 'error' 
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Rendu du contenu de chaque étape
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fade in={activeStep === 0}>
            <Box>
              <BackButton color="primary" onClick={handleBackClick}>
                <ArrowBackIcon />
              </BackButton>
              <Typography variant="h6" gutterBottom>
                Choisir le type d'événement
              </Typography>
              <RadioGroup
                aria-label="event-type"
                name="event-type"
                value={eventType}
                onChange={handleEventTypeChange}
              >
                <StyledFormControlLabel
                  value="private"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <LockIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Événement Privé</Typography>
                        <Typography variant="body2" color="textSecondary">Visible uniquement par les personnes invitées</Typography>
                      </Box>
                    </Box>
                  }
                />
                <StyledFormControlLabel
                  value="friends"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <PeopleIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Événement Amis</Typography>
                        <Typography variant="body2" color="textSecondary">Visible par tous vos amis</Typography>
                      </Box>
                    </Box>
                  }
                />
                <StyledFormControlLabel
                  value="public"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <PublicIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Événement Public</Typography>
                        <Typography variant="body2" color="textSecondary">Visible par tout le monde à proximité</Typography>
                      </Box>
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
          </Fade>
        );
        case 1:
          return (
            <Fade in={activeStep === 1}>
              <Box>
                <BackButton color="primary" onClick={handleBackClick}>
                  <ArrowBackIcon />
                </BackButton>
                <CharacterCountInput
                  id="title"
                  label="Titre de l'événement"
                  name="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setErrors(prev => ({ ...prev, title: '' }));
                  }}
                  error={!!errors.title}
                  helperText={errors.title || ""}
                  maxLength={100}
                  icon={<EventIcon color="action" />}
                  autoFocus
                  required
                />
                
                <CharacterCountInput
                  id="venueName"
                  label="Nom du lieu (Optionnel)"
                  name="venueName"
                  value={venueName}
                  onChange={(e) => setVenueName(e.target.value)}
                  maxLength={100}
                  icon={<PlaceIcon color="action" />}
                />
  
                <CharacterCountInput
                  id="price"
                  label="Prix (Optionnel)"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  maxLength={100}
                  icon={<MonetizationOnIcon color="action" />}
                  placeholder="ex: Gratuit, 10€, 5-15€, ou Prix sur demande"
                />
  
                <CharacterCountInput
                  id="description"
                  label="Description de l'événement"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setErrors(prev => ({ ...prev, description: '' }));
                  }}
                  maxLength={1000}
                  minLength={10}
                  icon={<DescriptionIcon color="action" />}
                  error={!!errors.description}
                  helperText={errors.description || ""}
                  multiline
                  rows={4}
                  required
                />
  
                {eventType === 'public' && (
                  <FormControl fullWidth variant="outlined" margin="normal" error={!!errors.eventCategory}>
                    <InputLabel id="event-category-label">Catégorie de l'événement</InputLabel>
                    <Select
                      labelId="event-category-label"
                      id="event-category"
                      value={eventCategory}
                      onChange={handleEventCategoryChange}
                      label="Catégorie de l'événement"
                      startAdornment={<CategoryIcon color="action" style={{ marginRight: 8 }} />}
                      required={eventType === 'public'}
                    >
                      {eventCategories.map((category) => (
                        <MenuItem key={category.value} value={category.value}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.eventCategory && (
                      <Typography variant="caption" color="error">
                        {errors.eventCategory}
                      </Typography>
                    )}
                  </FormControl>
                )}
              {(eventType === 'private') && (
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GroupIcon />}
                    onClick={() => setOpenInviteDialog(true)}
                    disabled={invitedUsers.length >= 50}
                  >
                    Inviter des Amis & Groupes {invitedUsers.length > 0 && `(${invitedUsers.length})`}
                  </Button>
                  {invitedUsers.length >= 50 && (
                    <Typography variant="caption" color="warning.main" sx={{ ml: 1 }}>
                      Limite de 50 invités atteinte
                    </Typography>
                  )}
                </Box>
              )}
              {invitedUsers.length > 0 && (
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Invités: {invitedUsers.length} personnes
                    </Typography>
                    <Divider style={{ margin: '10px 0' }} />
                    <Box display="flex" flexWrap="wrap">
                      {invitedUsers.map((user) => (
                        <StyledChip
                          key={user.id}
                          avatar={<Avatar>{user.name ? user.name[0].toUpperCase() : 'U'}</Avatar>}
                          label={user.name}
                          onDelete={() => removeInvitedUser(user.id)}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </StyledCard>
              )}
            </Box>
          </Fade>
        );
        case 2:
          return (
            <Fade in={activeStep === 2}>
              <Box>
                <BackButton color="primary" onClick={handleBackClick}>
                  <ArrowBackIcon />
                </BackButton>
        
                {/* Date & Time Selection Section */}
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EventIcon color="primary" />
                    Date & Heure de l'événement
                  </Typography>
                  
                  <Grid container spacing={3}>
                    {/* Start Date & Time */}
                    <Grid item xs={12} md={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Date de début
                        </Typography>
                        <DatePicker
                          value={startDate}
                          onChange={(newDate) => {
                            if (newDate) {
                              const newDateTime = setHours(
                                setMinutes(
                                  newDate,
                                  getMinutes(startDate)
                                ),
                                getHours(startDate)
                              );
                              handleStartDateChange(newDateTime);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              error={!!errors.startDate}
                              helperText={errors.startDate}
                              sx={{ mb: 2 }}
                            />
                          )}
                          minDate={addMinutes(new Date(), 5)}
                          maxDate={addYears(new Date(), 1)}
                          locale={currentLanguage}
                        />
                        
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Heure de début
                        </Typography>
                        <TimePicker
                          value={startDate}
                          onChange={(newTime) => {
                            if (newTime) {
                              const newDateTime = setHours(
                                setMinutes(
                                  startDate, 
                                  getMinutes(newTime)
                                ),
                                getHours(newTime)
                              );
                              handleStartDateChange(newDateTime);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              error={!!errors.startDate}
                              helperText={errors.startDate}
                            />
                          )}
                          minutesStep={5}
                          ampm={false}
                          views={['hours', 'minutes']}
                          locale={currentLanguage}
                        />
                      </Box>
                    </Grid>
        
                    {/* End Date & Time */}
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                            Date & Heure de fin (Optionnel)
                          </Typography>
                          <Switch
                            size="small"
                            checked={!!endDate}
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleEndDateChange(addHours(startDate, 2));
                              } else {
                                setEndDate(null);
                              }
                            }}
                            sx={{ ml: 1 }}
                          />
                        </Box>
        
                        {endDate && (
                          <>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                              Date de fin
                            </Typography>
                            <DatePicker
                              value={endDate}
                              onChange={(newDate) => {
                                if (newDate) {
                                  const newDateTime = setHours(
                                    setMinutes(
                                      newDate, 
                                      getMinutes(endDate)
                                    ),
                                    getHours(endDate)
                                  );
                                  handleEndDateChange(newDateTime);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  sx={{ mb: 2 }}
                                />
                              )}
                              minDate={startDate}
                              maxDate={addDays(startDate, 1)}
                              locale={currentLanguage}
                            />
        
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                              Heure de fin
                            </Typography>
                            <TimePicker
                              value={endDate}
                              onChange={(newTime) => {
                                if (newTime) {
                                  const newDateTime = setHours(
                                    setMinutes(
                                      endDate, 
                                      getMinutes(newTime)
                                    ),
                                    getHours(newTime)
                                  );
                                  handleEndDateChange(newDateTime);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                />
                              )}
                              minutesStep={5}
                              ampm={false}
                              views={['hours', 'minutes']}
                              locale={currentLanguage}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
        
                  {/* Duration Display */}
                  {endDate && (
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTimeIcon color="action" fontSize="small" />
                      <Typography variant="body2" color="textSecondary">
                        Durée: {formatDuration(differenceInMinutes(endDate, startDate))}
                      </Typography>
                    </Box>
                  )}
                </Paper>
        
                {/* Location Section */}
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PlaceIcon color="primary" />
                    Lieu de l'événement
                  </Typography>
        
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="address"
                    label="Adresse de l'événement"
                    name="address"
                    required
                    error={!!errors.address || !!errors.coordinates}
                    helperText={
                      <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {errors.address || errors.coordinates || (
                          <>
                            <LocationSearchingIcon fontSize="small" sx={{ color: 'primary.main' }} />
                            Les événements sont visibles dans un rayon de 30km - veuillez indiquer une adresse précise
                          </>
                        )}
                      </Box>
                    }
                    value={address}
                    onChange={handleAddressChange}
                    InputProps={{
                      startAdornment: <PlaceIcon color="action" sx={{ mr: 1 }} />,
                      endAdornment: loadingLocation ? (
                        <CircularProgress size={20} />
                      ) : coordinates ? (
                        <Tooltip title="Emplacement vérifié">
                          <CheckCircleIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Entrez une adresse valide">
                          <InfoIcon color="action" />
                        </Tooltip>
                      )
                    }}
                    margin="normal"
                  />
        
                  {coordinates && (
                    <Box mt={1} display="flex" alignItems="center" gap={1}>
                      <LocationOnIcon fontSize="small" color="success" />
                      <Typography variant="body2" color="success.main">
                        Emplacement trouvé: {address}
                      </Typography>
                    </Box>
                  )}
        
                  {coordinates && (
                    <Box mt={2} height="300px" position="relative">
                      <CustomMapContainer 
                        center={mapCenter}
                        zoom={13}
                        coordinates={[coordinates.latitude, coordinates.longitude]}
                        events={[{
                          id: 'temp',
                          coordinates: {latitude: coordinates.latitude, longitude: coordinates.longitude},
                          username: username || 'Vous',
                          title: title || 'Votre événement'
                        }]}
                        onViewDetails={() => {}}
                        key={JSON.stringify(coordinates)}
                      />
                      <Box
                        position="absolute"
                        bottom={16}
                        left={16}
                        bgcolor="rgba(255, 255, 255, 0.9)"
                        p={1}
                        borderRadius={1}
                        boxShadow={1}
                        zIndex={1000}
                      >
                        <Typography variant="caption" display="flex" alignItems="center" gap={0.5}>
                          <RadioButtonUncheckedIcon fontSize="small" />
                          Rayon de visibilité de 30km
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Paper>
              </Box>
            </Fade>
          );
        case 3:
          return (
            <Fade in={activeStep === 3}>
              <Box>
                <BackButton color="primary" onClick={handleBackClick}>
                  <ArrowBackIcon />
                </BackButton>
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EventIcon color="primary" />
                    Image de l'événement
                  </Typography>
                
                  {errors.image && (
                    <Alert severity="error" sx={{ mb: 2 }}>{errors.image}</Alert>
                  )}
                
                  <ImageUpload onImageUploaded={(imageUrl) => {
                    setImage(imageUrl);
                    setErrors(prev => ({ ...prev, image: '' }));
                  }} />
                
                  {image && (
                    <Box mt={2} display="flex" justifyContent="center">
                      <Box
                        component="img"
                        src={image}
                        alt="Aperçu de l'événement"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '200px',
                          borderRadius: 1,
                          boxShadow: 3
                        }}
                      />
                    </Box>
                  )}
                </Paper>
                
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EventIcon color="primary" />
                    Hashtags de l'événement
                  </Typography>
                  
                  {errors.hashtags && (
                    <Alert severity="error" sx={{ mb: 2 }}>{errors.hashtags}</Alert>
                  )}
                  
                  <HashtagInput onChange={handleHashtagsChange} />
                  
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    Les hashtags aident les utilisateurs à trouver votre événement. Maximum 3 hashtags.
                  </Typography>
                </Paper>
              </Box>
            </Fade>
          );
        case 4:
          return (
            <Fade in={activeStep === 4}>
              <Box>
                <Typography variant="h6" gutterBottom>Vérifiez les informations de votre événement</Typography>
                
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom color="primary">Informations générales</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">Titre</Typography>
                        <Typography variant="body1">{title}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">Type d'événement</Typography>
                        <Typography variant="body1">
                          {eventType === 'private' ? 'Privé' : 
                           eventType === 'friends' ? 'Amis' : 'Public'}
                        </Typography>
                      </Box>
                    </Grid>
                    {eventType === 'public' && (
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Catégorie</Typography>
                          <Typography variant="body1">
                            {eventCategories.find(cat => cat.value === eventCategory)?.label || 'Non spécifiée'}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">Description</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom color="primary">Date & Lieu</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">Date de début</Typography>
                        <Typography variant="body1">
                          {startDate.toLocaleString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                    {endDate && (
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Date de fin</Typography>
                          <Typography variant="body1">
                            {endDate.toLocaleString('fr-FR', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {endDate && (
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Durée</Typography>
                          <Typography variant="body1">
                            {formatDuration(differenceInMinutes(endDate, startDate))}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">Adresse</Typography>
                        <Typography variant="body1">{address}</Typography>
                      </Box>
                    </Grid>
                    {venueName && (
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Nom du lieu</Typography>
                          <Typography variant="body1">{venueName}</Typography>
                        </Box>
                      </Grid>
                    )}
                    {price && (
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Prix</Typography>
                          <Typography variant="body1">{price}</Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
                
                {(eventType === 'private' && invitedUsers.length > 0) && (
                  <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom color="primary">Invités</Typography>
                    <Typography variant="body1">{invitedUsers.length} personnes invitées</Typography>
                    <Box display="flex" flexWrap="wrap" mt={1}>
                      {invitedUsers.map((user) => (
                        <Chip
                          key={user.id}
                          avatar={<Avatar>{user.name ? user.name[0].toUpperCase() : 'U'}</Avatar>}
                          label={user.name}
                          sx={{ m: 0.5 }}
                        />
                      ))}
                    </Box>
                  </Paper>
                )}
                
                {image && (
                  <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom color="primary">Image</Typography>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Box
                        component="img"
                        src={image}
                        alt="Image de l'événement"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '200px',
                          borderRadius: 1,
                          boxShadow: 3
                        }}
                      />
                    </Box>
                  </Paper>
                )}
                
                {hashtags.length > 0 && (
                  <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom color="primary">Hashtags</Typography>
                    <Box display="flex" flexWrap="wrap">
                      {hashtags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          color="primary"
                          variant="outlined"
                          sx={{ m: 0.5 }}
                        />
                      ))}
                    </Box>
                  </Paper>
                )}
                
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                      name="acceptTerms"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      J'ai lu et j'accepte les <Link href="#" onClick={(e) => { e.preventDefault(); setOpenTerms(true); }}>Conditions d'utilisation</Link>
                    </Typography>
                  }
                />
              </Box>
            </Fade>
          );
        default:
          return 'Étape inconnue';
      }
    };


    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLanguage}>
        <Container component="main" maxWidth="md">
          <StyledPaper elevation={6}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Créer un nouvel événement
            </Typography>
            <StyledStepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={index} completed={stepCompleted[index]}>
                  <StepButton onClick={() => handleStepClick(index)} disabled={index > activeStep && !stepCompleted[index]}>
                    <StepLabel>{step.label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </StyledStepper>
            <StyledForm onSubmit={(e) => e.preventDefault()}>
              {renderStepContent(activeStep)}
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  disabled={activeStep === 0 || loading}
                  onClick={handleBack}
                  startIcon={<ArrowBackIcon />}
                >
                  Retour
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading || !acceptedTerms}
                    endIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                  >
                    {loading ? 'Création en cours...' : 'Créer l\'événement'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={loading}
                  >
                    Suivant
                  </Button>
                )}
              </Box>
            </StyledForm>
          </StyledPaper>
  
          <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Inviter des amis & groupes</DialogTitle>
            <DialogContent>
              <Typography variant="body2" gutterBottom>
                Amis: {friends.length}, Groupes: {groups.length}
              </Typography>
              <Autocomplete
                multiple
                id="invite-friends-and-groups"
                options={[
                  ...friends.map(friend => ({ ...friend, type: 'friend' })),
                  ...groups.map(group => ({ ...group, type: 'group' }))
                ]}
                getOptionLabel={(option) => option.type === 'friend' ? option.displayName : `Groupe: ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sélectionner des amis & groupes"
                    placeholder="Tapez pour rechercher"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      avatar={<Avatar>{option.type === 'group' ? 'G' : option.displayName[0]}</Avatar>}
                      label={option.type === 'friend' ? option.displayName : `Groupe: ${option.name}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, newValue) => {
                  handleInvite(newValue);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenInviteDialog(false)} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
  
          <Dialog open={openTerms} onClose={() => setOpenTerms(false)} maxWidth="md" fullWidth>
            <DialogTitle>Conditions d'utilisation</DialogTitle>
            <DialogContent>
              <TermsOfService />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenTerms(false)}>Fermer</Button>
              <Button 
                onClick={() => {
                  setOpenTerms(false);
                  setAcceptedTerms(true);
                }}
                color="primary"
                variant="contained"
              >
                J'accepte
              </Button>
            </DialogActions>
          </Dialog>
  
          <Snackbar 
            open={snackbar.open} 
            autoHideDuration={6000} 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => setSnackbar({ ...snackbar, open: false })} 
              severity={snackbar.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </LocalizationProvider>
    );
  }
  
  export default CreateEvent;