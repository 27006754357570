import React, { useState, useEffect } from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogTitle, 
  Typography, Box, IconButton, Snackbar, Alert, Fade, 
  Card, CardContent, CardActions, Step, StepLabel, Stepper,
  useMediaQuery, useTheme, Divider, Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopIcon from '@mui/icons-material/Laptop';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
// ChromeIcon doesn't exist in Material UI, replace with appropriate icon
import WebIcon from '@mui/icons-material/Web'; // Using WebIcon instead of ChromeIcon
import GetAppIcon from '@mui/icons-material/GetApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Add missing import
import logo from '../assets/logo.png';

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [browserType, setBrowserType] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [installAttempted, setInstallAttempted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Check if app is already installed
  const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches || 
                         window.navigator.standalone ||
                         document.referrer.includes('android-app://');

  useEffect(() => {
    // Don't show install prompt if app is already installed
    if (isAppInstalled) {
      return;
    }

    // Detect device type
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('ios');
    } else if (/android/i.test(userAgent)) {
      setDeviceType('android');
    } else {
      setDeviceType('desktop');
    }
    
    // Detect browser type
    if (/CriOS/i.test(userAgent)) {
      setBrowserType('chrome-ios');
    } else if (/chrome/i.test(userAgent)) {
      setBrowserType('chrome');
    } else if (/firefox/i.test(userAgent)) {
      setBrowserType('firefox');
    } else if (/safari/i.test(userAgent)) {
      setBrowserType('safari');
    } else {
      setBrowserType('other');
    }

    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(e);
      
      // Only show the install prompt after user has interacted with the site
      // This ensures a better user experience
      const hasInteracted = localStorage.getItem('user_has_interacted');
      const promptDismissed = localStorage.getItem('install_prompt_dismissed');
      const promptShownRecently = localStorage.getItem('install_prompt_shown_recently');
      
      if (hasInteracted && !promptDismissed && !promptShownRecently && !isAppInstalled) {
        // Wait 30 seconds after page load before showing the prompt
        setTimeout(() => {
          setShowInstallPrompt(true);
          localStorage.setItem('install_prompt_shown_recently', 'true');
          // Reset the "shown recently" flag after 24 hours
          setTimeout(() => {
            localStorage.removeItem('install_prompt_shown_recently');
          }, 24 * 60 * 60 * 1000);
        }, 30000);
      }
    };

    // Record user interaction
    const recordInteraction = () => {
      localStorage.setItem('user_has_interacted', 'true');
    };

    // Add event listeners
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    document.addEventListener('click', recordInteraction, { once: true });
    document.addEventListener('scroll', recordInteraction, { once: true });
    document.addEventListener('keydown', recordInteraction, { once: true });

    // Check for iOS standalone mode
    if (deviceType === 'ios' && !window.navigator.standalone) {
      // Show iOS instruction prompt after 60 seconds if user has interacted
      const hasInteracted = localStorage.getItem('user_has_interacted');
      const promptDismissed = localStorage.getItem('install_prompt_dismissed');
      const promptShownRecently = localStorage.getItem('install_prompt_shown_recently');
      
      if (hasInteracted && !promptDismissed && !promptShownRecently && !isAppInstalled) {
        setTimeout(() => {
          setShowInstallPrompt(true);
          localStorage.setItem('install_prompt_shown_recently', 'true');
        }, 60000);
      }
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      document.removeEventListener('click', recordInteraction);
      document.removeEventListener('scroll', recordInteraction);
      document.removeEventListener('keydown', recordInteraction);
    };
  }, [deviceType, isAppInstalled]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // Show the browser's install prompt
      deferredPrompt.prompt();
      
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      
      if (outcome === 'accepted') {
        setSnackbarMessage('Thanks for installing our app!');
        setSnackbarOpen(true);
        // Don't show the prompt again by clearing the deferredPrompt
        setDeferredPrompt(null);
      } else {
        setSnackbarMessage("Maybe next time. You can install anytime from the menu.");
        setSnackbarOpen(true);
      }
      
      setShowInstallPrompt(false);
      setInstallAttempted(true);
    } else if (deviceType === 'ios') {
      // Show iOS specific instructions
      setShowInstructions(true);
      setShowInstallPrompt(false);
    } else {
      // For other browsers without install support
      setShowInstructions(true);
      setShowInstallPrompt(false);
    }
  };

  const handleDismiss = () => {
    setShowInstallPrompt(false);
    localStorage.setItem('install_prompt_dismissed', 'true');
    
    // Allow the prompt to be shown again after 7 days
    setTimeout(() => {
      localStorage.removeItem('install_prompt_dismissed');
    }, 7 * 24 * 60 * 60 * 1000);
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderIOSInstructions = () => (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Tap Share</StepLabel>
        </Step>
        <Step>
          <StepLabel>Tap "Add to Home Screen"</StepLabel>
        </Step>
        <Step>
          <StepLabel>Confirm</StepLabel>
        </Step>
      </Stepper>
      
      <Box mt={3} textAlign="center">
        {activeStep === 0 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 1: Tap the Share button</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <ShareIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                Tap the Share button in Safari's menu bar, usually at the bottom of the screen
                on iPhone or at the top on iPad.
              </Typography>
            </Box>
          </Fade>
        )}
        
        {activeStep === 1 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 2: Add to Home Screen</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <AddCircleIcon fontSize="large" />
                <HomeIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                Scroll down in the share menu and tap "Add to Home Screen". 
                You might need to scroll down to find this option.
              </Typography>
            </Box>
          </Fade>
        )}
        
        {activeStep === 2 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 3: Confirm</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <img src={logo} alt="App Logo" style={{ height: '48px' }} />
              </Box>
              <Typography variant="body2">
                Confirm by tapping "Add" in the top-right corner. 
                You can optionally edit the name before adding.
              </Typography>
              <Typography variant="body2" mt={2}>
                That's it! You'll now have the app on your home screen.
              </Typography>
            </Box>
          </Fade>
        )}
      </Box>
    </Box>
  );

  const renderAndroidInstructions = () => (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Tap Menu</StepLabel>
        </Step>
        <Step>
          <StepLabel>Tap "Install App"</StepLabel>
        </Step>
        <Step>
          <StepLabel>Confirm</StepLabel>
        </Step>
      </Stepper>
      
      <Box mt={3} textAlign="center">
        {activeStep === 0 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 1: Tap the Menu</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <MoreVertIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                Tap the three-dot menu in the top-right corner of Chrome.
              </Typography>
            </Box>
          </Fade>
        )}
        
        {activeStep === 1 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 2: Install App</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <GetAppIcon fontSize="large" />
              </Box>
              <Typography variant="body2">
                Tap "Install app" or "Add to Home screen" in the menu.
                If you don't see this option, our app may not be installable on your device.
              </Typography>
            </Box>
          </Fade>
        )}
        
        {activeStep === 2 && (
          <Fade in>
            <Box>
              <Typography variant="h6" gutterBottom>Step 3: Confirm</Typography>
              <Box display="flex" justifyContent="center" my={2}>
                <img src={logo} alt="App Logo" style={{ height: '48px' }} />
              </Box>
              <Typography variant="body2">
                Tap "Install" on the confirmation dialog.
              </Typography>
              <Typography variant="body2" mt={2}>
                The app will now be available on your home screen!
              </Typography>
            </Box>
          </Fade>
        )}
      </Box>
    </Box>
  );

  const renderDesktopInstructions = () => (
    <Box>
      <Typography variant="h6" gutterBottom>Install on Desktop</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" gap={2} my={3}>
        <WebIcon fontSize="large" />
        <GetAppIcon fontSize="large" />
      </Box>
      <Typography variant="body2" paragraph>
        Look for the install icon (⊕) in the address bar or the three-dot menu 
        in the top-right corner of your browser.
      </Typography>
      <Typography variant="body2" paragraph>
        Click "Install" or "Install app" to add our app to your desktop.
      </Typography>
      <Typography variant="body2">
        After installation, you can launch the app from your desktop or start menu
        without opening your browser!
      </Typography>
    </Box>
  );

  const getDeviceSpecificInstructions = () => {
    if (deviceType === 'ios') {
      return renderIOSInstructions();
    } else if (deviceType === 'android') {
      return renderAndroidInstructions();
    } else {
      return renderDesktopInstructions();
    }
  };

  const getDeviceIcon = () => {
    if (deviceType === 'ios') {
      return <AppleIcon fontSize="large" />;
    } else if (deviceType === 'android') {
      return <AndroidIcon fontSize="large" />;
    } else {
      return <LaptopIcon fontSize="large" />;
    }
  };

  return (
    <>
      <Dialog
        open={showInstallPrompt}
        onClose={handleDismiss}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Install Our App</Typography>
            <IconButton aria-label="close" onClick={handleDismiss} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <img src={logo} alt="App Logo" style={{ height: '48px', width: '48px' }} />
                <Box>
                  <Typography variant="h6" component="div">
                    HUB
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create and join events, anywhere, anytime
                  </Typography>
                </Box>
                {getDeviceIcon()}
              </Box>
            </CardContent>
          </Card>
          
          <Typography variant="body1" paragraph>
            Install our app for a better experience:
          </Typography>
          
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <CheckCircleIcon color="success" fontSize="small" />
              <Typography variant="body2">Works offline</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <CheckCircleIcon color="success" fontSize="small" />
              <Typography variant="body2">Faster loading times</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <CheckCircleIcon color="success" fontSize="small" />
              <Typography variant="body2">Easy home screen access</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <CheckCircleIcon color="success" fontSize="small" />
              <Typography variant="body2">Push notifications</Typography>
            </Box>
          </Box>
          
          <Typography variant="body2" color="text.secondary">
            The app is installed directly from this website and uses minimal storage space.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismiss} color="inherit">
            Not Now
          </Button>
          <Button 
            onClick={handleInstallClick} 
            color="primary" 
            variant="contained"
            startIcon={<GetAppIcon />}
          >
            Install
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showInstructions}
        onClose={() => setShowInstructions(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">How to Install Our App</Typography>
            <IconButton aria-label="close" onClick={() => setShowInstructions(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {getDeviceSpecificInstructions()}
        </DialogContent>
        <DialogActions>
          {(deviceType === 'ios' || deviceType === 'android') && (
            <>
              <Button 
                onClick={handlePrevStep} 
                disabled={activeStep === 0}
                color="inherit"
              >
                Back
              </Button>
              <Button 
                onClick={handleNextStep}
                disabled={activeStep === 2}
                color="primary"
              >
                Next
              </Button>
            </>
          )}
          <Button 
            onClick={() => setShowInstructions(false)} 
            color="primary"
            variant="contained"
          >
            Got It
          </Button>
        </DialogActions>
      </Dialog>

      {/* Quick install button in the corner for those who dismissed but want to install later */}
      {!isAppInstalled && installAttempted && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={() => setShowInstallPrompt(true)}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            borderRadius: 4,
            boxShadow: 3,
          }}
        >
          Install App
        </Button>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default PWAInstallPrompt;