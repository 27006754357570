import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../firebase';
import { getRedirectResult } from 'firebase/auth';
import { 
  Container, 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Box,
  Divider, 
  Avatar,
  CircularProgress,
  Alert,
  IconButton,
  InputAdornment,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Logo from '../assets/logo.png';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const StyledLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  marginBottom: theme.spacing(2),
}));

function Login() {
    const [formData, setFormData] = useState({
      email: '',
      password: ''
    });
    const [errors, setErrors] = useState({});
    const [isSignUp, setIsSignUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'error'
    });
    
    const navigate = useNavigate();
    const location = useLocation();
    const { signup, login, loginWithGoogle } = useAuth();
  
    // Récupérer la redirection prévue après connexion
    const from = location.state?.from || '/';
  
    useEffect(() => {
      const checkRedirectResult = async () => {
        try {
          const result = await getRedirectResult(auth);
          if (result) {
            // L'utilisateur s'est connecté avec succès via la redirection
            setSnackbar({
              open: true,
              message: 'Connexion réussie !',
              severity: 'success'
            });
            setTimeout(() => {
              navigate(from);
            }, 1000);
          }
        } catch (error) {
          console.error("Error checking redirect result:", error);
          setSnackbar({
            open: true,
            message: `Erreur lors de la connexion: ${error.message}`,
            severity: 'error'
          });
        } finally {
          setLoading(false);
        }
      };
  
      checkRedirectResult();
    }, [navigate, from]);
    
    const validateForm = () => {
      const newErrors = {};
      
      // Validation de l'e-mail
      if (!formData.email) {
        newErrors.email = "L'email est requis";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "Format d'email invalide";
      }
      
      // Validation du mot de passe
      if (!formData.password) {
        newErrors.password = "Le mot de passe est requis";
      } else if (isSignUp && formData.password.length < 6) {
        newErrors.password = "Le mot de passe doit contenir au moins 6 caractères";
      }
      
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      
      // Effacer l'erreur quand l'utilisateur corrige le champ
      if (errors[name]) {
        setErrors(prev => ({
          ...prev,
          [name]: ''
        }));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!validateForm()) {
        return;
      }
      
      setAuthLoading(true);
      try {
        if (isSignUp) {
          await signup(formData.email, formData.password);
          setSnackbar({
            open: true,
            message: 'Compte créé avec succès !',
            severity: 'success'
          });
        } else {
          await login(formData.email, formData.password);
          setSnackbar({
            open: true,
            message: 'Connexion réussie !',
            severity: 'success'
          });
        }
        // Rediriger après un court délai pour permettre à l'utilisateur de voir le message de succès
        setTimeout(() => {
          navigate(from);
        }, 1000);
      } catch (error) {
        console.error("Auth error:", error);
        let errorMessage = "Une erreur s'est produite lors de l'authentification.";
        
        // Messages d'erreur personnalisés en fonction du code d'erreur Firebase
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          errorMessage = "Email ou mot de passe incorrect.";
        } else if (error.code === 'auth/email-already-in-use') {
          errorMessage = "Cet email est déjà utilisé.";
        } else if (error.code === 'auth/weak-password') {
          errorMessage = "Le mot de passe est trop faible.";
        } else if (error.code === 'auth/invalid-email') {
          errorMessage = "Format d'email invalide.";
        } else if (error.code === 'auth/too-many-requests') {
          errorMessage = "Trop de tentatives. Veuillez réessayer plus tard.";
        }
        
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error'
        });
      } finally {
        setAuthLoading(false);
      }
    };
  
    const handleGoogleLogin = async () => {
      try {
        setAuthLoading(true);
        await loginWithGoogle();
        // Ne pas naviguer ici, la redirection sera gérée par la fonction checkRedirectResult
      } catch (error) {
        console.error("Google login error:", error);
        setSnackbar({
          open: true,
          message: "Erreur lors de la connexion avec Google.",
          severity: 'error'
        });
        setAuthLoading(false);
      }
    };

    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    const handleCloseSnackbar = () => {
      setSnackbar({...snackbar, open: false});
    };

    if (loading) {
      return (
        <Container component="main" maxWidth="xs">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </Container>
      );
    }

    return (
      <Container component="main" maxWidth="xs">
        <StyledPaper elevation={6}>
          {/* Affichage du logo */}
          <StyledLogo alt="Out Tonight Logo" src={Logo} />
          <Typography component="h1" variant="h5">
            {isSignUp ? 'Inscription' : 'Connexion'} à HuB
          </Typography>
          
          {/* Phrase d'accroche */}
          <Typography variant="body2" color="textSecondary" align="center" sx={{ marginBottom: 2 }}>
            Créez et rejoignez des événements n'importe où, n'importe quand.
          </Typography>

          <StyledForm onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              disabled={authLoading}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de Passe"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete={isSignUp ? "new-password" : "current-password"}
              value={formData.password}
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
              disabled={authLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={authLoading}
            >
              {authLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                isSignUp ? "S'inscrire" : "Se connecter"
              )}
            </StyledButton>
          </StyledForm>
          
          <Box my={2}>
            <Divider>OU</Divider>
          </Box>
          
          <StyledButton
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleLogin}
            disabled={authLoading}
          >
            {authLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Se connecter avec Google"
            )}
          </StyledButton>
          
          <Box mt={2}>
            <Button
              fullWidth
              color="secondary"
              onClick={() => setIsSignUp(!isSignUp)}
              disabled={authLoading}
            >
              {isSignUp ? 'Déjà un compte? Connexion' : "Pas de compte? Inscription"}
            </Button>
          </Box>
        </StyledPaper>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    );
}

export default Login;