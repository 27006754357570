// src/utils/dateUtils.js

import { addDays, isBefore, isAfter, setHours, setMinutes, format, parseISO, isToday, isYesterday, isSameDay } from 'date-fns';

/**
 * Détermine si l'heure actuelle est dans la période "fin de soirée" (après minuit mais avant 6h)
 * @returns {boolean} Vrai si l'heure actuelle est entre minuit et 6h
 */
export const isLateNight = () => {
  const now = new Date();
  const hour = now.getHours();
  return hour >= 0 && hour < 6;
};

/**
 * Récupère la date de référence pour l'affichage des événements en tenant compte des heures tardives
 * Si c'est tard dans la nuit (après minuit mais avant 6h), renvoie la date de la veille
 * @returns {Date} La date de référence à utiliser pour le filtrage des événements
 */
export const getEventReferenceDate = () => {
  const now = new Date();
  if (isLateNight()) {
    // Si c'est après minuit mais avant 6h, considérer comme la veille
    return addDays(now, -1);
  }
  return now;
};

/**
 * Récupère toutes les dates pertinentes pour afficher les événements, y compris la veille pour les soirées tardives
 * @param {Date} referenceDate - La date de référence principale
 * @returns {Date[]} Tableau des dates à considérer pour l'affichage des événements
 */
export const getRelevantDates = (referenceDate = new Date()) => {
  const dates = [referenceDate];
  
  // Si c'est actuellement tard dans la nuit, ajouter également le jour calendaire actuel
  if (isLateNight()) {
    const today = new Date();
    dates.push(today);
  }
  
  return dates;
};

/**
 * Détermine si un événement doit être affiché en fonction de l'heure actuelle et de l'heure de l'événement
 * Garde les événements visibles jusqu'à 6h le lendemain
 * @param {string|Date} eventDate - Date de début de l'événement
 * @returns {boolean} Vrai si l'événement doit être affiché
 */
export const shouldDisplayEvent = (eventDate) => {
  const now = new Date();
  const sixAM = setHours(setMinutes(new Date(), 0), 6);
  const eventDateTime = eventDate instanceof Date ? eventDate : parseISO(eventDate);
  
  // Si l'événement est aujourd'hui, l'afficher
  if (format(now, 'yyyy-MM-dd') === format(eventDateTime, 'yyyy-MM-dd')) {
    return true;
  }
  
  // Si l'événement était hier et qu'il est actuellement avant 6h, l'afficher
  if (
    format(addDays(now, -1), 'yyyy-MM-dd') === format(eventDateTime, 'yyyy-MM-dd') &&
    isBefore(now, sixAM)
  ) {
    return true;
  }
  
  return false;
};

/**
 * Filtre les événements pour inclure les événements actuels et de fin de soirée
 * @param {Array} events - Tableau d'objets événement avec la propriété startDate
 * @returns {Array} Événements filtrés
 */
export const filterEventsForLateNight = (events) => {
  const now = new Date();
  const isNightTime = isLateNight();
  
  return events.filter(event => {
    const eventDate = new Date(event.startDate);
    
    // Événements du jour actuel
    if (format(now, 'yyyy-MM-dd') === format(eventDate, 'yyyy-MM-dd')) {
      return true;
    }
    
    // Événements de la veille si c'est actuellement tard (avant 6h)
    if (isNightTime && 
        format(addDays(now, -1), 'yyyy-MM-dd') === format(eventDate, 'yyyy-MM-dd')) {
      return true;
    }
    
    return false;
  });
};

/**
 * Regroupe les événements par jour, avec un traitement spécial pour les événements de fin de soirée
 * @param {Array} events - Tableau d'objets événement
 * @returns {Object} Événements regroupés par clé de date
 */
export const groupEventsWithLateNight = (events) => {
  const groupedEvents = {};
  const isNightTime = isLateNight();
  
  events.forEach(event => {
    let eventDate;
    
    // Handle different date formats
    if (typeof event.startDate === 'string') {
      eventDate = parseISO(event.startDate);
    } else if (event.startDate && event.startDate._seconds) {
      // Firebase Timestamp format
      eventDate = new Date(event.startDate._seconds * 1000);
    } else if (event.startDate instanceof Date) {
      eventDate = event.startDate;
    } else {
      console.error("Invalid date format:", event.startDate);
      return; // Skip this event
    }
    
    let dateKey = format(eventDate, 'yyyy-MM-dd');
    
    // Si c'est tard dans la nuit et que l'événement date d'hier, 
    // nous pourrions ajouter une étiquette spéciale pour indiquer qu'il continue depuis hier
    if (isNightTime && 
        format(addDays(new Date(), -1), 'yyyy-MM-dd') === dateKey) {
      // Soit conserver la clé de date d'origine, soit créer une clé "continue" spéciale
      // Ici, nous conservons la clé d'origine, mais vous pourriez créer une personnalisée
    }
    
    if (!groupedEvents[dateKey]) {
      groupedEvents[dateKey] = [];
    }
    
    groupedEvents[dateKey].push(event);
  });
  
  return groupedEvents;
};

/**
 * Obtient un en-tête de date convivial pour l'affichage des événements
 * Ajoute des étiquettes "Ce soir" ou "Suite de la soirée d'hier" si nécessaire
 * @param {string} dateKey - Clé de date au format yyyy-MM-dd
 * @returns {string} En-tête de date formaté
 */
export const getDateHeader = (dateKey) => {
  const eventDate = parseISO(dateKey);
  const now = new Date();
  const isNightTime = isLateNight();
  
  // Si l'événement est d'hier et qu'il est actuellement après minuit mais avant 6h
  if (isNightTime && 
      format(addDays(now, -1), 'yyyy-MM-dd') === dateKey) {
    return `${format(eventDate, 'EEEE d MMMM yyyy')} (Suite de la soirée d'hier)`;
  }
  
  // Si l'événement est aujourd'hui
  if (format(now, 'yyyy-MM-dd') === dateKey) {
    const hour = now.getHours();
    if (hour >= 18) {
      return `${format(eventDate, 'EEEE d MMMM yyyy')} (Ce soir)`;
    }
    return `${format(eventDate, 'EEEE d MMMM yyyy')} (Aujourd'hui)`;
  }
  
  return format(eventDate, 'EEEE d MMMM yyyy');
};