import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container, Typography, Grid, Card, CardContent, CardActions,
  Button, IconButton, Box, Snackbar, Alert, CircularProgress,
  Tabs, Tab, Pagination, InputAdornment, TextField, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from '../utils/api';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

function MyEvents() {
  const [events, setEvents] = useState({ created: [], joined: [] });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [eventsPerPage] = useState(9);
  const [tabValue, setTabValue] = useState(1); // Default to joined events (1)
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const navigate = useNavigate();
  const { getIdToken } = useAuth();

  const fetchMyEvents = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getIdToken();
      const response = await api.get('/my-events', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEvents({
        created: response.data.createdEvents || [],
        joined: response.data.joinedEvents || []
      });
    } catch (error) {
      console.error("Error fetching events: ", error);
      setSnackbar({ open: true, message: 'Error fetching events. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [getIdToken]);

  useEffect(() => {
    fetchMyEvents();
  }, [fetchMyEvents]);

  const filteredEvents = useMemo(() => {
    const filterEvents = (eventList) => {
      return eventList.filter(event =>
        event.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    };

    return {
      created: filterEvents(events.created),
      joined: filterEvents(events.joined)
    };
  }, [events, searchTerm]);

  const handleBackClick = () => {
    navigate('/');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setEditedTitle(event.title);
    setEditedDescription(event.description);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  const handleSaveEdit = async () => {
    if (selectedEvent) {
      try {
        const token = await getIdToken();
        await api.put(`/my-events/${selectedEvent.eventId}`, {
          title: editedTitle,
          description: editedDescription,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSnackbar({ open: true, message: 'Event updated successfully!', severity: 'success' });
        fetchMyEvents();
        handleCloseDialog();
      } catch (error) {
        console.error("Error updating event: ", error);
        setSnackbar({ open: true, message: 'Error updating event. Please try again.', severity: 'error' });
      }
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const token = await getIdToken();
      await api.delete(`/my-events/${eventId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Event deleted successfully!', severity: 'success' });
      fetchMyEvents();
    } catch (error) {
      console.error("Error deleting event: ", error);
      setSnackbar({ open: true, message: 'Error deleting event. Please try again.', severity: 'error' });
    }
  };

  const currentEvents = tabValue === 0 ? filteredEvents.created : filteredEvents.joined;
  const paginatedEvents = currentEvents.slice((page - 1) * eventsPerPage, page * eventsPerPage);

  if (loading) {
    return (
      <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '5rem', position: 'relative' }}>
      <BackButton color="primary" onClick={handleBackClick}>
        <ArrowBackIcon />
      </BackButton>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        My Events
      </Typography>
      
      <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 3 }}>
        <Tab label="Created Events" />
        <Tab label="Joined Events" />
      </Tabs>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search events"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '1rem' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {currentEvents.length === 0 ? (
        <Box textAlign="center" mt={4}>
          <EventIcon style={{ fontSize: 60, color: 'grey' }} />
          <Typography variant="h6" color="textSecondary" mt={2}>
            {searchTerm ? 'No events found matching your search.' : `You haven't ${tabValue === 0 ? 'created' : 'joined'} any events yet.`}
          </Typography>
          {tabValue === 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/create-event')}
              style={{ marginTop: '1rem' }}
            >
              Create Your First Event
            </Button>
          )}
        </Box>
      ) : (
        <>
          <Grid container spacing={4}>
            {paginatedEvents.map((event) => (
              <Grid item key={event.eventId} xs={12} sm={6} md={4}>
                <StyledCard>
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {event.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Date: {formatDate(event.startDate)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Type: {event.eventType}
                    </Typography>
                    {tabValue === 0 && (
                      <Typography variant="body2" color="textSecondary" component="p">
                        Address: {event.address}
                      </Typography>
                    )}
                  </StyledCardContent>
                  <CardActions>
                    <Button size="small" color="primary" onClick={() => navigate(`/event/${event.eventId}`)}>
                      View Details
                    </Button>
                    {tabValue === 0 && (
                      <>
                        <IconButton size="small" color="primary" onClick={() => handleEditClick(event)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton size="small" color="error" onClick={() => handleDeleteEvent(event.eventId)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </CardActions>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(currentEvents.length / eventsPerPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Event</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MyEvents;