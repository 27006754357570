// utils/badgeUtils.js

import { doc, getDoc, updateDoc, arrayUnion, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const checkAndAwardBadges = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();
  const newBadges = [];

  // Vérifiez les conditions pour les badges
  if (!userData.badges.includes('Early Adopter')) {
    const usersCollection = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollection);
    if (usersSnapshot.size <= 1000) {
      newBadges.push('Early Adopter');
    }
  }

  // Ajoutez d'autres conditions pour les badges ici

  if (newBadges.length > 0) {
    await updateDoc(userRef, {
      badges: arrayUnion(...newBadges)
    });
  }
};