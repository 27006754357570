import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const pulseGlow = (color1, color2) => keyframes`
  0% { box-shadow: 0 0 2px ${color1}, 0 0 4px ${color1}, 0 0 6px ${color1}; }
  50% { box-shadow: 0 0 4px ${color2}, 0 0 8px ${color2}, 0 0 12px ${color2}; }
  100% { box-shadow: 0 0 2px ${color1}, 0 0 4px ${color1}, 0 0 6px ${color1}; }
`;

const BorderWrapper = styled(Box)(({ theme, color1, color2 }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  background: `linear-gradient(45deg, ${color1}, ${color2})`,
  animation: `${pulseGlow(color1, color2)} 10s ease-in-out infinite`,
  height: '100%', // Ensure it takes full height of its container
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 'inherit',
  position: 'relative',
  zIndex: 1,
  height: '100%', // Ensure content takes full height
  display: 'flex',
  flexDirection: 'column',
}));

const CustomizablePulsatingGlowBorder = ({ children, color1 = '#0d47a1', color2 = '#42a5f5' }) => (
  <BorderWrapper color1={color1} color2={color2}>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </BorderWrapper>
);

export default CustomizablePulsatingGlowBorder;